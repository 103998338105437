import {
  Layout,
  Button,
  Card,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Typography,
} from "antd";
import logo1 from "../images/logos-facebook.svg";
import logo2 from "../images/logo-apple.svg";
import logo3 from "../images/Google__G__Logo.svg.png";
import { Link } from "react-router-dom";
import Footer_Pro from "./Footer_Pro";
import Header_Pro from "./Header_Pro";
import signinbg from "../images/img-signin.png";
import axios from "axios";
import Password from "antd/lib/input/Password";
import auth from "../../services/auth";
import { extractToken } from "../../utils/localStorage";
import { useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { notify } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import menuItems from "../../utils/menu-items";
const { Header, Footer, Content } = Layout;
const { Title } = Typography;

// console.log("menuItems:", menuItems);

// const defaultUrl = findDefaultUrl(menuItems.items);

// console.log("default:", defaultUrl);

const Login = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [initialUrl, setInitialUrl] = useState("/");

  // JavaScript logic to handle Enter key press
  useEffect(() => {
    const handleEnterKeyPress = (e) => {
      if (e.key === "Enter") {
        form.submit();
      }
    };
    // Add event listener for keydown
    document.addEventListener("keydown", handleEnterKeyPress);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [form]);

  const findDefaultUrl = (data) => {
    const visibleGroup = data.find((group) => group.visible);

    // console.log("visibleGroup", visibleGroup);

    if (visibleGroup && visibleGroup.children && visibleGroup.children.length) {
      const findVisibleItem = (children) => {
        for (const child of children) {
          if (child.type === "item" && child.visible) {
            return child.url;
          } else if (child.children && child.children.length) {
            const found = findVisibleItem(child.children);
            if (found) return found;
          }
        }
        return null;
      };

      const defaultUrl = findVisibleItem(visibleGroup.children);
      return defaultUrl || "/";
    }

    return "/";
  };

  useEffect(() => {
    // findDefaultUrl(menuItems.items)
    setInitialUrl(findDefaultUrl(menuItems.items));
  }, []);

  useEffect(() => {
    let user = extractToken();
    if (user?.id) {
      if (initialUrl !== "/") window.location.href = initialUrl;
    }
  }, [initialUrl]);

  const onFinish = async (payload) => {
    try {
      const response = await auth.login(payload);
      // console.log("res",response)
      localStorage.setItem("accessToken", response?.data?.token);
      let user = extractToken();
      // console.log(user?.role);
      if (user?.id) {
        // window.location.href = "/dashboard";
        // window.location.href = findDefaultUrl(menuItems.items);
        window.location.href = initialUrl;
      }
    } catch (error) {
      // console.log("error", error?.data?.msg)
      if (error?.data?.msg === "Authentication failed") {
        notify("error", "Email or password is invalid.");
      }
    }
  };

  // useEffect(() => {
  //   let user = extractToken();
  //   if (user?.id) {
  //     window.location.href = "/dashboard";
  //   } else {
  //     window.location.href = "/login";
  //   }
  // }, []);

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default ant-layout layout-sign-up-cover  bg-white">
        {/* <Header>
            <Header_Pro />
          </Header> */}
        <Content className="sign-in">
          <Row gutter={[24, 0]} justify="space-around">
         
            <Col
              className="sign-img text-center"
              style={{
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={{ span: 24, order: 1 }}
              lg={{ span: 12, order: 2 }}
              md={{ span: 12, order: 2 }}
            >
              <img
                src="https://mpkarateassociation.com/wp-content/uploads/2017/06/mpka-new-logo.png"
                alt="MPKA Logo"
                className="inline-block responsive-img"
              />
            </Col>

       
            <Col
              xs={{ span: 24, offset: 0, order: 2 }}
              lg={{ span: 6, offset: 6, order: 1 }}
              md={{ span: 12, order: 1 }}
            >
              <Title className="mb-15">User Login</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to login
              </Title>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <div className="d-flex justify-content-end">
                  <div
                    role="button"
                    onClick={() => history.push("/password-recovery")}
                    className="pointer"
                    style={{
                      color: "var(--individual-button)",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    LOGIN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>

        {/* <Content className="sign-in ">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 6 }}
              md={{ span: 12 }}
            >
              <Title className="mb-15">User Login</Title>
              <Title className="font-regular text-muted" level={5}>
                Enter your email and password to login
              </Title>
              <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                <div className="d-flex justify-content-end">
                  <div
                    role="button"
                    onClick={() => history.push("/password-recovery")}
                    className="pointer"
                    style={{
                      color: "var(--individual-button)",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password?
                  </div>
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    LOGIN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col
              className="sign-img mt-minus-150 text-center"
              style={{
                padding: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              xs={{ span: 24 }}
              lg={{ span: 12 }}
              md={{ span: 12 }}
            >
              <img
                src={
                  "https://mpkarateassociation.com/wp-content/uploads/2017/06/mpka-new-logo.png"
                }
                alt=""
                className="inline-block"
              />
            </Col>
          </Row>
        </Content> */}

        {/* <Footer>
            <Footer_Pro />
          </Footer> */}
      </Layout>
    </>
  );
};

export default Login;
