import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Slider,
  Tree,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { notify } from "../../../../utils/helper";
import moment from "moment";
import event from "../../../../services/event";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
const CreateCategory = () => {
  const [form] = Form.useForm();
  const [allEvents, setAllEvents] = useState([]);
  const [id, setId] = useState(null);
  const [participationsType, setParticipationsType] = useState("age");
  const [minWeight, setMinWeight] = useState(null);
  const [maxWeight, setMaxWeight] = useState(null);
  const [team, setTeam] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [eventName, setEventName] = useState(null);

  const [minPoint, setMinPoint] = useState(null)

  const [type, setType] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const validateMinWeight = (_, value) => {
    if (value && maxWeight && value > maxWeight) {
      return Promise.reject(
        new Error("Min weight cannot be greater than max weight!")
      );
    }
    return Promise.resolve();
  };

  const validateMaxWeight = (_, value) => {
    if (value && minWeight && value < minWeight) {
      return Promise.reject(
        new Error("Max weight cannot be less than min weight!")
      );
    }
    return Promise.resolve();
  };

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Male + Female", value: "both" },
  ];

  const types = [
    { label: "Kata", value: "kata" },
    { label: "Kumite", value: "kumite" },
    // { label: "Team Kata", value: "teamkata" },
    // { label: "Team Kumite", value: "teamkumite" },
  ];

  const participationType = [
    { label: "Individual", value: false },
    { label: "Team", value: true },
  ];

  const competitionType = [
    { label: "Weight Limit", value: "weight" },
    { label: "Age Limit", value: "age" },
  ];

  // const getAllEvents = async () => {
  //   try {
  //     const res = await event.getAllEvent();
  //     setAllEvents(res?.data?.response?.records);
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   getAllEvents();
  // }, []);

  const createCategory = async (values) => {
    let paylaod = {
      name: values?.name,
      description: values?.description,
      gender: values?.gender,
      age: { min: values?.minAge, max: values?.maxAge },
      weight: { min: values?.minWeight, max: values?.maxWeight },
      //   startDate: moment(values?.startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      //   endDate: moment(values?.endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      team: values?.team,
      teamSize: values?.teamSize,
      type: values?.type,
      boutDuration: values?.boutDuration,
      pointLimit:{min: values?.minPoint, max: values?.maxPoint}
    };
    try {
      const res = await event.createCategory(id, paylaod);
      notify("success", "Category Created Successfully.");
      history.goBack();
    } catch (err) {}
  };

  const onSelect = (selectedKeys, info) => {};

  // const getEventCategoryDetailsById = async (eventId) => {
  //   try {
  //     const res = await event.getEventCategoryDetailsById(eventId);
  //     let data = res?.data?.category?.filter(
  //       (category) => category?.event == id
  //     );
  //   } catch (err) {}
  // };

  // const getEventNameById = (eventId) => {
  //   return allEvents.filter((event) => event._id == eventId)[0]?.name;
  // };

  // useEffect(() => {
  //   if (id) {
  //     getEventCategoryDetailsById(id);
  //   }
  // }, [id]);

  const disableEndDate = (current) => {
    return current && current <= moment(startDate).endOf("day");
  };

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Category",
        subtitle: "Create New Category",
        url: "",
      })
    );
  }, []);

  const boutDurations = [30, 60, 90, 120, 150, 180];

  return (
    <Card title={<h4>Create Category</h4>}>
      <Form onFinish={createCategory} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={type !== null ? 24 : 24}>
            <Row gutter={[24, 24]}>
              <Col span={24} className="m-2"></Col>

              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Type</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Type is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select type"
                    value={type}
                    onChange={(e) => setType(e)}
                  >
                    {types.map((type) => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Name</h4>

                <Form.Item
                  style={{ marginBottom: 10 }}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              {/* <Col sm={24} md={24} lg={12} span={12}> */}
                {/* <Row> */}
                {/* <Col sm={24} md={24} lg={ 12 } span={ 12}> */}
                {/* <h4 className="list-header text-m text-muted m-0">Event</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="id"
                  rules={[
                    {
                      required: true,
                      message: "Event is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select an event"
                    value={id}
                    onChange={(e) => {
                      setId(e);
                    }}
                  >
                    {allEvents.map((event) => (
                      <Select.Option key={event._id} value={event._id}>
                        {event?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                {/* </Col> */}
                {/* {id && (
                    <Col sm={24} md={24} lg={12} span={12}>
                      <h4
                        className="list-header text-m text-muted m-0"
                        style={{ visibility: "hidden" }}
                      >
                        Tree
                      </h4>
                      <Tree
                        showLine
                        switcherIcon={<DownOutlined />}
                        onSelect={onSelect}
                        treeData={treeData}
                      />
                    </Col>
                  )} */}
                {/* </Row> */}
              {/* </Col> */}

              {/* <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">
                  Start Date
                </h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="startDate"
                  rules={[
                    {
                      required: true,
                      message: "Start date is required!",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    onChange={(date, dateString) => {
                        setStartDate(date)
                      //   const formattedDate = moment(date).format("DD/MM/YYYY");
                    }}
                    disabledDate={(current) => current && current < moment().startOf("day")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}

              {/* <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">End Date</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="endDate"
                  rules={[
                    {
                      required: true,
                      message: "End date is required!",
                    },
                  ]}
                >
                  <DatePicker
                    format="DD/MM/YYYY"
                    disabledDate={disableEndDate}
                    onChange={(date, dateString) => {
                      //   const formattedDate = moment(date).format("DD/MM/YYYY");
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}

              {type === "kumite" && (
                <Col sm={24} md={24} lg={12} span={12}>
                  <h4 className="list-header text-m text-muted m-0">
                    Bout Duration
                  </h4>

                  <Form.Item
                    style={{ margin: 0 }}
                    name="boutDuration"
                    rules={[
                      {
                        required: true,
                        message: "Bout duration is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select bout duration"
                      // onChange={(selectedDuration) => setBoutDuration(selectedDuration)}
                    >
                      {boutDurations.map((duration) => (
                        <Select.Option key={duration} value={duration}>
                          {duration} sec
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {type === "kata" && (
                <Col sm={24} md={24} lg={12} span={12}>
                  <h4 className="list-header text-m text-muted m-0">
                    Points Limit
                  </h4>
                  <Row>
                  <Col span={11}>
                    <Form.Item
                      name="minPoint"
                      rules={[
                        {
                          required: true,
                          message: "Please enter minimum points can be given to a player.",
                        },
                        //   {
                        //     validator: validateMinWeight,
                        //   },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={25}
                        //   step={0.01}
                        placeholder="Minimum points"
                        style={{ width: "100%" }}
                        value={""}
                        onChange={(value) => setMinPoint(value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "32px",
                    }}
                  >
                    -
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      name="maxPoint"
                      rules={[
                        {
                          required: true,
                          message: "Please enter maximum points can be given to a player",
                        },
                        //   {
                        //     validator: validateMaxWeight,
                        //   },
                      ]}
                    >
                      <InputNumber
                        min={minPoint}
                        max={25}
                        //   step={0.01}
                        placeholder="Maximum point"
                        style={{ width: "100%" }}
                        value={""}
                        // onChange={(value) => setMaxWeight(value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                </Col>
              )}

              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Gender</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Gender is required!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select gender"
                    // value={role}
                    onChange={(e) => {}}
                  >
                    {genders.map((gender) => (
                      <Select.Option key={gender.value} value={gender.value}>
                        {gender.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Col sm={24} md={24} lg={12} span={12}>
             <h4 className="list-header text-m text-muted m-0">Age limit</h4>

              <Form.Item
              name="age"
              rules={[
                {
                  required: true,
                  message: "Please select an age range!",
                },
              ]}
               >
              <Slider range min={8} max={25} defaultValue={[8, 18]} />
               </Form.Item>
              </Col> */}

              {/* <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">
              Age / Weight Limit
            </h4>

            <Form.Item
              style={{ margin: 0 }}
              name="Age / weight "
              rules={[
                {
                  required: true,
                  message: "Age / weight limit is required!",
                },
              ]}
            >
              <Select
                placeholder="Select type"
                // value={role}
                onChange={(e) => setParticipationsType(e)}
              >
                {competitionType.map((comp) => (
                  <Select.Option key={comp.value} value={comp.value}>
                    {comp.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
               </Col> */}
              {/* {participationsType === "age" && ( */}
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Age Limit</h4>

                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="minAge"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a minimum age!",
                        },
                        //   {
                        //     validator: validateMinWeight,
                        //   },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={40}
                        //   step={0.01}
                        placeholder="Minimum age"
                        style={{ width: "100%" }}
                        value={""}
                        onChange={(value) => setMinWeight(value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "32px",
                    }}
                  >
                    -
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      name="maxAge"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a maximum age!",
                        },
                        //   {
                        //     validator: validateMaxWeight,
                        //   },
                      ]}
                    >
                      <InputNumber
                        min={0}
                        max={40}
                        //   step={0.01}
                        placeholder="Maximum age"
                        style={{ width: "100%" }}
                        value={""}
                        onChange={(value) => setMaxWeight(value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              {/* )} */}

              {/* {participationsType === "weight" && ( */}
              <Col sm={24} md={24} lg={12} span={12}>
              <h4 className="list-header text-m text-muted m-0">
                  Description
                </h4>

                <Form.Item
                  style={{ margin: 0 }}
                  name="description"
                  rules={[
                    {
                      required: false,
                      message: "Description is required!",
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Enter description" rows={4} />
                </Form.Item>
              </Col>
              {/* )} */}

              <Col sm={24} md={24} lg={12} span={12}>
              <h4 className="list-header text-m text-muted m-0">
                  Weight Limit
                </h4>

                <Row>
                  <Col span={11}>
                    <Form.Item
                      name="minWeight"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a minimum weight!",
                        },
                        // {
                        //   validator: validateMinWeight,
                        // },
                      ]}
                    >
                      <InputNumber
                        min={18}
                        max={170}
                        step={0.01}
                        placeholder="Minimum weight in kg"
                        style={{ width: "100%" }}
                        value={minWeight ? minWeight : ""}
                        onChange={(value) => setMinWeight(value)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "32px",
                    }}
                  >
                    -
                  </Col>

                  <Col span={11}>
                    <Form.Item
                      name="maxWeight"
                      rules={[
                        {
                          required: true,
                          message: "Please enter a maximum weight!",
                        },
                        // {
                        //   validator: validateMaxWeight,
                        // },
                      ]}
                    >
                      <InputNumber
                        min={minWeight + 5}
                        max={180}
                        step={0.01}
                        placeholder="Maximum weight in kg"
                        style={{ width: "100%" }}
                        value={maxWeight ? maxWeight : ""}
                        onChange={(value) => setMaxWeight(value)}
                      />
                    </Form.Item>
                  </Col>
                </Row> 
                
                <Row gutter={[24, 24]}>
                  {/* {type && type.includes("team") && ( */}
                  <Col sm={24} md={24} lg={team ? 12 : 24} span={12}>
                    <h4 className="list-header text-m text-muted m-0">Team</h4>

                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Team is required!",
                        },
                      ]}
                      name="team"
                    >
                      {/* <Checkbox></Checkbox> */}

                      <Select
                        placeholder="Select type"
                        //   value={team}
                        onChange={(e) => setTeam(e)}
                      >
                        {participationType.map((type) => (
                          <Select.Option key={type.label} value={type.value}>
                            {type.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* )} */}

                  {team && (
                    <Col sm={24} md={24} lg={12} span={12}>
                      <h4 className="list-header text-m text-muted m-0">
                        Team size
                      </h4>
                      <Form.Item
                        name="teamSize"
                        rules={[
                          {
                            required: true,
                            message: "Please enter max team size!",
                          },
                        ]}
                      >
                        <InputNumber
                          min={3}
                          max={7}
                          //   step={0.01}
                          placeholder="Max team size"
                          style={{ width: "100%" }}
                          //   value={maxWeight}
                          //   onChange={(value) => setMaxWeight(value)}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>

              <Col span={24} className="m-3"></Col>
              <Col
                span={24}
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  type="primary"
                  className="px-25"
                  onClick={() => history.goBack()}
                >
                  CANCEL
                </Button>
                <Button type="primary" className="px-25" htmlType="submit">
                  CREATE CATEGORY
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateCategory;
