import { Modal } from "antd";
import React from "react";

const ModalWrapper = ({ open, setOpen, title, children, width }) => {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={width}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
