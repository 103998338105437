import React, { useEffect, useState } from "react";
import { Table, Row, Col, Select } from "antd";
import moment from "moment";
import { useParams, useHistory, Link } from "react-router-dom";
import { formatFee, notify } from "../../../../utils/helper";
import expense from "../../../../services/expense";
// import CreateExpenseModal from "./CreateExpenseModal";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
// import UpdateExpenseModal from "./UpdateExpenseModal";
import CreateExpenseModal from "../expense/CreateExpenseModal";
import UpdateExpenseModal from "../expense/UpdateExpenseModal";
import { CustomTable } from "../Styled";

const { Option } = Select;

const EventExpenses = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [allExpense, setAllExpense] = useState([]);
  const [eventExpense, setEventExpense] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState({});
  const [totalEventExpense, setTotalEventExpense] = useState(0);
  const history = useHistory();
  const query = useParams();

  const getAllExpense = async () => {
    try {
      const res = await expense.getExpenseList();
      setAllExpense(res?.data);
    } catch (error) {}
  };

  const getEventExpenseById = async (EventId) => {
    try {
      const res = await expense.getEventExpenseById(EventId);
      setEventExpense(res?.data);
    } catch (error) {
      console.error("Error fetching event expense data", error);
    }
  };

  const getTotalEventExpenses = async (EventId) => {
    try {
      const res = await expense.getTotalEventExpenses(EventId);
      setTotalEventExpense(res?.data?.totalExpense);
    } catch (error) {}
  }

  const deleteExpense = async (id) => {
    try {
      const res = await expense.deleteExpenses({
        expense: id ? [id] : selectedRowKeys,
      });
      notify("success", "Expense Deleted Successfully.");
      getEventExpenseById(query?.EventId);
    } catch (error) {}
  };

  const handleDownload = async (id, fileName) => {
    try {
      const res = await expense.downloadAttachment(id, { fileName: fileName });

      window.open(res?.data, "_blank");

      notify("success", "File downloaded successfully.");
    } catch (err) {}
  };

  const expenseColumns = [
    {
      title: "Expense Name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Expense Cost",
      dataIndex: "cost",
      key: "cost",
      align: "center",
      width: 200,
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 200,
    },

    {
      title: "Attachment",
      dataIndex: "attachment",
      key: "attachment",
      align: "center",
      width: 200,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      align: "center",
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      width: 100,
    },
  ];

  let data = [];
  for (let item of eventExpense?.response?.records || []) {
    data.push({
      key: item?._id,
      name:item?.name? <div style={{width:'200px'}}>{item?.name}</div> :<div style={{width:'200px'}}> - </div>,
      cost: item?.cost ? <div style={{width:'200px'}} >{`\u20B9 ${item?.cost?.toFixed(2)}` }</div> : <div style={{width:'200px'}}> - </div>,
      createdAt:item?.createdAt ? <div style={{width:'200px'}} >{moment(item?.createdAt).format("DD-MMM-YYYY")}</div> :<div style={{width:'200px'}}> - </div>,
      comments: item?.description ? <div style={{width:'200px'}} >{item?.description}</div>  : <div style={{width:'200px'}}>-</div>,
      attachment: item?.attachment ? (
        <a onClick={() => handleDownload(item?._id, item?.attachment)}>
          <div style={{width:'200px'}} >    View Attachment</div>
      
        </a>
      ) : (
       <div style={{width:'200px'}}>No Attachment</div> 
      ),
      actions: (
        <div style={{width:'80px'}}>
          <FaEdit
            onClick={() => {
              setIsUpdateModalVisible(true);
              setSelectedExpense(item);
            }}
            size={15}
            style={{ marginRight: 8, cursor: "pointer" }}
          />
          <MdDelete
            onClick={() => deleteExpense(item?._id)}
            size={18}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    });
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  const onChange = (pagination, filters, sorter, extra) => {};

  useEffect(() => {
    if (query?.EventId) {
      getEventExpenseById(query?.EventId);
      getTotalEventExpenses(query?.EventId);
    }

  }, [query?.EventId]);

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 325px)", x: true } : null;

  return (
    <>
      {isCreateModalVisible && (
        <CreateExpenseModal
          open={isCreateModalVisible}
          setOpen={setIsCreateModalVisible}
          getAllExpense={getAllExpense}
          eventId={query?.EventId}
          getEventExpenseById={getEventExpenseById}
        />
      )}

      {isUpdateModalVisible && (
        <UpdateExpenseModal
          open={isUpdateModalVisible}
          setOpen={setIsUpdateModalVisible}
          getAllExpense={getAllExpense}
          selectedExpense={selectedExpense}
          eventId={query?.EventId}
          getEventExpenseById={getEventExpenseById}
        />
      )}

      <Row gutter={[0, 24]}>
        <Col span={12}></Col>
        <Col span={12} className="d-flex justify-content-end">
          {selectedRowKeys?.length > 0 && (
            <div style={{ width: "150px" }}>
              <button
                class="button-29"
                role="button"
                onClick={() => deleteExpense()}
              >
                Delete Expense
              </button>
            </div>
          )}
          <div style={{ width: "150px" }}>
            <button
              class="button-29"
              role="button"
              onClick={() => setIsCreateModalVisible(true)}
            >
              Add Expense
            </button>
          </div>
        </Col>

        <Col span={24}>
        <CustomTable>
          <Table
            tableLayout="auto"
            scroll={scroll}
            columns={expenseColumns}
            dataSource={data}
            onChange={onChange}
            rowSelection={rowSelection}
          />
          </CustomTable>
        </Col>
      </Row>
    </>
  );
};

export default EventExpenses;
