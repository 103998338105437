import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { Drawer, Row, Col, Typography, Button } from "antd";
import "./index.css";
import { domain } from "../../../../../utils/domainUrl";
import { calculateTotalPoints } from "./KumitiScoreBoard";
import fixtures from "../../../../../services/fixtures";
import { useLocalStorage } from "../../../../../context/LocalStorageContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const socket = io(domain);

const penaltyHierarchy = {
  general: { C1: 1, C2: 2, C3: 3, HC: 4, H: 5 },
};

function getHighlightedButtons(penalties) {
  const maxPenaltyIndex = Math.max(
    ...penalties.map((p) => penaltyHierarchy.general[p])
  );
  return Object.keys(penaltyHierarchy.general).filter(
    (penalty) => penaltyHierarchy.general[penalty] <= maxPenaltyIndex
  );
}

const KumiteScoreDrawer = () => {
  const [matchData, setMatchData] = useState({});
  const [playerWarnings, setPlayerWarnings] = useState([]);
  const [timeLeft, setTimeLeft] = useState(
    matchData?.timer?.remainingTime || 0
  );
  const [isRunning, setIsRunning] = useState(
    matchData?.timer?.isRunning || false
  );
  const query = useParams();

  const { kumiteDisplayValue } = useLocalStorage();

  useEffect(() => {
    if (matchData?.scoreSheet) {
      const warnings = matchData.scoreSheet.filter(
        (entry) => entry.type === "warning"
      );
      setPlayerWarnings(warnings);
    }
  }, [matchData]);

  const aoPenalties = playerWarnings
    .filter((w) => w.player === matchData?.participant1?._id)
    .map((w) => w.warningType.toUpperCase());
  const akaPenalties = playerWarnings
    .filter((w) => w.player === matchData?.participant2?._id)
    .map((w) => w.warningType.toUpperCase());

  const highlightedAoButtons = getHighlightedButtons(aoPenalties);
  const highlightedAkaButtons = getHighlightedButtons(akaPenalties);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (matchData?._id) {
      const countdownChannel = `countdown-${matchData?._id}`;
      setTimeLeft(matchData?.timer?.remainingTime);
      const handleCountdownUpdate = (payload) => {
        setTimeLeft(payload?.response?.remainingTime);
        setIsRunning(payload?.response?.isRunning);
      };

      socket.on(countdownChannel, handleCountdownUpdate);

      return () => {
        socket.off(countdownChannel, handleCountdownUpdate);
      };
    }
  }, [matchData, socket]);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  const getMatchesById = async () => {
    try {
      if (!kumiteDisplayValue) return setMatchData({});
      const res = await fixtures.getMatchesById(
        query?.catid,
        kumiteDisplayValue
      );
      if (res?.data?.participant1?.name) {
        res.data.participant1.firstName = res?.data?.participant1?.name || "";
        res.data.participant1.lastName = "";
      }
      if (res?.data?.participant2?.name) {
        res.data.participant2.firstName = res?.data?.participant2?.name || "";
        res.data.participant2.lastName = "";
      }
      if (res?.data?.winner?.name) {
        res.data.winner.firstName = res?.data?.winner?.name || "";
        res.data.winner.lastName = "";
      }

      setMatchData(res?.data);
      setTimeLeft(res?.data?.subEvent?.boutDuration);
    } catch (error) {}
  };

  useEffect(() => {
    getMatchesById();
  }, [kumiteDisplayValue]);

  useEffect(() => {
    if (matchData?._id) {
      try {
        socket.on(`scorepannel-${matchData?._id?.toString()}`, (payload) => {
          if (payload?.match?.participant1?.name) {
            payload.match.participant1.firstName =
              payload?.match?.participant1?.name || "";
            payload.match.participant1.lastName = "";
          }
          if (payload?.match?.participant2?.name) {
            payload.match.participant2.firstName =
              payload?.match?.participant2?.name || "";
            payload.match.participant2.lastName = "";
          }
          if (payload?.match?.winner?.name) {
            payload.match.winner.firstName = payload?.match?.winner?.name || "";
            payload.match.winner.lastName = "";
          }

          setMatchData(payload?.match);
        });

        return () => {
          socket.off("disconnect", () => {});
        };
      } catch (err) {}
    }
  }, [matchData]);

  return (
    <Drawer
      title=""
      placement="bottom"
      // onClose={onClose}
      open={true}
      width="100%"
      height="100%"
      closable={false}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
      keyboard={false}
    >
      <div className="scoreboard-container">
        <Row style={{ position: "relative", height: "100%" }}>
          <div
            className="team-text d-flex justify-content-center w-100"
            style={{
              position: "absolute",
              zIndex: 999,
              top: "15%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {matchData?.subEvent?.name || "Match Category"}
          </div>
          <Col
            span={12}
            className="team-section blue-team"
            style={{ alignItems: "start" }}
          >
            {kumiteDisplayValue ? (
              <>
                <div className="d-flex flex-column">
                  <div className="team-name-code">
                    Ao{" "}
                    {matchData?.firstStrike &&
                      (matchData?.firstStrike?.player ===
                        matchData?.participant1?._id ||
                        matchData?.firstStrike?.player1 ===
                          matchData?.participant1?._id) && (
                        <div
                          className="first-strike d-flex justify-content-center align-itenms-center"
                          style={{ marginLeft: "10px" }}
                        >
                          S
                        </div>
                      )}
                  </div>
                  <div>
                    {/* <div className="player-name-score-drawer">
                      {matchData?.participant1?.firstName +
                        " " +
                        matchData?.participant1?.lastName}
                    </div> */}
                    {/* {matchData?.firstStrike &&
                      (matchData?.firstStrike?.player ===
                        matchData?.participant1?._id ||
                        matchData?.firstStrike?.player1 ===
                          matchData?.participant1?._id) && (
                        <div
                          className="first-strike d-flex justify-content-center align-itenms-center"
                          style={{ marginLeft: "10px" }}
                        >
                          S
                        </div>
                      )} */}
                  </div>
                </div>

                <div className="score-text">
                  {calculateTotalPoints(
                    matchData,
                    matchData?.participant1?._id
                  )}
                </div>

                <div className="penalty-category">
                  {["C1", "C2", "C3", "HC", "H"].map((penalty) => (
                    <div
                      className={
                        highlightedAoButtons.includes(penalty)
                          ? "highlighted penalty-block"
                          : "penalty-block"
                      }
                      key={penalty}
                    >
                      {penalty}
                    </div>
                  ))}
                </div>
                <div className="circle-category-blue">
                  {["C1", "C2", "C3", "HC", "H"].map((penalty, index) => (
                    <div
                      className={
                        highlightedAoButtons.includes(penalty)
                          ? "circle-blue filled"
                          : "circle-blue"
                      }
                      key={index}
                    ></div>
                  ))}
                </div>
              </>
            ) : (
              <h1 style={{ color: "white" }}>
                match is not available at this moment
              </h1>
            )}
          </Col>

          <Col
            span={12}
            className="team-section red-team"
            style={{ alignItems: "end" }}
          >
            {kumiteDisplayValue && (
              <>
                <div className="d-flex flex-column">
                  <div className="team-name-code d-flex justify-content-end">
                    {matchData?.firstStrike &&
                      (matchData?.firstStrike?.player ===
                        matchData?.participant2?._id ||
                        matchData?.firstStrike?.player1 ===
                          matchData?.participant2?._id) && (
                        <div
                          className="first-strike d-flex justify-content-center align-itenms-center"
                          style={{ marginRight: "10px" }}
                        >
                          S
                        </div>
                      )}{" "}
                    Aka
                  </div>
                  <div>
                    {/* <div className="player-name-score-drawer">
                      {matchData?.participant2?.firstName +
                        " " +
                        matchData?.participant2?.lastName}
                    </div> */}

                    {/* {matchData?.firstStrike &&
                      (matchData?.firstStrike?.player ===
                        matchData?.participant2?._id ||
                        matchData?.firstStrike?.player1 ===
                          matchData?.participant2?._id) && (
                        <div
                          className="first-strike d-flex justify-content-center align-itenms-center"
                          style={{ marginLeft: "10px" }}
                        >
                          S
                        </div>
                      )} */}
                  </div>
                </div>

                <div className="score-text">
                  {calculateTotalPoints(
                    matchData,
                    matchData?.participant2?._id
                  )}
                </div>

                <div className="penalty-category">
                  {["C1", "C2", "C3", "HC", "H"].map((penalty) => (
                    <div
                      className={
                        highlightedAkaButtons.includes(penalty)
                          ? "highlighted penalty-block"
                          : "penalty-block"
                      }
                      key={penalty}
                    >
                      {penalty}
                    </div>
                  ))}
                </div>
                <div className="circle-category-red">
                  {["C1", "C2", "C3", "HC", "H"].map((penalty, index) => (
                    <div
                      className={
                        highlightedAkaButtons.includes(penalty)
                          ? "circle-red filled"
                          : "circle-red"
                      }
                      key={index}
                    ></div>
                  ))}
                </div>
              </>
            )}
          </Col>

          <Col span={24} className="time-section">
            <div className="timer">{formatTime(timeLeft)}</div>{" "}
          </Col>
        </Row>

        {/* 
        <Row className="penalties-section d-flex justify-content-around align-items-center">
          {["C1", "C2", "C3", "HC", "H"].map((penalty) => (
            <div
              className={
                highlightedAoButtons.includes(penalty)
                  ? "highlighted penalty-block"
                  : "penalty-block"
              }
              key={penalty}
            >
              {penalty}
            </div>
          ))}
          <div className="team-text" style={{ margin: "0 50px" }}>
            PENALTIES
          </div>
          {["C1", "C2", "C3", "HC", "H"].map((penalty) => (
            <div
              className={
                highlightedAkaButtons.includes(penalty)
                  ? "highlighted penalty-block"
                  : "penalty-block"
              }
              key={penalty}
            >
              {penalty}
            </div>
          ))}
        </Row> */}
      </div>
    </Drawer>
  );
};

export default KumiteScoreDrawer;
