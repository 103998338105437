import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Upload,
  Row,
  Col,
  Select,
  DatePicker,
  Divider,
} from "antd";
import { MdCloudUpload } from "react-icons/md";
import expense from "../../../../services/expense";
import { notify } from "../../../../utils/helper";
import event from "../../../../services/event";
import ModalWrapper from "../../../modal/ModalWrapper";
import moment from "moment";

const { Option } = Select;

const UpdateExpenseModal = ({
  open,
  setOpen,
  getAllExpense,
  selectedExpense,
  eventId,
  getEventExpenseById,
}) => {
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [file, setFile] = useState(null);

  const [form] = Form.useForm();

  const getAllEvent = async () => {
    try {
      const res = await event.getAllEvent();
      // setAllEvents(res?.data);
      setAllEvents(res?.data?.response?.records);
    } catch (error) {}
  };

  const updateExpense = async (values) => {
    try {
      const res = await expense.updateExpense(selectedExpense?._id, {
        event:eventId ? eventId: selectedEventId ? selectedEventId : selectedExpense?.event,
        name: values?.name,
        cost: values?.cost,
        description: values?.comments,
      });
      if (file) {
        await handleUpload(selectedExpense?._id, file?.file);
      }
      notify("success", "Expense Updated Successfully.");
      form.resetFields();
      setOpen(false);
      // getAllExpense();
      {eventId ? getEventExpenseById(eventId):getAllExpense()}
    } catch (error) {}
  };

  const handleUpload = async (id, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await expense.uploadAttachmentToS3(id, formData);
      // notify("success", "File uploaded successfully.");
    } catch (err) {
      console.error("File upload error:", err);
    }
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const beforeUpload = (file) => {
    setFile(file);
    return false;
  };

  useEffect(() => {
    getAllEvent();
  }, []);

  return (
    <ModalWrapper
      title="Update Expense"
      open={open}
      setOpen={setOpen}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={updateExpense}
        initialValues={{
          event: selectedExpense?.event,
          name: selectedExpense?.name,
          cost: selectedExpense?.cost,
          createdAt: selectedExpense?.createdAt
            ? moment(selectedExpense?.createdAt)
            : null,
          comments: selectedExpense?.description,
        }}
      >
          {!eventId &&
        <Form.Item
          name="event"
          label="Events Name"
          rules={[{ required: true, message: "Please enter the event name" }]}
        >
        
          <Select onChange={(e) => setSelectedEventId(e)}>
            {allEvents?.map((item, index) => (
              <Option key={index} value={item?._id}>
                {item?.name}
              </Option>
            ))} 
          </Select>
        </Form.Item>
        }
        <Form.Item
          name="name"
          label="Expense Name"
          rules={[{ required: true, message: "Please enter the expense name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cost"
          label="Expense Cost"
          rules={[{ required: true, message: "Please enter the expense cost" }]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="createdAt"
          label="Transaction Date"
          rules={[
            { required: true, message: "Please select the creation date" },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="comments" label="Comments">
          <Input.TextArea rows={4} placeholder="Enter any comments or notes" />
        </Form.Item>
        <Form.Item
          name="attachment"
          label="Attachment"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
        >
          <Upload
            name="attachment"
            listType="text"
            multiple={false}
            accept="image/*,.pdf"
            type="file"
            beforeUpload={beforeUpload}
            onChange={handleFileChange}
          >
            <Button icon={<MdCloudUpload />}>Click to Replace</Button>
          </Upload>
        </Form.Item>
        <Divider />
        <Form.Item className="d-flex justify-content-center">
          <Button className="button-29" type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};

export default UpdateExpenseModal;
