import React from "react";
import { capitalizeFirstLetter } from "./helper";
import { Tag } from "antd";

export const FilterTag = ({ onClick, item, closIcon, background, color }) => {
  return (
      <Tag onClick={onClick}>
        {capitalizeFirstLetter(item)}
        {closIcon}
      </Tag>
  );
};
