import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Table, Avatar, Tree, Input, Button } from "antd";
import { KumitiScorePanelWrapper } from "../../Styled";
import fixtures from "../../../../../services/fixtures";
import { useParams } from "react-router-dom";
import "./index.css";
import { notify } from "../../../../../utils/helper";
import { FaUndo } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import KumiteScoreDrawer from "./KumitiScoreDrawer";
import WinnerConfirmationModal from "./WinnerConfirmationModal";
import { io } from "socket.io-client";
import { domain } from "../../../../../utils/domainUrl";
import KumitiScoreSheet from "./KumitiScoreSheet";
import { useLocalStorage } from "../../../../../context/LocalStorageContext";

export const calculateTotalPoints = (matchData, playerId) => {
  return matchData?.scoreSheet?.reduce((total, item) => {
    if (item?.type === "score" && item?.player === playerId) {
      return total + item?.point;
    }
    return total;
  }, 0);
};

const RenderPrintSection = ({
  // selectedRowData,
  catDetails,
  printSectionRef,
  matchData,
}) => {
  return (
    <div style={{ display: "none" }}>
      <div ref={printSectionRef}>
        <Col span={24}>
          <div className="d-flex justify-content-center mb-1 font-bold h4">
            {matchData?.subEvent?.name}
          </div>
        </Col>
        {/* {selectedRowData.map((matchData, index) => ( */}
        <KumitiScoreSheet matchData={matchData} />
        {/* ))} */}
      </div>
    </div>
  );
};

const KumiteScoreboard = () => {
  let componentRef = useRef(null);
  const socket = io(domain);
  const { kumiteDisplayValue } = useLocalStorage();

  const [matchData, setMatchData] = useState({});
  const [playerWarnings, setPlayerWarnings] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [visible, setVisible] = useState(false);
  const [winnerModalOpen, setWinnerModalOpen] = useState(false);
  const [winnerConfirmState, setWinnerConfirmState] = useState(false);
  const [decidedWinner, setDecidedWinner] = useState({});
  const [hasStarted, setHasStarted] = useState(false);
  const [matchId, setMatchId] = useState(kumiteDisplayValue);

  console.log("matchData:", matchData);

  const [totalTime, setTotalTime] = useState(120000);

  const [remainingTime, setRemainingTime] = useState(
    Number(localStorage.getItem("remainingTime")) || matchData?.timer?.remainingTime
  );
  const [isLoading, setIsLoading] = useState(
    localStorage.getItem("isRunning") === "active" ? true : false
  );
  const [isActive, setIsActive] = useState(
    localStorage.getItem("isRunning") === "active" ? true : false
  );
  const [startTime, setStartTime] = useState(null);

  let skip = 0;
  const query = useParams();

  const penaltyHierarchy = {
    general: { C1: 1, C2: 2, C3: 3, HC: 4, H: 5 },
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const boutMatchDuration = async (status) => {
    try {
      const res = await fixtures.boutMatchDuration(query?.catid, query?.id, {
        action: status,
        timeLeft: timeLeft,
      });

      setTimeLeft(res?.data?.remainingTime);
    } catch (error) {}
  };

  const getMatchesById = async () => {
    try {
      const res = await fixtures.getMatchesById(query?.catid, query?.id);
      if (res?.data?.participant1?.name) {
        res.data.participant1.firstName = res?.data?.participant1?.name || "";
        res.data.participant1.lastName = "";
      }
      if (res?.data?.participant2?.name) {
        res.data.participant2.firstName = res?.data?.participant2?.name || "";
        res.data.participant2.lastName = "";
      }
      if (res?.data?.winner?.name) {
        res.data.winner.firstName = res?.data?.winner?.name || "";
        res.data.winner.lastName = "";
      }

      setMatchData(res?.data);
      setTimeLeft(res?.data?.subEvent?.boutDuration);
    } catch (error) {}
  };

  const updateWinner = async (winner) => {
    try {
      const res = await fixtures.updateWinner(query?.id, {
        winnerId: winner,
      });
      notify("success", "Winner has been declared.");
      getMatchesById();
    } catch (error) {}
  };

  const undoScore = async () => {
    try {
      const res = await fixtures.undoScore(query?.id);
      notify("success", "Undo happend.");
      getMatchesById();
    } catch (error) {}
  };

  const updateKumitiScoreSheet = async (
    playerName,
    scorePoint,
    scoreType,
    warningType,
    playerNumber
  ) => {
    try {
      const res = await fixtures.updateKumitiScoreSheet(
        query?.catid,
        query?.id,
        {
          player: playerName,
          point: scorePoint,
          type: scoreType,
          warningType: warningType,
          playerNumber,
        }
      );
      getMatchesById();
    } catch (error) {}
  };

  const handleScoreClick = (playerName, point, type, playerNumber) => {
    const currentTotalPoints = matchData?.scoreSheet?.reduce((total, item) => {
      if (item?.type === "score" && item?.player === playerName) {
        return total + item?.point;
      }
      return total;
    }, 0);

    if (currentTotalPoints + point > 20) {
      notify("warn", "Maximum points reached for this player.");
      return;
    }

    updateKumitiScoreSheet(playerName, point, type, "", playerNumber);
  };

  const handleWarningClick = (playerName, warningType) => {
    updateKumitiScoreSheet(playerName, 0, "warning", warningType);
  };

  const getHighlightedButtons = (penalties) => {
    const maxPenaltyIndex = Math.max(
      ...penalties.map((p) => penaltyHierarchy.general[p])
    );
    return Object.keys(penaltyHierarchy.general).filter(
      (penalty) => penaltyHierarchy.general[penalty] <= maxPenaltyIndex
    );
  };

  const aoPenalties = playerWarnings
    .filter((w) => w.player === matchData?.participant1?._id)
    .map((w) => w.warningType.toUpperCase());

  const akaPenalties = playerWarnings
    .filter((w) => w.player === matchData?.participant2?._id)
    .map((w) => w.warningType.toUpperCase());

  const highlightedAoButtons = getHighlightedButtons(aoPenalties);
  const highlightedAkaButtons = getHighlightedButtons(akaPenalties);

  const handleAfterPrint = React.useCallback(() => {}, []);
  const handleBeforePrint = React.useCallback(() => {
    return Promise.resolve();
  }, []);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "AwesomeFileName",
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });

  const openInNewTab = () => {
    const url = `/pages/events/all-events/category/${query?.catid}/kumiti-match-panel/score-panel`;
    window.open(url, "_blank");
  };

  const startTimer = () => {
    setIsRunning(true);
    setHasStarted(true);
    boutMatchDuration("start");
    // setSelectedTimerStatus("start");

    localStorage.setItem("lastStopTime", Date.now());
    setIsActive(true);
    localStorage.setItem("isRunning", "active");
  };

  const togglePauseResume = () => {
    if (isRunning) {
      setIsRunning(false);
      boutMatchDuration("pause");
      // setSelectedTimerStatus("pause");
      
      setIsActive(false);
      localStorage.removeItem("isRunning");
      localStorage.setItem("remainingTime", remainingTime);
      const currentTime = Date.now();
      localStorage.setItem("spendTime", currentTime - startTime);
      localStorage.setItem("lastStopTime", currentTime);

    } else {
      setIsRunning(true);
      boutMatchDuration("resume");
      // setSelectedTimerStatus("resume");

    }
  };

  const startTimerr = () => {
    localStorage.setItem("lastStopTime", Date.now());
    setIsActive(true);
    localStorage.setItem("isRunning", "active");
  };

  const resetTimer = () => {
    setIsActive(false);
    setRemainingTime(totalTime);
    localStorage.removeItem("spendTime");
    localStorage.removeItem("isRunning");
    localStorage.removeItem("remainingTime");
    localStorage.removeItem("lastStopTime");
  };

  const stopTimer = () => {
    setIsActive(false);
    localStorage.removeItem("isRunning");
    localStorage.setItem("remainingTime", remainingTime);

    const currentTime = Date.now();
    localStorage.setItem("spendTime", currentTime - startTime);
    localStorage.setItem("lastStopTime", currentTime);
  };

  useEffect(() => {
    let timer;

    if (isActive) {
      let time = Number(localStorage.getItem("lastStopTime")) || Date.now();
      setStartTime(time);

      timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        console.log(elapsedTime);
        const newRemainingTime =
          totalTime -
          elapsedTime -
          Number(localStorage.getItem("spendTime") || 0);

        if (newRemainingTime <= 0) {
          clearInterval(timer);
          setRemainingTime(0);
          setIsActive(false);
        } else {
          setRemainingTime(newRemainingTime);
          // setTimeLeft(newRemainingTime)
        }
        setIsLoading(false);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isActive, startTime, totalTime]);

  useEffect(() => {
    if (matchData?.timer?.remainingTime) {
      const totalDuration =
        matchData?.timer?.remainingTime + matchData?.timer?.spendTime;
      let remainingTime = matchData?.timer?.remainingTime;

      setTimeLeft(Math.max(0, remainingTime));
      setHasStarted(remainingTime < totalDuration);
      setIsRunning(matchData?.timer?.isRunning);
    }
  }, [matchData]);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(intervalId);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    const countdownChannel = `countdown-${matchData?._id}`;
    const handleCountdownUpdate = (payload) => {
      setTimeLeft(payload?.response?.remainingTime);
    };

    socket.on(countdownChannel, handleCountdownUpdate);

    return () => {
      socket.off(countdownChannel, handleCountdownUpdate);
    };
  }, [matchData, socket]);

  useEffect(() => {
    if (matchData?.scoreSheet) {
      const warnings = matchData.scoreSheet.filter(
        (entry) => entry.type === "warning"
      );
      setPlayerWarnings(warnings);
    }
  }, [matchData]);

  useEffect(() => {
    getMatchesById();
    const baseurl = window.location;
    if (baseurl.pathname.includes("/score-panel")) {
      setVisible(true);
    }
  }, []);

  return (
    <>
      <RenderPrintSection
        // catDetails={catDetails}
        printSectionRef={componentRef}
        // selectedRowData={selectedRowData}
        matchData={matchData}
      />

      {winnerModalOpen && (
        <WinnerConfirmationModal
          open={winnerModalOpen}
          setOpen={setWinnerModalOpen}
          setWinnerConfirmState={setWinnerConfirmState}
          decidedWinner={decidedWinner}
          updateWinner={updateWinner}
        />
      )}
      <KumitiScorePanelWrapper>
        <Col span={24} className="d-flex justify-content-end">
          {matchData?.undoScoreAvaible && !matchData?.winner?._id ? (
            <button
              className="undo-button"
              onClick={() => undoScore()}
              style={{ marginRight: "10px" }}
            >
              <FaUndo size={12} />
              <span style={{ paddingLeft: "5px" }}>UNDO</span>
            </button>
          ) : (
            <div style={{ height: "30px" }}></div>
          )}

          <button
            className="print-button"
            style={{ marginRight: "10px" }}
            onClick={() => {
              if (matchId) {
                localStorage.removeItem("kumite-match-id", query?.id);
                setMatchId(null);
              } else {
                localStorage.setItem("kumite-match-id", matchData?._id);
                setMatchId(matchData?._id);
              }
            }}
          >
            {matchId === matchData?._id ? "Stop" : "Start"}
          </button>
          <button
            className="print-button"
            onClick={printFn}
            style={{ marginRight: "10px" }}
          >
            Print Result
          </button>
          <button
            className="print-button"
            onClick={() => {
              // setVisible(true);
              openInNewTab();
            }}
          >
            Show Scoreboard
          </button>
        </Col>
        <Row gutter={[16, 24]} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Row gutter={[6, 0]}>
              <Col span={24}>
                <div className="d-flex justify-content-center mb-1 font-bold h4">
                  {matchData?.subEvent?.name}
                </div>
              </Col>
              <Col span={12}>
                <div className="score-panel">
                  <h2 className="team-name-blue">AO</h2>
                  <div
                    className="player-name d-flex justify-content-between align-items-center"
                    style={{ padding: "8px 15px" }}
                  >
                    <div>
                      {matchData?.participant1?.firstName +
                        " " +
                        matchData?.participant1?.lastName}
                    </div>
                    {matchData?.firstStrike &&
                      (matchData?.firstStrike?.player ===
                        matchData?.participant1?._id ||
                        matchData?.firstStrike?.player1 ===
                          matchData?.participant1?._id) && (
                        <div className="first-strike">S</div>
                      )}
                  </div>
                  <div className="grid-container">
                    {[...Array(20).keys()].map((i) => (
                      <div key={i + 1} className="number-block">
                        {i + 1}
                      </div>
                    ))}

                    {(() => {
                      let skip = 0;
                      let totalPoints = 0;
                      return matchData?.scoreSheet?.map((item, index) => {
                        if (
                          item?.type === "score" &&
                          item?.player === matchData?.participant1?._id
                        ) {
                          totalPoints += item?.point;

                          if (totalPoints > 20) {
                            return null;
                          }

                          const className =
                            item?.point === 1
                              ? "horizontal-line"
                              : item?.point === 2
                              ? "diagonal-line"
                              : "reverse-diagonal-line";

                          const lineWidth = item?.point * 30;
                          const lineLeft = skip * 30;

                          skip += item?.point;

                          return (
                            <div
                              key={index}
                              className={className}
                              style={{
                                width: `${lineWidth}px`,
                                left: `${lineLeft}px`,
                              }}
                            />
                          );
                        }
                        return null;
                      });
                    })()}
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="total-score-box">
                      Total Score:{" "}
                      {calculateTotalPoints(
                        matchData,
                        matchData?.participant1?._id
                      )}
                    </div>
                    {matchData?.winner?._id ? (
                      matchData?.winner?._id ===
                      matchData?.participant1?._id ? (
                        <div className="total-score-box winner">
                          Result: Winner
                        </div>
                      ) : (
                        <div className="total-score-box lose">Result: Lose</div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    style={{ padding: "0 15px" }}
                  >
                    <div className="warnings-section">
                      {["C1", "C2", "C3", "HC", "H"].map((type) => (
                        <div
                          key={type}
                          className={
                            highlightedAoButtons.includes(type)
                              ? "highlighted warning-block"
                              : "warning-block"
                          }
                          // onClick={() =>
                          //   handleWarningClick(
                          //     matchData?.participant1?._id,
                          //     type.toLowerCase()
                          //   )
                          // }
                        >
                          {type}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="score-panel">
                  <h2 className="team-name-red">AKA</h2>

                  <div
                    className="player-name d-flex justify-content-between align-items-center"
                    style={{ padding: "8px 15px" }}
                  >
                    <div>
                      {matchData?.participant2?.firstName +
                        " " +
                        matchData?.participant2?.lastName}
                    </div>
                    {matchData?.firstStrike &&
                      (matchData?.firstStrike?.player ===
                        matchData?.participant2?._id ||
                        matchData?.firstStrike?.player1 ===
                          matchData?.participant2?._id) && (
                        <div className="first-strike">S</div>
                      )}
                  </div>

                  <div className="grid-container">
                    {[...Array(20).keys()].map((i) => (
                      <div key={i + 1} className="number-block">
                        {i + 1}
                      </div>
                    ))}

                    {(() => {
                      let skip = 0;
                      let totalPoints = 0;
                      return matchData?.scoreSheet?.map((item, index) => {
                        if (
                          item?.type === "score" &&
                          item?.player === matchData?.participant2?._id
                        ) {
                          totalPoints += item?.point;

                          if (totalPoints > 20) {
                            return null;
                          }

                          const className =
                            item?.point === 1
                              ? "horizontal-line"
                              : item?.point === 2
                              ? "diagonal-line"
                              : "reverse-diagonal-line";

                          const lineWidth = item?.point * 30;
                          const lineLeft = skip * 30;

                          skip += item?.point;

                          return (
                            <div
                              key={index}
                              className={className}
                              style={{
                                width: `${lineWidth}px`,
                                left: `${lineLeft}px`,
                              }}
                            />
                          );
                        }
                        return null;
                      });
                    })()}
                  </div>

                  <div className="d-flex justify-content-center">
                    <div className="total-score-box">
                      Total Score:{" "}
                      {calculateTotalPoints(
                        matchData,
                        matchData?.participant2?._id
                      )}
                    </div>
                    {matchData?.winner?._id ? (
                      matchData?.winner?._id ===
                      matchData?.participant2?._id ? (
                        <div className="total-score-box winner">
                          Result: Winner
                        </div>
                      ) : (
                        <div className="total-score-box lose">Result: Lose</div>
                      )
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    style={{ padding: "0 15px" }}
                  >
                    <div className="warnings-section">
                      {["C1", "C2", "C3", "HC", "H"].map((type) => (
                        <div
                          key={type}
                          className={
                            highlightedAkaButtons.includes(type)
                              ? "highlighted warning-block"
                              : "warning-block"
                          }
                          // onClick={() =>
                          //   handleWarningClick(
                          //     matchData?.participant2?._id,
                          //     type.toLowerCase()
                          //   )
                          // }
                        >
                          {type}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          {!matchData?.winner?._id && (
            <Col span={24} style={{ marginTop: "30px" }}>
              <Row>
                <Col span={10}>
                  <Row gutter={[0, 12]}>
                    <Col span={24} className="d-flex justify-content-center">
                      <div>
                        <Button
                          className="score-button green-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant1?._id,
                              1,
                              "score"
                            )
                          }
                        >
                          1
                        </Button>
                        <Button
                          className="score-button orange-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant1?._id,
                              2,
                              "score"
                            )
                          }
                        >
                          2
                        </Button>
                        <Button
                          className="score-button red-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant1?._id,
                              3,
                              "score"
                            )
                          }
                        >
                          3
                        </Button>
                        <Button
                          className="score-button orange-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant1?._id,
                              0,
                              "firstStrike",
                              "player1"
                            )
                          }
                        >
                          S
                        </Button>
                      </div>
                    </Col>
                    <Col span={24} className="d-flex justify-content-center">
                      <div className="warnings-section">
                        {["C1", "C2", "C3", "HC", "H"].map((type) => (
                          <div
                            key={type}
                            className={
                              highlightedAoButtons.includes(type)
                                ? "highlighted penalty-block-panel"
                                : "penalty-block-panel"
                            }
                            onClick={() =>
                              handleWarningClick(
                                matchData?.participant1?._id,
                                type.toLowerCase()
                              )
                            }
                          >
                            {type}
                          </div>
                        ))}
                      </div>
                    </Col>
                    {matchData?.winner?._id !==
                      matchData?.participant1?._id && (
                      <Col span={24} className="d-flex justify-content-center">
                        <button
                          className="blue-team-button"
                          onClick={() => {
                            setWinnerModalOpen(true);
                            setDecidedWinner(matchData?.participant1);
                          }}
                        >
                          Declare AO Winner
                        </button>
                      </Col>
                    )}
                  </Row>
                </Col>

                <Col
                  span={4}
                  className="d-flex flex-column justify-content-center align-items-center"
                >
                  <div className="timing-display">{formatTime(timeLeft)}</div>
                  {/* <div className="timing-display">
                    {Math.max(0, Math.floor(remainingTime / 1000))}
                  </div> */}

                  <div
                    style={{ marginTop: "20px" }}
                    className="d-flex flex-row justify-content-center align-items-center"
                  >
                    {/* <button onClick={startTimer} disabled={isActive}>
                      Start Timer
                    </button>
                    <button onClick={stopTimer} disabled={!isActive}>
                      Stop Timer
                    </button>
                    <button onClick={resetTimer}>Reset Timer</button> */}

                    {!hasStarted ? (
                    <button className="button-29" onClick={startTimer}>
                      Start
                    </button>
                    ) : (
                    <button
                      // className="button-29"
                      style={{ marginLeft: "10px" }}
                      onClick={togglePauseResume}
                    >
                      {isRunning ? "Pause" : "Resume"}
                    </button>
                   )} 
                  </div>
                </Col>

                <Col span={10}>
                  <Row gutter={[0, 12]}>
                    <Col span={24} className="d-flex justify-content-center">
                      <div>
                        <Button
                          className="score-button green-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant2?._id,
                              1,
                              "score"
                            )
                          }
                        >
                          1
                        </Button>
                        <Button
                          className="score-button orange-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant2?._id,
                              2,
                              "score"
                            )
                          }
                        >
                          2
                        </Button>
                        <Button
                          className="score-button red-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant2?._id,
                              3,
                              "score"
                            )
                          }
                        >
                          3
                        </Button>
                        <Button
                          className="score-button orange-button"
                          onClick={() =>
                            handleScoreClick(
                              matchData?.participant2?._id,
                              0,
                              "firstStrike",
                              "player2"
                            )
                          }
                        >
                          S
                        </Button>
                      </div>
                    </Col>
                    <Col span={24} className="d-flex justify-content-center">
                      <div className="warnings-section">
                        {["C1", "C2", "C3", "HC", "H"].map((type) => (
                          <div
                            key={type}
                            className={
                              highlightedAkaButtons.includes(type)
                                ? "highlighted penalty-block-panel"
                                : "penalty-block-panel"
                            }
                            onClick={() =>
                              handleWarningClick(
                                matchData?.participant2?._id,
                                type.toLowerCase()
                              )
                            }
                          >
                            {type}
                          </div>
                        ))}
                      </div>
                    </Col>
                    {matchData?.winner?._id !==
                      matchData?.participant2?._id && (
                      <Col span={24} className="d-flex justify-content-center">
                        <button
                          className="red-team-button"
                          onClick={() => {
                            setWinnerModalOpen(true);
                            setDecidedWinner(matchData?.participant2);
                          }}
                        >
                          Declare AKA Winner
                        </button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </KumitiScorePanelWrapper>
    </>
  );
};

export default KumiteScoreboard;
