import React, { Component } from "react";
import { connect } from "react-redux";
import menuItems from "../../../utils/menu-items";
import config from "../../../utils/config";
import { NavLink, withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Breadcrumb as AntdBreadcrumb } from "antd";
import { useSelector } from "react-redux";
import { selectBreadcrumbData } from "../../../store/selectors";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useHistory } from "react-router-dom";

class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main: [],
      item: [],
    };
  }

  componentDidMount() {
    this.filter();

    const { history } = this.props;

    // this.props.setBreadcrumb({
    //   title: this.state.item.title,
    //   subtitle: "Optional Subtitle",
    //   url: this.props.location.pathname,
    // });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.filter();

      // this.props.setBreadcrumb({
      //   title: this.state.item.title,
      //   subtitle: "Optional Subtitle",
      //   url: this.props.location.pathname,
      // });
    }
  }

  filter = () => {
    menuItems.items.map((item, idx) => {
      if (item.type && item.type === "group") {
        this.getCollapse(item, idx);
      }
      return false;
    });
  };

  getCollapse = (item) => {
    if (item.children) {
      item.children.filter((collapse) => {
        if (collapse.type && collapse.type === "collapse") {
          this.getCollapse(collapse);
        } else if (collapse.type && collapse.type === "item") {
          if (document.location.pathname === config.basename + collapse.url) {
            this.setState({ item: collapse, main: item });
          }
        }
        return false;
      });
    }
  };

  render() {
    const { breadcrumbInfo } = this.props;
    // console.log("bred:", breadcrumbInfo);
    let main, item;
    let breadcrumb = "";
    let title = "Welcome";

    if (this.state.main && this.state.main.type === "collapse") {
      main = (
        <AntdBreadcrumb.Item>
          <NavLink to="/">{this.state.main.title}</NavLink>
        </AntdBreadcrumb.Item>
      );
    }

    if (this.state.item && this.state.item.type === "item") {
      // title = this.state.item.title;
      item = (
        <AntdBreadcrumb.Item>
          <NavLink to="/">{title}</NavLink>
        </AntdBreadcrumb.Item>
      );
    }

    if (this.state.item.breadcrumbs !== false) {
      breadcrumb = (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="ant-page-header-heading">
                  <span className="ant-page-header-heading-title">
                    {breadcrumbInfo.title}
                  </span>
                </div>
                {breadcrumbInfo.subtitle && (
                  <AntdBreadcrumb>
                    {/* {main}
                    {item} */}
                    <div
                      onClick={() => this.props.history.goBack()}
                      className="breadcrumbBack"
                    >
                      {breadcrumbInfo.title}
                    </div>
                    <div style={{ padding: "0 4px" }}>/</div>
                    <div>{breadcrumbInfo.subtitle}</div>
                  </AntdBreadcrumb>
                )}
                {/* <div>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item
                      className="bred-hover"
                    >
                      {breadcrumbInfo.title}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className="bred-hover">
                      {breadcrumbInfo.subtitle}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <div>{breadcrumb}</div>;
  }
}

const mapStateToProps = (state) => ({
  breadcrumbInfo: selectBreadcrumbData(state),
});

const mapDispatchToProps = (dispatch) => ({
  setBreadcrumb: (breadcrumbItems) => dispatch(setBreadcrumb(breadcrumbItems)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Breadcrumb)
);
