import React, { useEffect, useState } from "react";
import fixtures from "../../../../services/fixtures";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Drawer, Table } from "antd";
import event from "../../../../services/event";
import { notify } from "../../../../utils/helper";
import moment from "moment";
import { domain } from "../../../../utils/domainUrl";
import { io } from "socket.io-client";
import { CustomKataRankTable } from "../Styled";
import { FaMedal } from "react-icons/fa";
const socket = io(domain);

const KataRankPanel = () => {
  const [groupMatches, setGroupMatches] = useState([]);
  const [catDetails, setCatDetails] = useState({});
  const [groups, setGroups] = useState([]);


  const query = useParams();

  const getMatchesByGroupName = async () => {
    try {
      const res = await fixtures.getKataMatchesByRankGroupName(
        query?.catid,
        query?.group,
        query?.eventId
      );
      setGroupMatches(res?.data?.matches);
      setCatDetails(res?.data?.cat)
    } catch (err) {}
  };

  // const getCategoryById = async () => {
  //   try {
  //     const res = await event.getCategoryById(query?.catid);
  //     setCatDetails(res?.data);
  //   } catch (err) {
  //     notify("error", "something went wrong");
  //   }
  // };

  const getGroups = async (req, res) => {
    try {
      const res = await fixtures.getFixturekataGroup(query?.catid);
      setGroups(res?.data?.group);
      console.log("res:", res?.data)
    } catch (err) {}
  };

  useEffect(() => {
    getMatchesByGroupName();
    // getCategoryById();
  }, []);

  useEffect(() => {
    try {
      socket.on(`kata-match-update`, (payload) => {
        getMatchesByGroupName();
      });

      socket.on(`event-${query?.eventId}`, (payload) => {
        getMatchesByGroupName();
      });

      return () => {
        socket.off(`kata-match-update`);
        socket.off(`event-${query?.eventId}`);
      };
    } catch (err) {
      // console.error("Socket error:", err);
    }
  }, []);

  const getMaxAndMinIndices = (scores) => {
    const max = Math.max(...scores);
    const min = Math.min(...scores);
    const maxIndex = scores.findIndex((score) => score === max);
    const minIndex = scores.findIndex((score) => score === min);
    return { maxIndex, minIndex };
  };

  const renderCell = (text, record, field, judgeName) => {
    const scores = [
      record.judge1,
      record.judge2,
      record.judge3,
      record.judge4,
      record.judge5,
    ];

    const { maxIndex, minIndex } = getMaxAndMinIndices(scores);
    const fieldIndex = parseInt(field.replace("judge", ""), 10) - 1;

    const getCircleStyle = () => {
      const baseStyle = {
        display: "inline-block",
        width: "45px",
        height: "33px",
        lineHeight: "33px",
        borderRadius: "10%",
        textAlign: "center",
        verticalAlign: "middle",
      };

      if (fieldIndex === maxIndex) {
        return {
          ...baseStyle,
          backgroundColor: "rgba(255, 0, 0, 0.8)",
          color: "white",
        };
      }
      if (fieldIndex === minIndex) {
        return {
          ...baseStyle,
          backgroundColor: "rgba(255, 255, 0, 0.8)",
          color: "black",
        };
      }
      return {};
    };

    const circleStyle = getCircleStyle();

    return (
      <div style={{ width: "80px", textAlign: "center" }}>
        <div style={circleStyle}>{text}</div>
      </div>
    );
  };

  const columns = [
    {
      title: <div className="column-title">Sr. No.</div>,
      dataIndex: "sr",
      key: "sr",
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: <div className="column-title">Name of Player</div>,
      dataIndex: "playerName",
      key: "playerName",
      width: 200,
    },
    {
      title: <div className="column-title">School/Club</div>,
      dataIndex: "school",
      key: "school",
      width: 150,
      align: "center",
    },
    {
      title: <div className="column-title">Kata Name</div>,
      dataIndex: "kataName",
      key: "kataName",
      width: 150,
      align: "center",
    },
    {
      title: <div className="column-title">Judge 1</div>,
      dataIndex: "judge1",
      key: "judge1",
      width: 120,
      render: (text, record) =>
        renderCell(text, record, "judge1", record?.judgeName1),
      align: "center",
    },
    {
      title: <div className="column-title">Judge 2</div>,
      dataIndex: "judge2",
      key: "judge2",
      width: 120,
      render: (text, record) =>
        renderCell(text, record, "judge2", record?.judgeName2),
      align: "center",
    },
    {
      title: <div className="column-title">Judge 3</div>,
      dataIndex: "judge3",
      key: "judge3",
      render: (text, record) =>
        renderCell(text, record, "judge3", record?.judgeName3),
      width: 120,
      align: "center",
    },
    {
      title: <div className="column-title">Judge 4</div>,
      dataIndex: "judge4",
      key: "judge4",
      render: (text, record) =>
        renderCell(text, record, "judge4", record?.judgeName4),
      width: 120,
      align: "center",
    },
    {
      title: <div className="column-title">Judge 5</div>,
      dataIndex: "judge5",
      key: "judge5",
      width: 120,
      render: (text, record) =>
        renderCell(text, record, "judge5", record?.judgeName5),
      align: "center",
    },
    {
      title: <div className="column-title">Total</div>,
      dataIndex: "total",
      key: "total",
      width: 120,
      render: (text, record, index) => {
        index = index + 1;
        const formattedTotal = record?.total;
        let finalScoreClass = "";
        let medalIcon = null;
        if (index === 1) {
          finalScoreClass = "golden";
          medalIcon = <FaMedal style={{ color: "gold", fontSize: "1.5em" }} />;
        } else if (index === 2) {
          finalScoreClass = "silver";
          medalIcon = (
            <FaMedal style={{ color: "silver", fontSize: "1.5em" }} />
          );
        } else if (index === 3 || index === 4) {
          finalScoreClass = "bronze";
          medalIcon = (
            <FaMedal style={{ color: "#cd7f32", fontSize: "1.5em" }} />
          );
        }
        return (
          <div style={{ width: "80px" }}>
            <div className="d-flex justify-content-center">
              <div
                style={{ marginRight: "10px" }}
                className="d-flex align-items-center"
              >
                { groups?.length > 0 && medalIcon }
              </div>
              <div className={finalScoreClass}>
                {formattedTotal ? formattedTotal.toFixed(2) : 0.0}
              </div>
            </div>
          </div>
        );
      },
      align: "center",
    },
  ];

  let data = [];


  let manualRnaking = {
    first: { item: {}, index: 0, exist: false },
    second: { iten: {}, index: 0, exist: false },
    third: { item: {}, index: 0, exist: false },
    fourth: { item: {}, index: 0, exist: false },
  };


  let index = 1;
  for (let item of groupMatches) {
    let details = {
      key: item?._id,
      sr: index + ".",
      playerName: (
        <div className="playerName">
          {item?.participant1?.name ||
            item?.participant1?.firstName + " " + item?.participant1?.lastName}
        </div>
      ),
      school:
        item?.participant1?.instituteId?.name ||
        item?.participant1?.institute?.name,
      kataName: item?.scoreSheet?.kataName || "",
      judge1: item?.scoreSheet?.judge1,
      judge2: item?.scoreSheet?.judge2,
      judge3: item?.scoreSheet?.judge3,
      judge4: item?.scoreSheet?.judge4,
      judge5: item?.scoreSheet?.judge5,
      total: item?.scoreSheet?.totalScore,
    };

    if (item?.rank) {
      if (item?.rank === 1) {
        manualRnaking.first = { item: details, index: index - 1, exist: true };
      }
      if (item?.rank === 2) {
        manualRnaking.second = { item: details, index: index - 1, exist: true };
      }
      if (item?.rank === 3) {
        manualRnaking.third = { item: details, index: index - 1, exist: true };
      }
      if (item?.rank === 4) {
        manualRnaking.fourth = { item: details, index: index - 1, exist: true };
      }
    } else {
      data?.push(details);
    }

    index++;
  }

  if (manualRnaking?.first?.exist) {
    data.splice(0, 0, manualRnaking?.first?.item);
  }
  if (manualRnaking?.second?.exist) {
    data.splice(1, 0, manualRnaking?.second?.item);
  }
  if (manualRnaking?.third?.exist) {
    data.splice(2, 0, manualRnaking?.third?.item);
  }
  if (manualRnaking?.fourth?.exist) {
    data.splice(3, 0, manualRnaking?.fourth?.item);
  }

  return (
    <Drawer
      title=""
      placement="bottom"
      // onClose={() => setOpen(false)}
      open={true}
      width="100%"
      height="100%"
      closable={false}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0 }}
      keyboard={false}
    >
      <Col span={24}>
        <CustomKataRankTable>
          <Table
            dataSource={data}
            columns={columns}
            title={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src="/mpka.jpg"
                  alt="Logo 1"
                  style={{ width: "100px", height: "100px" }}
                />
                <div style={{ textAlign: "center" }}>
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "28px",
                      margin: 0,
                      color: "#fff",
                    }}
                  >
                    {catDetails?.name}
                  </h2>

                  <p style={{ fontSize: "20px", margin: "0 0" }}>
                    {moment(catDetails?.event?.startDate).format(
                      "Do MMMM YYYY"
                    )}{" "}
                    {" - "}
                    {moment(catDetails?.event?.endData).format("Do MMMM YYYY")}
                  </p>

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      margin: "5px 0",
                    }}
                  >
                    KATA FIXTURES
                  </p>
                  <p style={{ fontSize: "20px", margin: 0 }}>
                    Date: {moment().format("Do MMMM YYYY")}
                  </p>
                </div>

                <img
                  src="/bdka.jpg"
                  alt="Logo 2"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
            pagination={false}
            // rowSelection={rowSelection}
          />
        </CustomKataRankTable>
      </Col>
    </Drawer>
  );
};

export default KataRankPanel;
