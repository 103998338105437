import { Col, Drawer, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import fixtures from "../../../../../services/fixtures";
import { FaMedal } from "react-icons/fa";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const KataFinalResultDrawer = () => {
  const [results, setResults] = useState([]);

  const query = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const getkataResult = async () => {
    try {
      const res = await fixtures.getkataResult(query?.catId);
      setResults(res?.data);
      console.log("res:", res?.data);
    } catch (error) {}
  };

  const getkumiteResult = async () => {
    try {
      const res = await fixtures.getkumiteResult(query?.catId);
      setResults(res?.data);
      console.log("res:", res?.data);
    } catch (error) {}
  };

  console.log({params})
  useEffect(() => {
    if(params?.type === 'kata') {
      getkataResult();

    }
    if(params?.type === 'kumite' ){
    getkumiteResult();

    }
  }, []);

  const hasResults = results && results.length > 0;

  return (
    <Drawer
      title=""
      placement="bottom"
      // onClose={() => setOpen(false)}
      open={true}
      width="100%"
      height="100vh"
      closable={false}
      style={{ padding: 0 }}
      bodyStyle={{ padding: 0, backgroundColor: "#282c34" }}
      keyboard={false}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          textAlign: "center",
          padding: "50px",
          backgroundColor: "#282c34",
        }}
      >
        {hasResults ? (
          <>
            <Col span={24} style={{ marginBottom: "70px" }}>
              <Title level={2} style={{ color: "#fff" }}>
                Final Result ( {results[0]?.category?.name} )
              </Title>
            </Col>

            <Row style={{ width: "100%" }}>
              <Col span={8}>
              <p style={{ color: "#fff", fontSize: "2em" }}>
                  {results[1]?.user?.firstName} {results[1]?.user?.lastName}
                </p>
                <FaMedal
                  style={{
                    color: "#C0C0C0",
                    fontSize: "10em",
                    marginBottom: "20px",
                  }}
                />
            

                <Title level={2} style={{ color: "#fff" }}>
                  2nd
                </Title>
              </Col>

              <Col span={8}>
              <p style={{ color: "#fff", fontSize: "2em" }}>
                  {results[0]?.user?.firstName} {results[0]?.user?.lastName}
                </p>
                <FaMedal
                  style={{
                    color: "#FFD700",
                    fontSize: "15em",
                    marginBottom: "20px",
                  }}
                />
                 <Title level={1} style={{ color: "#fff" }}>
                  1st
                </Title>
              
              </Col>

              <Col span={8}>
              <p style={{ color: "#fff", fontSize: "2em" }}>
                  {results[2]?.user?.firstName} {results[2]?.user?.lastName}
                </p>
             
                <FaMedal
                  style={{
                    color: "#CD7F32",
                    fontSize: "7em",
                    marginBottom: "20px",
                  }}
                />
                <Title level={2} style={{ color: "#fff" }}>
                  3rd
                </Title>
              </Col>
            </Row>
          </>
        ) : (
          <Col span={24} style={{ color: "#fff" }}>
            <Title level={2} style={{ color: "#fff" }}>
              No Results Available
            </Title>
            <p style={{ color: "#aaa", fontSize: "1.5em" }}>
              There are currently no results to display. Please check back
              later.
            </p>
          </Col>
        )}
      </Row>
    </Drawer>
  );
};

export default KataFinalResultDrawer;
