import React, { useEffect, useState } from "react";
import { Row, Col, Table, Avatar, Tree, Input, Button } from "antd";
import event from "../../../../services/event";
import moment from "moment";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { ShowTotal } from "../../../../utils/helper";
import KumiteScoreboard from "./category/KumitiScoreBoard";
import { Link } from "react-router-dom";
import { CustomTable } from "../Styled";
import fixtures from "../../../../services/fixtures";
import KataFinalResultDrawer from "./category/KataFinalResultDrawer";
import { useParams } from "react-router-dom";
import AddCategoriesModal from "./AddCategoriesModal";

const EventCatagories = ({ eventId }) => {
  const [apiData, setApiData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState([""]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState([]);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState("");
  const [openKataResult, setOpenkataResult] = useState(false);
  const [openCategoriesModal, setOpenCategoriesModal] = useState(false);

  const query = useParams();

  const getEventCategoryDetailsById = async (eventId, searchText = "") => {
    try {
      const res = await event.getEventCategoryDetailsById(
        eventId,
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      // console.log("category:", res?.data?.response?.records)
      setCount(res?.data?.response?.totalItems);
    } catch (err) {}
  };

  const handleSearch = (value) => {
    getEventCategoryDetailsById(eventId, value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  useEffect(() => {
    getEventCategoryDetailsById(eventId);
  }, [
    eventId,
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
  ]);

  const getEventCategoryDetailsByIdTreeData = async (
    eventId,
    searchText = ""
  ) => {
    try {
      const res = await event.getEventCategoryDetailsById(eventId, count);
      setTotalData(res?.data?.response?.records);
    } catch (err) {}
  };

  useEffect(() => {
    if (count) {
      getEventCategoryDetailsByIdTreeData(eventId);
    }
  }, [count]);

  const openInNewTab = (catId, type) => {
    const url = `/pages/events/all-events/${query?.EventId}/result-sheet/${catId}?type=${type}`;
    window.open(url, "_blank");
  };

  const capitalizeFirstLetter = (str) => {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            // maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            <Link
              // to={`/pages/events/all-events/category/${item?._id}/matches`}
              to={{
                pathname: `/pages/events/all-events/${eventId}/category/${item?._id}/matches`,
                state: { name: item?.name, type: item?.type },
              }}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.name || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort: item?.name || "-",
      type: (
        <div style={{ width: "120px" }}>
          {item?.type ? capitalizeFirstLetter(item?.type) : "-"}
        </div>
      ),
      description: (
        <div style={{ width: "300px" }}>
          {item?.description ? item?.description : "-"}
        </div>
      ),

      gender: (
        <div style={{ width: "100px" }}>
          {item?.gender ? capitalizeFirstLetter(item?.gender) : "-"}
        </div>
      ),
      age: (
        <div style={{ width: "150px" }}>
          {item?.age?.min
            ? item?.age?.min + " to " + item?.age?.max + " years"
            : "-"}
        </div>
      ),

      weight: (
        <div style={{ width: "150px" }}>
          {item?.weight?.min
            ? item?.weight?.min + " - " + item?.weight?.max + " kg"
            : "-"}
        </div>
      ),

      team: (
        <div style={{ width: "120px" }}>
          {item?.team ? "Team" : "Individual"}
        </div>
      ),

      startDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.startDate).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      endDate: (
        <div style={{ width: "200px" }}>
          {moment(item?.endDate).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),
      result: (
        <div>
          <Button
            onClick={() => {
              setSelectedCategoryItem(item?._id);
              openInNewTab(item?._id, item?.type);
            }}
          >
            Result
          </Button>
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "Category Name",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        // multiple: 3,
      },
    },
    {
      title: "Type",
      className: "ant-table-row-cell-break-word",
      dataIndex: "type",
      align: "center",
      // sorter: {
      //   compare: (a, b) => a.age - b.age,
      //   multiple: 2,
      // },
    },
    {
      title: "Age Limit",
      className: "ant-table-row-cell-break-word",
      dataIndex: "age",
      align: "center",
      //   sorter: {
      //     compare: (a, b) => a.age - b.age,
      //     multiple: 2,
      //   },
    },
    {
      title: "Weight Range",
      className: "ant-table-row-cell-break-word",
      dataIndex: "weight",
      align: "center",
      //   sorter: {
      //     compare: (a, b) => a.age - b.age,
      //     multiple: 2,
      //   },
    },
    {
      title: "Gender",
      className: "ant-table-row-cell-break-word",
      dataIndex: "gender",
      align: "center",
      //   sorter: {
      //     compare: (a, b) => a.age - b.age,
      //     multiple: 2,
      //   },
    },
    {
      title: "Team",
      className: "ant-table-row-cell-break-word",
      dataIndex: "team",
      align: "center",
      //   sorter: {
      //     compare: (a, b) => a.age - b.age,
      //     multiple: 2,
      //   },
    },
    {
      title: "Result",
      className: "ant-table-row-cell-break-word",
      dataIndex: "result",
      align: "center",
      // fixed: "right"
    },
  ];

  const processParticipants = (participants, category, age, weight) => {
    return participants.map((participant) => {
      const { _id, firstName, lastName, city, email, mobile } = participant;
      const uniqueKey = `${_id}-${category}-${age.min}-${age.max}-${
        weight?.min ?? 0
      }-${weight?.max ?? 0}`;
      const children = [];

      if (city) {
        children.push({
          title: `City: ${city}`,
          key: `${uniqueKey}-city`,
        });
      }
      if (email) {
        children.push({
          title: `Email: ${email}`,
          key: `${uniqueKey}-email`,
        });
      }
      if (mobile) {
        children.push({
          title: `Mobile: ${mobile}`,
          key: `${uniqueKey}-mobile`,
        });
      }

      return {
        title: `${firstName} ${lastName}`,
        key: `${uniqueKey}-participant`,
        children,
      };
    });
  };

  const processParticipantsTeams = (participants, category, age, weight) => {
    return participants.map((participant) => {
      const { _id, name } = participant;
      const uniqueKey = `${_id}-${category}-${age.min}-${age.max}-${
        weight?.min ?? 0
      }-${weight?.max ?? 0}`;
      const children = [];

      // if (city) {
      //   children.push({
      //     title: `City: ${city}`,
      //     key: `${uniqueKey}-city`,
      //   });
      // }
      // if (email) {
      //   children.push({
      //     title: `Email: ${email}`,
      //     key: `${uniqueKey}-email`,
      //   });
      // }
      // if (mobile) {
      //   children.push({
      //     title: `Mobile: ${mobile}`,
      //     key: `${uniqueKey}-mobile`,
      //   });
      // }

      return {
        title: `${name}`,
        key: `${_id}-participant`,
        children,
      };
    });
  };

  const processTreeData = (data) => {
    const groupedData = {
      kata: [],
      kumite: [],
      "team kata": [],
      "team kumite": [],
    };

    // Group data based on the type, team, and other properties
    data.forEach((item) => {
      const {
        type,
        age,
        totalParticipant,
        totalTeamParticipant,
        team,
        weight,
        _id,
      } = item;

      let category = type;
      if (team) {
        category = `team ${type}`;
      }

      groupedData[category].push({
        title:
          `Age: ${age?.min} - ${age?.max} years` +
          (weight?.min !== undefined && weight?.max !== undefined
            ? `, Weight: ${weight?.min} - ${weight?.max} kg`
            : ""),
        key: `${_id}-age`,
        children: [
          {
            title: `Participants: ${
              !team ? totalParticipant.length : totalTeamParticipant?.length
            }`,
            key: `${_id}-participants`,
            children: !team
              ? processParticipants(totalParticipant, category, age, weight)
              : processParticipantsTeams(
                  totalTeamParticipant,
                  category,
                  age,
                  weight
                ), // Pass category, age, and weight for unique keys
          },
        ],
      });
    });

    return Object.keys(groupedData).map((category) => ({
      title: capitalizeFirstLetter(category),
      key: category,
      children: groupedData[category],
    }));
  };

  useEffect(() => {
    const data = processTreeData(totalData);
    setTreeData(data);

    const allKeys = data.flatMap((category) => [
      category.key,
      ...category.children.map((child) => child.key),
    ]);
    setDefaultExpandedKeys(allKeys);
  }, [totalData, totalData.length]);

  useEffect(() => {
    const baseurl = window.location;
    if (baseurl.pathname.includes("/kata-result")) {
      setOpenkataResult(true);
    }
  }, []);

  const onSelect = (selectedKeys, info) => {};

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  return (
    <>
      {/* {openKataResult && (
        <KataFinalResultDrawer
          open={openKataResult}
          setOpen={setOpenkataResult}
          selectedCategoryItem={selectedCategoryItem}
        />
      )} */}
      {openCategoriesModal && (
        <AddCategoriesModal
          open={openCategoriesModal}
          setOpen={setOpenCategoriesModal}
          // getActiveEvent={getActiveEvent}
          // selectedEvent={selectedRowKeys}
        />
      )}
      <Row gutter={[24, 0]} className="mt-24">
        <Col
          sm={24}
          md={24}
          lg={24}
          className="header-control"
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            class="button-29"
            role="button"
            onClick={() => setOpenCategoriesModal(true)}
          >
            Add Categories
          </button>
          <Input
            className="header-search"
            placeholder="Search here..."
            prefix={<SearchOutlined />}
            style={{ width: "300px" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col sm={24} md={24} lg={17}>
          <CustomTable>
            <Table
              tableLayout="auto"
              scroll={scroll}
              columns={columns}
              dataSource={data}
              bordered={false}
              // rowSelection={rowSelection}
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: 1,
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100"],
                total: count,
                onChange: (currentPage, size) => {
                  setPagination({ size, currentPage });
                },
                showTotal: (total, range) => (
                  <ShowTotal
                    selectedRowKeys={selectedRowKeys}
                    total={total}
                    currentPageItem={range[1] - range[0] + 1}
                    ranged={range}
                  />
                ),
              }}
              onChange={handleChangeTable}
            />
          </CustomTable>
        </Col>

        <Col sm={24} md={24} lg={7}>
          <div style={{ maxHeight: "550px", overflowY: "auto" }}>
            <Tree
              showLine
              switcherIcon={<DownOutlined />}
              onSelect={onSelect}
              treeData={treeData}
              // defaultExpandedKeys={["Kata", "Kumite"]}
              defaultExpandedKeys={defaultExpandedKeys}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EventCatagories;
