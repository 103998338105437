// LocalStorageContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const LocalStorageContext = createContext();

export const LocalStorageProvider = ({ children }) => {
    const [kataDisplayValue, setKataDisplayValue] = useState(localStorage.getItem('kata-match-id') || '');

    const [kumiteDisplayValue, setKumiteDisplayValue] = useState(localStorage.getItem('kumite-match-id') || '');

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'kata-match-id') {
              setKataDisplayValue(event.newValue);
            }
            if (event.key === 'kumite-match-id') {
                setKumiteDisplayValue(event.newValue);
              }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <LocalStorageContext.Provider value={{ kataDisplayValue, setKataDisplayValue, setKumiteDisplayValue , kumiteDisplayValue}}>
            {children}
        </LocalStorageContext.Provider>
    );
};

export const useLocalStorage = () => {
    return useContext(LocalStorageContext);
};
