import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Upload,
  Row,
  Col,
  Select,
  DatePicker,
} from "antd";
import { MdCloudUpload } from "react-icons/md";
import expense from "../../../../services/expense";
import { notify } from "../../../../utils/helper";
import event from "../../../../services/event";
import ModalWrapper from "../../../modal/ModalWrapper";

const { Option } = Select;

const CreateExpenseModal = ({ open, setOpen, getAllExpense, eventId, getEventExpenseById }) => {
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [file, setFile] = useState(null);

  const [form] = Form.useForm();

  const getAllEvent = async () => {
    try {
      const res = await event.getAllEvent();
      // setAllEvents(res?.data);
      setAllEvents(res?.data?.response?.records);
    } catch (error) {}
  };

  const createExpense = async (values) => {
    try {
      const res = await expense.createExpense({
        event: eventId ? eventId :selectedEventId,
        name: values?.name,
        cost: values?.cost,
        description: values?.comments,
      });
      if (file) {
        await handleUpload(res?.data._id, file?.file);
      }
      notify("success", "Expense Added Successfully.");
      form.resetFields();
      setOpen(false);
      {eventId ? getEventExpenseById(eventId):getAllExpense()}
    } catch (error) {}
  };

  const handleUpload = async (id, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await expense.uploadAttachmentToS3(id, formData);
      // notify("success", "File uploaded successfully.");
    } catch (err) {
      console.error("File upload error:", err);
    }
  };

  const handleFileChange = (file) => {
    setFile(file);
  };

  const beforeUpload = (file) => {
    setFile(file);
    return false;
  };

  useEffect(() => {
    getAllEvent();
  }, []);

  return (
    <ModalWrapper
      title="Add Expense"
      open={open}
      setOpen={setOpen}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={createExpense}>
        {!eventId && (
          <Form.Item
            name="event"
            label="Events Name"
            rules={[{ required: true, message: "Please enter the event name" }]}
          >
            <Select onChange={(e) => setSelectedEventId(e)}>
              {allEvents?.map((item, index) => (
                <Option key={index} value={item?._id}>
                  {item?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="name"
          label="Expense Name"
          rules={[{ required: true, message: "Please enter the expense name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="cost"
          label="Expense Cost"
          rules={[{ required: true, message: "Please enter the expense cost" }]}
        >
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="createdAt"
          label="Created At"
          rules={[
            { required: true, message: "Please select the creation date" },
          ]}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item name="comments" label="Comments">
          <Input.TextArea rows={4} placeholder="Enter any comments or notes" />
        </Form.Item>
        <Form.Item
          name="attachment"
          label="Attachment"
          valuePropName="fileList"
          getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
        >
          <Upload
            name="attachment"
            listType="text"
            multiple={false}
            accept="image/*,.pdf"
            type="file"
            beforeUpload={beforeUpload}
            onChange={handleFileChange}
          >
            <Button icon={<MdCloudUpload />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item className="d-flex justify-content-center">
          <Button className="button-29" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};

export default CreateExpenseModal;
