import React from "react";
import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Segmented,
  Select,
  Slider,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import event from "../../../../services/event";
import { notify } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
const TatamiDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [eventName, setEventName] = useState("");
  const [id, setId] = useState(null);
  const [form] = Form.useForm();
  const { ringId } = useParams();
  const [user, setUser] = useState({});


  const dispatch = useDispatch();
  const history = useHistory();

  const getTatamiById = async (ringId) => {
    try {
      const res = await event.getTatamiById(ringId);
      setUser(res?.data);
    } catch (error) {
      //   console.error("Error fetching tatami data", error);
    }
  };

  const getAllEvents = async () => {
    try {
      const res = await event.getAllEvent();
      setAllEvents(res?.data?.response?.records);
    } catch (err) {}
  };

  useEffect(() => {
    getTatamiById(ringId);
    getAllEvents();
  }, []);

  const getEventById = async (eventId) => {
    try {
      const res = await event.getEventById(eventId);
      setEventName(res?.data?.name);
    } catch (error) {}
  };

  useEffect(() => {
    if(user?.event){
      getEventById(user?.event);
    }
  }, [user?.event]);

  

  const updateTatami = async (values) => {
  
    try {
      const res = await event.updateTatami(ringId, values);
      notify("success", "Tatami Updated Successfully.");
      getTatamiById(ringId);
      setIsEditUser(false);
    } catch (err) {}
  };

  // Function to populate the form fields with user data
  const populateFormFields = () => {
    form.setFieldsValue({
      name: user?.name,
      event: user?.event,
      address: user?.address,
    });
  };

  // When edit mode is activated, populate the form fields
  useEffect(() => {
    if (isEditUser) {
      populateFormFields();
    }
  }, [isEditUser, user]);

  useEffect(() => {
    if (user?.name)
      dispatch(
        setBreadcrumb({
          title: "Tatami",
          subtitle: user?.name,
          url: "",
        })
      );
  }, [user]);

  return (
    <div>
      <Card
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                {/* <Avatar
                  size={74}
                  shape="square"
                  src={
                    user?.profilePicture ? (
                      user?.profilePicture
                    ) : (
                      <div style={{ background: "gray" }}>
                        {user?.name
                          ?.split(/[\s-]/)
                          .filter((word) => isNaN(word))
                          .map((word) => word.charAt(0).toUpperCase() + " ")
                          .join("")}
                      </div>
                    )
                  }
                /> */}

                <div className="avatar-info" style={{ marginLeft: "10px" }}>
                  <h4 className="font-semibold m-0">{user?.name}</h4>
                  <p>{user?.role}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <Segmented
                options={options}
                onChange={(value) => {
                  setTab(value);
                }}
              /> */}
            </Col>
          </Row>
        }
      >
        <>
          <Form form={form} onFinish={updateTatami}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div
                  className="w-100"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6>Summary</h6>
                  <div className="px-3">
                    {!isEditUser ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsEditUser(!isEditUser)}
                      >
                        <EditOutlined color="var(--text-primary)" size={20} />
                      </div>
                    ) : (
                      <div>
                        <Button
                          style={{
                            marginRight: "10px",
                          }}
                          type="primary"
                          onClick={() => setIsEditUser(!isEditUser)}
                          className="font-bold cursor-pointer mx-4"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="font-bold cursor-pointer mx-4"
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24} className="m-2"></Col>
              <Col span={12}>
                <h6 className="list-header text-m text-muted m-0">Name</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.name}</div>
                )}
              </Col>
              <Col span={12}>
                <h4 className="list-header text-m text-muted m-0">Event</h4>
                {isEditUser ? (
                  <Form.Item
                    style={{ margin: 0 }}
                    name="event"
                    rules={[
                      {
                        required: true,
                        message: "event is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select an event"
                      value={id}
                      onChange={(e) => setId(e)}
                    >
                      {allEvents.map((event) => (
                        <Select.Option key={event._id} value={event._id}>
                          {event?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{eventName}</div>
                )}
              </Col>
              {/* <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">Address</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ margin: 0 }}
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "address is required!",
                      },
                    ]}
                  >
                    <Input.TextArea placeholder="Enter address" rows={4} />
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.address}</div>
                )}
              </Col> */}

              <Col span={24} className="m-3 text-center">
                {!isEditUser && (
                  <>
                    <Divider />

                    <Button
                      type="primary"
                      className="font-bold cursor-pointer mx-4 p-0"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </>
      </Card>
    </div>
  );
};

export default TatamiDetails;
