import parseJwt from './parseJwt';

export const setItemFromLocalStrage = (itemName, item) => localStorage.setItem(itemName, item);

export const getItemFromLocalStorage = (itemName) => localStorage.getItem(itemName);

export const clearLocalStorage = () => localStorage.clear();

export const removeItemfromLocalStorage = (itemName) => localStorage.removeItem(itemName);

export const extractToken = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return {};
  return parseJwt(accessToken);
};

export const isLogin =() => {
    const user = extractToken();
    if(user.id) return true;
    else return false;
}