import React, { useState } from "react";
import { Divider, Input, Popover } from "antd";
import { FilterTag } from "./FilterTag";
import { BiFilter } from "react-icons/bi";
import Scrollbars from "react-custom-scrollbars";
import { AiTwotoneFilter } from "react-icons/ai";
import { filterTagColor } from "./helper";
import { MdClose } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";

const Filter = ({
  show,
  setShow,
  data,
  setIsTargetAsset,
  targetAssetValue,
  setSelectedGraph,
}) => {
  const [searchText, setSearchText] = useState("");



  return (
    <div className="w-100" style={{ display: "flex", alignItems: "center" }}>
      <div>
        <Popover
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          placement="topLeft"
          title={null}
          content={
            <div>
              <div>
                <Input
                  placeholder="Search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <div>
                <div className="filter-container">
                  <Scrollbars style={{ height: "150px" }}>
                    {data?.map((dataItem, index) => (
                      <span key={index}>
                        <div className="row-flex justify-content-between">
                          <span
                            style={{
                              fontSize: "12px",
                              marginBottom: "10px",
                              fontWeight: "600",
                            }}
                          >
                            {dataItem?.title}
                          </span>
                        </div>
                        <div className="row-flex" style={{ flexWrap: "wrap" }}>
                          {dataItem?.list
                            ?.filter(
                              (fil) =>
                                !dataItem?.filterList?.includes(fil) &&
                                fil
                                  .toLowerCase()
                                  .includes(searchText.toLocaleLowerCase())
                            )
                            ?.map((item) => (
                              <FilterTag
                                onClick={() => {
                                  dataItem?.setFilterList([
                                    ...dataItem?.filterList,
                                    item,
                                  ]);
                                }}
                                item={item}
                              />
                            ))}
                        </div>
                        <div className="">
                          {dataItem?.list?.filter(
                            (fil) =>
                              !dataItem?.filterList?.includes(fil) &&
                              fil
                                .toLowerCase()
                                .includes(searchText.toLocaleLowerCase())
                          )?.length ? (
                            ""
                          ) : (
                            <span style={{ fontSize: "10px" }}>Not Found</span>
                          )}
                        </div>
                        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                      </span>
                    ))}
                  </Scrollbars>
                </div>
              </div>
            </div>
          }
          trigger="click"
        >
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <div>
              <AiTwotoneFilter
                style={{ cursor: "pointer" }}
                onClick={() => setShow(!show)}
                size={20}
              />
            </div>
          </div>
        </Popover>
      </div>
      <div style={{ display: "flex" }}>
        {data?.map((dataItem) =>
          dataItem?.filterList?.map((item, index) => (
            <div >
              <FilterTag
                key={index}
                onClick={() => {
                  if (targetAssetValue && item === targetAssetValue) {
                    setSelectedGraph(null);
                    setIsTargetAsset(false);
                  }
                  dataItem?.setFilterList(
                    dataItem?.filterList?.filter((fil) => fil !== item)
                  );
                }}
                item={item}
                closIcon={
                 
                    <IoCloseOutline
                      // size={16}
                      style={{
                        fontSize: "10px",
                        cursor: "pointer",
                        // color:'white',
                      }}
                      className="mx-1"
                    />
                 
                }
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Filter;
