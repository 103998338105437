/**
 *
 * @param {*} token jwt token
 * @returns  extract token data
 */
const parseJwt = (token) => {
    try {
      const base64Url = token?.split('.')?.[1];
      const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          ?.split('')
          ?.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          ?.join('')
      );
  
      return JSON.parse(jsonPayload);
    } catch (err) {
      localStorage.removeItem('accessToken')
      window.location.href = '/login';
    }
  };
  export default parseJwt;
  