
import { EditOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Segmented,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import users from "../../../../services/users";
import { notify } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";

const { Option } = Select;

const RefereeDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const [tab, setTab] = useState("Personal Info");
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();


  const [user, setUser] = useState({});

  const getJudgesAndRefereesById = async (id) => {
    try {
      let res = await users.getJudgesAndRefereesById(id);
      setUser(res?.data?.user);
    } catch (err) {}
  };

  useEffect(() => {
    getJudgesAndRefereesById(id);
  }, [id]);



  // Function to populate the form fields with user data
  const populateFormFields = () => {
    form.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
      dateOfBirth: user?.dateOfBirth ? moment(user?.dateOfBirth) : null,
      class: user?.class,
      weight: user?.weight,
      state: user?.state,
      district: user?.district,
      city: user?.city,
      pincode: user?.pincode,
      mobile: user?.mobile,
    });
  };

  // When edit mode is activated, populate the form fields
  useEffect(() => {
    if (isEditUser) {
      populateFormFields();
    }
  }, [isEditUser, user]);

  const options =
    user?.role === "student" ? ["Personal Info", "Events"] : ["Personal Info"];

  const updateAnyJudgesOrReferees = async (values) => {


    try {
      let res = await users.updateAnyJudgesOrReferees(id, values);
      notify("success", "Referee Updated Successfully.");
      setIsEditUser(false);
      getJudgesAndRefereesById(id);
    } catch (err) {
    
    }
  };
  useEffect(() => {
    if (user?._id)
      dispatch(
        setBreadcrumb({
          title: "Raferee",
          subtitle: user?.firstName + " " + user?.lastName,
          url: "",
        })
      );
  }, [user]);


  return (
    <div>
      <Card
        // className="card-profile-head"
        // bodyStyle={{ display: "block" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar
                  size={74}
                  shape="square"
                  src={
                    user?.profilePicture ? (
                      user?.profilePicture
                    ) : (
                      <div style={{ background: "gray" }}>
                        {user?.firstName?.[0]?.toUpperCase() +
                          user?.lastName?.[0]?.toUpperCase() || "-"}
                      </div>
                    )
                  }
                />

                <div className="avatar-info" style={{ marginLeft: "10px" }}>
                  <h4 className="font-semibold m-0">
                    {user?.firstName} {user?.lastName}
                  </h4>
                  <p>{user?.role}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Segmented
                options={options}
                onChange={(value) => {
                  setTab(value);
                }}
              />
            </Col>
          </Row>
        }
      >
        <Form form={form} onFinish={updateAnyJudgesOrReferees}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div
                className="w-100"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h6>Summary</h6>
                <div className="px-3">
                  {!isEditUser ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditUser(!isEditUser)}
                    >
                      <EditOutlined color="var(--text-primary)" size={20} />
                    </div>
                  ) : (
                    <div>
                      <Button
                        style={{
                          marginRight: "10px",
                        }}
                        type="primary"
                        onClick={() => setIsEditUser(!isEditUser)}
                        className="font-bold cursor-pointer mx-4"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="font-bold cursor-pointer mx-4"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24} className="m-2"></Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">First Name</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ marginBottom: 10 }}
                  className="username"
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: "firstname is required!",
                    },
                  ]}
                >
                  <Input placeholder="Firstname" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.firstName}</div>
              )}
            </Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">Last Name</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="lastName"
                  rules={[
                    {
                      required: true,
                      message: "lastname is required!",
                    },
                  ]}
                >
                  <Input placeholder="Lastname" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.lastName}</div>
              )}
            </Col>

            <Col span={6}>
              {/* <div className="subHeading">Email</div> */}
              <h6 className="list-header text-m text-muted m-0">Email</h6>
              {/* {isEditUser ? (
          <Form.Item
            style={{ margin: 0 }}
            className="email"
            name="email"
            rules={[
              {
                required: true,
                message: "email is required!",
              },
            ]}
          >
            <Input placeholder="email" />
          </Form.Item> */}
              {/* ) : ( */}
              <div className="font-semibold">{user?.email}</div>
              {/* )} */}
            </Col>

            <Col span={6}>
              {/* <div className="subHeading">Member since</div> */}
              <h6 className="list-header text-m text-muted m-0">
                Member since
              </h6>
              <div className="font-semibold">
                {moment(user?.createdAt).format("DD MMM YYYY")}
              </div>
            </Col>
            {user?.role === "student" && (
              <>
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">D.O.B</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="dateOfBirth"
                      name="dateOfBirth"
                      rules={[
                        {
                          required: true,
                          message: "Date of birth is required!",
                        },
                      ]}
                    >
                      {/* <Input placeholder="Date of birth" /> */}
                      <DatePicker
                        format="DD/MM/YYYY"
                        onChange={(date, dateString) => {
                          const formattedDate =
                            moment(date).format("DD/MM/YYYY");
                          
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">
                      {" "}
                      {moment(user?.dateOfBirth).format("DD MMM YYYY")}
                    </div>
                  )}
                </Col>

                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">Class</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="username"
                      name="class"
                      rules={[
                        {
                          required: true,
                          message: "Class is required!",
                        },
                      ]}
                    >
                      <Input placeholder="Class" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold"> {user?.class}</div>
                  )}
                </Col>

                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">Weight</h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      className="username"
                      name="weight"
                      rules={[
                        {
                          required: true,
                          message: "Weight is required!",
                        },
                      ]}
                    >
                      <Input placeholder="Weight" />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold"> {user?.weight} Kg</div>
                  )}
                </Col>
              </>
            )}
            <Col span={6}>
              {/* <div className="subHeading">Email</div> */}
              <h6 className="list-header text-m text-muted m-0">State</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="state"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "state is required!",
                    },
                  ]}
                >
                  <Input placeholder="state" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.state}</div>
              )}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Email</div> */}
              <h6 className="list-header text-m text-muted m-0">District</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="district"
                  name="district"
                  rules={[
                    {
                      required: true,
                      message: "district is required!",
                    },
                  ]}
                >
                  <Input placeholder="district" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.district}</div>
              )}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Email</div> */}
              <h6 className="list-header text-m text-muted m-0">City</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="city"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "city is required!",
                    },
                  ]}
                >
                  <Input placeholder="city" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.city}</div>
              )}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Email</div> */}
              <h6 className="list-header text-m text-muted m-0">Pincode</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="pincode"
                  name="pincode"
                  rules={[
                    {
                      required: true,
                      message: "pincode is required!",
                    },
                  ]}
                >
                  <Input placeholder="pincode" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.pincode}</div>
              )}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Email</div> */}
              <h6 className="list-header text-m text-muted m-0">Mobile</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="mobile"
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "mobile is required!",
                    },
                  ]}
                >
                  <Input placeholder="mobile" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{user?.mobile}</div>
              )}
            </Col>
            <Col span={24} className="m-3"></Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default RefereeDetails;
