import { Row, Col, Card, Button, Avatar } from "antd";
import project1 from "../../images/home-decor-2.jpeg";
import project2 from "../../images/home-decor-2.jpeg";
import project3 from "../../images/home-decor-3.jpeg";
import profilavatar from "../../images/face-1.jpg";
import convesionImg from "../../images/face-3.jpg";
import convesionImg2 from "../../images/face-4.jpg";
import convesionImg3 from "../../images/face-5.jpeg";
import { useEffect, useState } from "react";
import event from "../../../services/event";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";

const ActiveEvents = () => {
  const [activeEvents, setActiveEvents] = useState([]);
  const [banners, setBanners] = useState({});

  const dispatch = useDispatch();

  const getActiveEvent = async () => {
    try {
      const res = await event.getActiveEvent();
      setActiveEvents(res?.data);
      console.log("active:", res?.data);
    } catch (error) {}
  };

  const handleDownload = async (id, fileName) => {
    try {
      const res = await event.downloadBanner(id, { fileName: fileName });
      return res?.data;
    } catch (err) {}
  };

  const getBannder = async (eventId, bannderImage) => {
    if (bannderImage) {
      let image = await handleDownload(eventId, bannderImage);
      return image;
    }
  };

  const fetchBanners = async () => {
    const bannerPromises = activeEvents.map(async (p) => {
      if (p?.bannerImage) {
        const imageUrl = await getBannder(p?._id, p?.bannerImage);
        return { eventId: p._id, imageUrl };
      }
      return null;
    });

    const bannersArray = await Promise.all(bannerPromises);
    const bannersMap = bannersArray.reduce((acc, curr) => {
      if (curr) {
        acc[curr.eventId] = curr.imageUrl;
      }
      return acc;
    }, {});
    setBanners(bannersMap);
  };

  useEffect(() => {
    if (activeEvents?.length) fetchBanners();
  }, [activeEvents]);

  useEffect(() => {
    getActiveEvent();
    dispatch(
      setBreadcrumb({
        title: "Events",
        subtitle: "All Active Events",
        url: "",
      })
    );
  }, []);

  return (
    <div>
      <Card
        bordered={false}
        className="header-solid mb-24"
        title={
          <>
            <h6 className="font-semibold">ACTIVE EVENTS</h6>
            {/* <p>Architects design houses</p> */}
          </>
        }
      >
        <Row gutter={[24, 24]} style={{ height: "72vh", overflow: "scroll" }}>
          {activeEvents.map((event, index) => (
            <Col span={24} key={event._id} style={{ marginBottom: "20px" }}>
              <Card bordered={false} hoverable>
                <Row gutter={[16, 16]}>
                  <Col
                    span={6}
                    className="col-gallery"
                    style={{ height: "170px", maxWidth: "250px" }}
                  >
                    {event?.bannerImage ? (
                      <img
                        src={banners[event._id] || ""}
                        alt={`${event.name} Banner`}
                        className="product-img shadow-3 img-contain"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <div
                        className="product-img-fallback shadow-3"
                        style={{
                          backgroundColor: "#f0f0f0",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Image not available
                      </div>
                    )}
                  </Col>
                  <Col span={16}>
                    <div>
                      <h5 style={{ color: "#0f51b5", marginBottom: "6px" }}>
                        {event.name}
                      </h5>
                      <h1 style={{ fontSize: "1.2em", margin: "0 0 12px 0" }}>
                        Organizer: {event.organizerName}
                      </h1>
                      <p style={{ margin: "8px 0" }}>
                        Event Type: {event.eventType}
                      </p>
                      <p style={{ margin: "8px 0" }}>
                        Level: {event.eventLevel}
                      </p>
                      <p style={{ margin: "8px 0" }}>
                        Location: {event.address}, {event.city},{" "}
                        {event.district}, {event.state} - {event.pinCode}
                      </p>
                      <p style={{ margin: "8px 0" }}>
                        Start Date:{" "}
                        {moment(event.startDate).format("DD/MM/YYYY")} - End
                        Date: {moment(event.endDate).format("DD/MM/YYYY")}
                      </p>
                      <div
                        className="d-flex"
                        style={{ justifyContent: "start", marginTop: "10px" }}
                      >
                        <div className="card-tag">
                          Last date of registration:
                          {moment(event.registrationLastDate).format(
                            "DD/MM/YYYY"
                          )}
                        </div>
                      </div>
                      <p style={{ margin: "8px 0" }}>
                        Contact: {event.contact.name}, Phone:{" "}
                        {event.contact.phone}
                      </p>
                      <div style={{ marginTop: "10px" }}>
                        <a
                          href={`https://${event.social.website}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Website
                        </a>{" "}
                        |
                        <a
                          href={event.social.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: "10px" }}
                        >
                          Facebook
                        </a>{" "}
                        |
                        <a
                          href={`https://twitter.com/${event.social.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: "10px" }}
                        >
                          Twitter
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col
                    span={2}
                    className="d-flex align-item-center"
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Button type="primary" size="small">
                      <Link
                        to={`/pages/events/active-events/${event._id}/active-event-details`}
                      >
                        VIEW DETAILS
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default ActiveEvents;
