export const kataListName = [
  {"label": "1", "value": "Anan"},
  {"label": "2", "value": "Anan Dai"},
  {"label": "3", "value": "Ananko"},
  {"label": "4", "value": "Aoyagi"},
  {"label": "5", "value": "Bassai"},
  {"label": "6", "value": "Bassai Dai"},
  {"label": "7", "value": "Bassai Sho"},
  {"label": "8", "value": "Chatanyara Kusanku"},
  {"label": "9", "value": "Chibana No Kushanku"},
  {"label": "10", "value": "Chinte"},
  {"label": "11", "value": "Chinto"},
  {"label": "12", "value": "Enpi"},
  {"label": "13", "value": "Fukyugata Ichi"},
  {"label": "14", "value": "Fukyugata Ni"},
  {"label": "15", "value": "Gankaku"},
  {"label": "16", "value": "Garyu"},
  {"label": "17", "value": "Gekisai (Geksai) 1"},
  {"label": "18", "value": "Gekisai (Geksai) 2"},
  {"label": "19", "value": "Gojushiho"},
  {"label": "20", "value": "Gojushiho Dai"},
  {"label": "21", "value": "Gojushiho Sho"},
  {"label": "22", "value": "Hakusho"},
  {"label": "23", "value": "Hangetsu"},
  {"label": "24", "value": "Haufa (Haffa)"},
  {"label": "25", "value": "Heian Shodan"},
  {"label": "26", "value": "Heian Nidan"},
  {"label": "27", "value": "Heian Sandan"},
  {"label": "28", "value": "Heian Yondan"},
  {"label": "29", "value": "Heian Godan"},
  {"label": "30", "value": "Heiku"},
  {"label": "31", "value": "Ishimine Bassai"},
  {"label": "32", "value": "Itosu Rohai Shodan"},
  {"label": "33", "value": "Itosu Rohai Nidan"},
  {"label": "34", "value": "Itosu Rohai Sandan"},
  {"label": "35", "value": "Jiin"},
  {"label": "36", "value": "Jion"},
  {"label": "37", "value": "Jinnu"},
  {"label": "38", "value": "Jitte"},
  {"label": "39", "value": "Juroku"},
  {"label": "40", "value": "Kanchin"},
  {"label": "41", "value": "Kanku Dai"},
  {"label": "42", "value": "Kanku Sho"},
  {"label": "43", "value": "Kanshu"},
  {"label": "44", "value": "Kishimono No Kushanku"},
  {"label": "45", "value": "Kihon Ich"},
  {"label": "46", "value": "Kihon Nee"},
  {"label": "47", "value": "Kihon San"},
  {"label": "48", "value": "Kihon Yan"},
  {"label": "49", "value": "Kousoukun"},
  {"label": "50", "value": "Kousoukun Dai"},
  {"label": "51", "value": "Kousoukun Sho"},
  {"label": "52", "value": "Kururunfa"},
  {"label": "53", "value": "Kusanku"},
  {"label": "54", "value": "Kyan No Chinto"},
  {"label": "55", "value": "Kyan No Wanshu"},
  {"label": "56", "value": "Matsukaze"},
  {"label": "57", "value": "Matsumura Bassai"},
  {"label": "58", "value": "Matsumura Rohai"},
  {"label": "59", "value": "Meikyo"},
  {"label": "60", "value": "Myojo"},
  {"label": "61", "value": "Naifanchin Shodan"},
  {"label": "62", "value": "Naifanchin Nidan"},
  {"label": "63", "value": "Naifanchin Sandan"},
  {"label": "64", "value": "Naihanchi"},
  {"label": "65", "value": "Nijushiho"},
  {"label": "66", "value": "Nipaipo"},
  {"label": "67", "value": "Niseishi"},
  {"label": "68", "value": "Ohan"},
  {"label": "69", "value": "Ohan Dai"},
  {"label": "70", "value": "Oyadomari No Passai"},
  {"label": "71", "value": "Pachu"},
  {"label": "72", "value": "Paiku"},
  {"label": "73", "value": "Papuren"},
  {"label": "74", "value": "Passai"},
  {"label": "75", "value": "Pinan Shodan"},
  {"label": "76", "value": "Pinan Nidan"},
  {"label": "77", "value": "Pinan Sandan"},
  {"label": "78", "value": "Pinan Yondan"},
  {"label": "79", "value": "Pinan Godan"},
  {"label": "80", "value": "Rohai"},
  {"label": "81", "value": "Saifa"},
  {"label": "82", "value": "Sanchin"},
  {"label": "83", "value": "Sansai"},
  {"label": "84", "value": "Sanseiru"},
  {"label": "85", "value": "Sanseru"},
  {"label": "86", "value": "Seichin"},
  {"label": "87", "value": "Seienchin (Seiyunchin)"},
  {"label": "88", "value": "Seipai"},
  {"label": "89", "value": "Seiryu"},
  {"label": "90", "value": "Seishan"},
  {"label": "91", "value": "Seisan (Sesan)"},
  {"label": "92", "value": "Shiho Kousoukun"},
  {"label": "93", "value": "Shinpa"},
  {"label": "94", "value": "Shinsei"},
  {"label": "95", "value": "Shisochin"},
  {"label": "96", "value": "Sochin"},
  {"label": "97", "value": "Suparinpei"},
  {"label": "98", "value": "Tekki Shodan"},
  {"label": "99", "value": "Tekki Nidan"},
  {"label": "100", "value": "Tekki Sandan"},
  {"label": "101", "value": "Tensho"},
  {"label": "102", "value": "Tomari Bassai"},
  {"label": "103", "value": "Unshu"},
  {"label": "104", "value": "Unsu"},
  {"label": "105", "value": "Useishi"},
  {"label": "106", "value": "Wankan"},
  {"label": "107", "value": "Wanshu"}
]
