import { EditOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Segmented,
  Select,
  Slider,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import event from "../../../../services/event";
import { notify } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";

const CategoryDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const [user, setUser] = useState({});
  const [tab, setTab] = useState("Category Info");
  const [allEvents, setAllEvents] = useState([]);
  const [eventName, setEventName] = useState("");
  const [minWeight, setMinWeight] = useState(null);
  const [maxWeight, setMaxWeight] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [minPoint, setMinPoint] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [team, setTeam] = useState(null);
  const [id, setId] = useState(null);
  const { categoryId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const type = location?.state?.type;

  useEffect(()=> {
    setSelectedType(type);
  },[type])

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Male + Female", value: "both" },
  ];

  const types = [
    { label: "Kata", value: "kata" },
    { label: "Kumite", value: "kumite" },
  ];

  const participationType = [
    { label: "Individual", value: false },
    { label: "Team", value: true },
  ];

  // getting individual category by id
  const getCategoryById = async (id) => {
    try {
      let res = await event.getCategoryById(id);
      setUser(res?.data);
    } catch (err) {}
  };

  useEffect(() => {
    getCategoryById(categoryId);
  }, [categoryId]);

  const options =
    user?.role === "student" ? ["Category Info", "Events"] : ["Category Info"];

  // updating catagory
  const updateCategory = async (values) => {
    let paylaod = {
      name: values?.name,
      description: values?.description,
      gender: values?.gender,
      age: { min: values?.minAge, max: values?.maxAge },
      weight: { min: values?.minWeight, max: values?.maxWeight },
      //   startDate: moment(values?.startDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      //   endDate: moment(values?.endDate).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      team: values?.team,
      teamSize: values?.teamSize,
      type: values?.type,
      event: id || user?.event,
      boutDuration: values?.boutDuration,
      pointLimit:{min: values?.minPoint, max: values?.maxPoint}

    };

    try {
      let res = await event.updateCategory(categoryId, paylaod);
      notify("success", "Category Updated Successfully.");
      setIsEditUser(false);
      getCategoryById(categoryId);
    } catch (err) {}
  };

  // Function to populate the form fields with user data
  const populateFormFields = () => {
    setTeam(user?.team);
    form.setFieldsValue({
      name: user?.name,
      lastName: user?.lastName,
      //   startDate: user?.startDate ? moment(user?.dateOfBirth) : null,
      //   endDate: user?.endDate ? moment(user?.endDate) : null,
      gender: user?.gender,
      description: user?.description,
      //   age: user?.age,
      team: user?.team,
      event: eventName,
      gender: user?.gender,
      type: user?.type,
      minAge: user?.age?.min,
      maxAge: user?.age?.max,
      minWeight: user?.weight?.min,
      maxWeight: user?.weight?.max,
      teamSize: user?.teamSize,
      boutDuration: user?.boutDuration,
      minPoint: user?.pointLimit?.min,
      maxPoint: user?.pointLimit?.max,
    });
  };

  // When edit mode is activated, populate the form fields
  useEffect(() => {
    if (isEditUser) {
      populateFormFields();
    }
  }, [isEditUser, user]);

  const getEventById = async (eventId) => {
    try {
      const res = await event.getEventById(eventId);
      setEventName(res?.data?.name);
    } catch (error) {}
  };

  useEffect(() => {
    // if(id)getEventById(id);
    getEventById(user?.event?._id);
  }, [user]);

  const getAllEvents = async () => {
    try {
      const res = await event.getAllEvent();
      // setAllEvents(res.data);
      setAllEvents(res?.data?.response?.records);
    } catch (err) {}
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  useEffect(() => {
    if (user?.name)
      dispatch(
        setBreadcrumb({
          title: "Category",
          subtitle: user?.name,
          url: "",
        })
      );
  }, [user]);

  const disableEndDate = (current) => {
    return (
      current &&
      current <= moment(startDate ? startDate : user?.startDate).endOf("day")
    );
  };

  const boutDurations = [30, 60, 90, 120, 150, 180];

  return (
    <div>
      <Card
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                {/* <Avatar
                  size={74}
                  shape="square"
                  src={
                    user?.profilePicture ? (
                      user?.profilePicture
                    ) : (
                      <div style={{ background: "gray" }}>
                        {user?.name
                          ?.split(/[\s-]/)
                          .filter((word) => isNaN(word))
                          .map((word) => word.charAt(0).toUpperCase() + " ")
                          .join("")}
                      </div>
                    )
                  }
                /> */}

                <div className="avatar-info" style={{ marginLeft: "10px" }}>
                  <h4 className="font-semibold m-0">{user?.name}</h4>
                  <p>{user?.role}</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Segmented
                options={options}
                onChange={(value) => {
                  setTab(value);
                }}
              />
            </Col>
          </Row>
        }
      >
        <>
          <Form form={form} onFinish={updateCategory}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div
                  className="w-100"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h6>Summary</h6>
                  <div className="px-3">
                    {!isEditUser ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsEditUser(!isEditUser)}
                      >
                        <EditOutlined color="var(--text-primary)" size={20} />
                      </div>
                    ) : (
                      <div>
                        <Button
                          style={{
                            marginRight: "10px",
                          }}
                          type="primary"
                          onClick={() => setIsEditUser(!isEditUser)}
                          className="font-bold cursor-pointer mx-4"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="font-bold cursor-pointer mx-4"
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24} className="m-2"></Col>

              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">Name</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "name is required!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.name}</div>
                )}
              </Col>

              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">Event</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    name="event"
                    rules={[
                      {
                        required: true,
                        message: "event is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select an event"
                      value={id}
                      onChange={(e) => setId(e)}
                    >
                      {allEvents.map((event) => (
                        <Select.Option key={event._id} value={event._id}>
                          {event?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{eventName}</div>
                )}
              </Col>

              {isEditUser && form.getFieldValue("type") === "kumite" && (
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    Bout Duration (seconds)
                  </h6>
                  <Form.Item
                    name="boutDuration"
                    rules={[
                      {
                        required: true,
                        message: "Bout duration is required!",
                      },
                    ]}
                  >
                    <Select placeholder="Select bout duration">
                      {boutDurations.map((duration) => (
                        <Select.Option key={duration} value={duration}>
                          {duration} sec
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              {selectedType === "kumite" && !isEditUser && (
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    Bout Duration
                  </h6>
                  <div className="font-semibold">{user?.boutDuration} sec</div>
                </Col>
              )}

              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">Gender</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    name="gender"
                    rules={[
                      {
                        required: true,
                        message: "gender is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select gender"
                      // value={role}
                      onChange={(e) => {}}
                    >
                      {genders.map((gender) => (
                        <Select.Option key={gender.value} value={gender.value}>
                          {gender.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.gender}</div>
                )}
              </Col>

              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">Type</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ marginBottom: 10 }}
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "type is required!",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select type"
                      // value={role}
                      onChange={(e) => { setSelectedType(e)}}
                    >
                      {types.map((type) => (
                        <Select.Option key={type.value} value={type.value}>
                          {type.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.type}</div>
                )}
              </Col>

              {/* <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">
                  Start Date
                </h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ margin: 0 }}
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Start date is required!",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(date, dateString) => {
                        setStartDate(date);
                        //   const formattedDate = moment(date).format("DD/MM/YYYY");
                      }}
                      disabledDate={(current) =>
                        current && current < moment().startOf("day")
                      }
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.startDate}</div>
                )}
              </Col>

              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">End Date</h6>
                {isEditUser ? (
                  <Form.Item
                    style={{ margin: 0 }}
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "End date is required!",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD/MM/YYYY"
                      onChange={(date, dateString) => {
                        //   const formattedDate = moment(date).format("DD/MM/YYYY");
                      }}
                      disabledDate={disableEndDate}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                ) : (
                  <div className="font-semibold">{user?.endDate}</div>
                )}
              </Col> */}
            {selectedType === "kata" &&
              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">
                  Points Limit
                </h6>
                {isEditUser ? (
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="minPoint"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter minimum points can be given to a player.",
                          },
                          //   {
                          //     validator: validateMinWeight,
                          //   },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={25}
                          //   step={0.01}
                          placeholder="Minimum points"
                          style={{ width: "100%" }}
                          //   value={minWeight}
                          onChange={(value) => setMinPoint(value)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "32px",
                      }}
                    >
                      -
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="maxPoint"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please enter maximum points can be given to a player",
                          },
                          //   {
                          //     validator: validateMaxWeight,
                          //   },
                        ]}
                      >
                        <InputNumber
                          min={minPoint}
                          max={25}
                          //   step={0.01}
                          placeholder="Maximum point"
                          style={{ width: "100%" }}
                          //   value={maxWeight}
                          onChange={(value) => setMaxWeight(value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <div className="font-semibold">
                    {user?.pointLimit?.min} to {user?.pointLimit?.max} 
                  </div>
                )}
              </Col>
            }
              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">
                  Age Limit (in years)
                </h6>
                {isEditUser ? (
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="minAge"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a minimum age!",
                          },
                          //   {
                          //     validator: validateMinWeight,
                          //   },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={40}
                          //   step={0.01}
                          placeholder="Minimum age"
                          style={{ width: "100%" }}
                          //   value={minWeight}
                          onChange={(value) => setMinWeight(value)}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={2}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "32px",
                      }}
                    >
                      -
                    </Col>

                    <Col span={11}>
                      <Form.Item
                        name="maxAge"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a maximum age!",
                          },
                          //   {
                          //     validator: validateMaxWeight,
                          //   },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={40}
                          //   step={0.01}
                          placeholder="Maximum age"
                          style={{ width: "100%" }}
                          //   value={maxWeight}
                          onChange={(value) => setMaxWeight(value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <div className="font-semibold">
                    {user?.age?.min} to {user?.age?.max} years
                  </div>
                )}
              </Col>
              {user?.weight?.min && user?.weight?.max && (
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    Weight Limit (in kg)
                  </h6>
                  {isEditUser ? (
                    <Row>
                      <Col span={11}>
                        <Form.Item
                          name="minWeight"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a minimum weight!",
                            },
                            //   {
                            //     validator: validateMinWeight,
                            //   },
                          ]}
                        >
                          <InputNumber
                            min={8}
                            max={180}
                            //   step={0.01}
                            placeholder="Minimum age"
                            style={{ width: "100%" }}
                            value={minWeight}
                            onChange={(value) => setMinWeight(value)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "32px",
                        }}
                      >
                        -
                      </Col>

                      <Col span={11}>
                        <Form.Item
                          name="maxWeight"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a maximum weight!",
                            },
                            //   {
                            //     validator: validateMaxWeight,
                            //   },
                          ]}
                        >
                          <InputNumber
                            min={minWeight + 5}
                            max={185}
                            //   step={0.01}
                            placeholder="Maximum age"
                            style={{ width: "100%" }}
                            value={maxWeight}
                            onChange={(value) => setMaxWeight(value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : (
                    <div className="font-semibold">
                      {user?.weight?.min} to {user?.weight?.max} kg
                    </div>
                  )}
                </Col>
              )}

              <Col span={6}>
                <h6 className="list-header text-m text-muted m-0">Team</h6>
                {isEditUser ? (
                  <Form.Item name="team">
                    {/* <Checkbox></Checkbox> */}
                    <Select
                      placeholder="Select type"
                      // value={role}
                      onChange={(e) => setTeam(e)}
                    >
                      {participationType.map((type) => (
                        <Select.Option key={type.label} value={type.value}>
                          {type.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <div className="font-semibold">
                    {user?.team ? "Team" : "Individual"}
                  </div>
                )}
              </Col>

              {team && (
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    Team size
                  </h6>
                  {isEditUser ? (
                    <Form.Item
                      name="teamSize"
                      rules={[
                        {
                          required: true,
                          message: "Please enter max team size!",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        max={3}
                        //   step={0.01}
                        placeholder="Max team size"
                        style={{ width: "100%" }}
                        //   value={maxWeight}
                        //   onChange={(value) => setMaxWeight(value)}
                      />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">
                      {user?.team ? "Team" : "Individual"}
                    </div>
                  )}
                </Col>
              )}

              {user?.description && (
                <Col span={6}>
                  <h6 className="list-header text-m text-muted m-0">
                    Description
                  </h6>
                  {isEditUser ? (
                    <Form.Item
                      style={{ margin: 0 }}
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "description is required!",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Enter description"
                        rows={4}
                      />
                    </Form.Item>
                  ) : (
                    <div className="font-semibold">{user?.description}</div>
                  )}
                </Col>
              )}

              {/* <Col span={24} className="m-3">
                {user?.teamSize}
              </Col> */}

              <Col span={24} className="m-3 text-center">
                {!isEditUser && (
                  <>
                    <Divider />

                    <Button
                      type="primary"
                      className="font-bold cursor-pointer mx-4 p-0"
                      onClick={() => history.goBack()}
                    >
                      Back
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </>
      </Card>
    </div>
  );
};

export default CategoryDetails;
