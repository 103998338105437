import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Image,
  Tag,
  InputNumber,
  Form,
  Select,
  Button,
  Table,
  Avatar,
  Progress,
  Tabs,
  Segmented,
} from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import moment from "moment";
import event from "../../../../services/event";
import AddEntriesModal from "../AddEntriesModal";

const EventOverview = ({ eventData }) => {
  const [bannerImage, setBannerImage] = useState("");
  const [openEntriesModal, setOpenEntriesModal] = useState(false);

  const handleDownload = async (id, fileName) => {
    try {
      const res = await event.downloadBanner(id, { fileName: fileName });
      setBannerImage(res?.data);
    } catch (err) {}
  };

  useEffect(() => {
    if (eventData) {
      handleDownload(eventData?._id, eventData?.bannerImage);
    }
  }, [eventData]);

  const decodeHtml = (html) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    return textArea.value;
  };

  const extractTextFromHtml = (htmlString) => {
    const decodedHtml = decodeHtml(htmlString);

    const parser = new DOMParser();
    const doc = parser.parseFromString(decodedHtml, "text/html");
    return doc.querySelector("p")?.innerText || "";
  };

  return (
    <Row>
      {openEntriesModal && (
        <AddEntriesModal
          open={openEntriesModal}
          setOpen={setOpenEntriesModal}
          selectedEvent={[eventData?._id]}
        />
      )}
      <Col span={24}>
        <Card
          title={[<h6 className="mb-0">Event Details</h6>]}
          bordered={false}
          className="header-solid card-product mb-24"
        >
          <Row gutter={[24, 24]}>
            <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
              <img
                src={bannerImage}
                className="product-img shadow-3 img-contain"
                alt="Event Banner"
              />
            </Col>

            <Col span={24} xl={12} lg={{ offset: 2 }}>
              <h3 className="h3">{eventData?.name}</h3>

              <h6 className="mb-0 mt-20 font-semibold">Duration</h6>
              <h5 className="font-semibold">
                {moment(eventData?.startDate).format("DD MMM YYYY")} -{" "}
                {moment(eventData?.endDate).format("DD MMM YYYY")}
              </h5>

              <Tag color="green" className="font-bold">
                {eventData?.registrationStatus}
              </Tag>

              <h6 className="mt-20 font-semibold">
                <small>Description:</small>
              </h6>
              <p>{extractTextFromHtml(eventData?.description)}</p>

              <h6 className="mt-20 font-semibold">
                <small>Organizer:</small>
              </h6>
              <p>{eventData?.organizerName}</p>

              <h6 className="mt-20 font-semibold">
                <small>Location:</small>
              </h6>
              <p>{`${eventData?.address}, ${eventData?.city}, ${eventData?.district}, ${eventData?.state} - ${eventData?.pinCode}`}</p>

              <h6 className="mt-20 font-semibold">
                <small>Contact Info:</small>
              </h6>
              <p>Name: {eventData?.contact?.name}</p>
              <p>Phone: {eventData?.contact?.phone}</p>

              <h6 className="mt-20 font-semibold">
                <small>Social Links:</small>
              </h6>
              <p>
                Website:{" "}
                <a
                  href={`https://${eventData?.social?.website}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {eventData?.social?.website}
                </a>
              </p>
              <p>
                Facebook:{" "}
                <a
                  href={eventData?.social?.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {eventData?.social?.facebook}
                </a>
              </p>
              <p>
                Twitter:{" "}
                <a
                  href={`https://twitter.com/${eventData?.social?.twitter.substring(
                    1
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {eventData?.social?.twitter}
                </a>
              </p>
            </Col>

            <Col span={24} lg={24} className="d-flex justify-content-center align-items-center">
              <button
                className="button-29"
                onClick={() => setOpenEntriesModal(true)}
              >
                PARTICIPATE NOW
              </button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default EventOverview;
