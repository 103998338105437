import { MailOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { notify } from "../../utils/helper";
import {useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import auth from "../../services/auth";

const InputWrapper = styled(Col)`
  .ant-input-affix-wrapper {
    padding: 1px 11px;
    border-radius: 6px;
  }
`;

const PasswordRecovery = () => {
  const [enable, setEnable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const query = useParams();
  const history = useHistory();

//   const { executeRecaptcha } = useGoogleReCaptcha();

  const onFinish = async (values) => {
    // const token = await handleSubmit();
    // if (token) {
      await forgotPassword({ email: values?.email });
    // } else {
    // }
  };

  const onFinishFailed = (errorInfo) => {};

  const handleSubmit = async () => {
    try {
        // const newToken = await executeRecaptcha("submit");
        // return newToken;
    } catch (err) {
      return null;
    }
  };

  const forgotPassword = async (payload) => {
    try {
      setIsLoading(true);
        const res = await auth.forgotPassword(payload);
      setIsLoading(false);
        if (res?.status === 200) {
          notify(
            "success",
            "If you are registered in our system, You shall receive email with further instructions to reset your password."
          );
        }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Row
      style={{
 
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col xl={8} lg={8} md={12} sm={12} className="p-4">
        <div className="text-center my-3" style={{ marginBottom: "30px" }}>
          <img
            src="https://mpkarateassociation.com/wp-content/uploads/2017/06/mpka-new-logo.png"
            style={{ width: "200px" }}
            alt="logo"
          />
        </div>
        {/* {!enable && ( */}
        <div className="text-center" style={{ marginBottom: "30px" }}>
          <h5 className="m-0">Password Recovery</h5>
          <h4 className="text-center">
            Tell us your email so we can send you a reset link
          </h4>
        </div>
        {/* )} */}
        <InputWrapper className="mt-3 mb-10">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid email address!",
                  },
                ]}
              >
                <Input
                  placeholder="Email"
                  suffix={
                    <MailOutlined size={23} color="var(--text-primary)" />
                  }
                />
              </Form.Item>
              {/* <GoogleReCaptcha onVerify={(t) => console.log("")} /> */}
            </>

            <div className="d-flex justify-content-end">
              <div
                role="button"
                onClick={() => history.push("/login")}
                className="pointer"
                style={{ color: "var(--individual-button)", cursor: "pointer" }}
              >
                Back to Log In
              </div>
            </div>

            <Form.Item>
              <Button
                type="primary"
                className="mt-1"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Forgot Password
              </Button>
            </Form.Item>
          </Form>
        </InputWrapper>
      </Col>
    </Row>
  );
};

export default PasswordRecovery;
