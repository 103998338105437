import {
  EyeInvisibleOutlined,
  EyeOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { checkStrongPassword, notify } from "../../utils/helper";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import auth from "../../services/auth";
import parseJwt from "../../utils/parseJwt";

const InputWrapper = styled(Col)`
  .ant-input-affix-wrapper {
    padding: 1px 11px;
    border-radius: 6px;
  }
`;
const ResetPassword = () => {
  const [enable, setEnable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const query = useParams();
  const history = useHistory();


  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const res = await auth.resetPassword({
        password: values?.password,
        token: query?.token,
      });
      setIsLoading(false);
      if (res.status === 200) {
        notify("success", "Password Reset Successfully!");
        history.push("/login");
      }
    } catch (err) {
      setIsLoading(false);
      notify("error", "Password Reset Link Expired!");
    }
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if (query?.token) {
      const jwtData = parseJwt(query?.token);
      if (!jwtData?.email) {
        history.push("/");
      }
      setData(jwtData);
    } else {
      history.push("/");
    }
  }, [query?.token]);

  const passwordRules = [
    {
      required: true,
      message: "Please input your password!",
    },
    {
      min: 8,
      message: "Password must be at least 8 characters long.",
    },
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])/,
      message:
        "Password must contain at least 1 lowercase letter, 1 uppercase letter, and 1 special character.",
    },
  ];

  return (
    <Row
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Col xl={8} lg={8} md={12} sm={12} className="p-4">
        <div className="text-center my-3" style={{ marginBottom: "30px" }}>
          <img
            src="https://mpkarateassociation.com/wp-content/uploads/2017/06/mpka-new-logo.png"
            style={{ width: "200px" }}
            alt="logo"
          />
        </div>
        {/* {!enable && ( */}
        <div className="text-center" style={{ marginBottom: "30px" }}>
          <h5 className="m-0">Reset Password</h5>
          <h4 className="text-center">Please customize your new password</h4>
        </div>
        {/* )} */}
        <InputWrapper className="mt-3 mb-10">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            // className="row-col"
          >
            <>
              {" "}
              <Form.Item
                className="username"
                name="password"
                rules={passwordRules}
              >
                <Col>
                  <Input.Password
                    type="password"
                    className="inputPassword"
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined
                          style={{
                            fontSize: "150%",
                            marginRight: "3px",
                            color: "var(--text-primary)",
                          }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{
                            fontSize: "150%",
                            marginRight: "3px",
                            color: "var(--text-primary)",
                          }}
                        />
                      )
                    }
                  />
                </Col>
              </Form.Item>
              <Form.Item
                className="username"
                name="password1"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Col>
                  <Input.Password
                    placeholder="Confirm Password"
                    className="inputPassword"
                    // style={{ backgroundColor: "#f3f3f3" }}
                    iconRender={(visible) =>
                      visible ? (
                        <EyeOutlined
                          style={{
                            fontSize: "150%",
                            marginRight: "3px",
                            color: "var(--text-primary)",
                          }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          style={{
                            fontSize: "150%",
                            marginRight: "3px",
                            color: "var(--text-primary)",
                          }}
                        />
                      )
                    }
                  />
                </Col>
              </Form.Item>
            </>
            {/* )} */}

            <Form.Item>
              <Button
                type="primary"
                className="mt-1"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </InputWrapper>
      </Col>
    </Row>
  );
};

export default ResetPassword;
