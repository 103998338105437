import React, { useEffect, useState } from "react";
import { Row, Col, Card, Tag, Form, Button, Table, Divider } from "antd";
import { useParams, useHistory } from "react-router";
import event from "../../../services/event";
import { GiBlackBelt } from "react-icons/gi";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../utils/helper";
const ActiveEventDetails = () => {
  const query = useParams();
  const [eventData, setEventData] = useState([]);
  const getEventById = async () => {
    try {
      const res = await event.getEventById(query?.EventId);
      setEventData(res?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getEventById();
  }, []);

  const [bannerImage, setBannerImage] = useState("");

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    if (isNaN(birthDate.getTime())) {
      return "Invalid Date";
    }

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();

    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      years--;
      months += 12;
    }

    if (today.getDate() < birthDate.getDate()) {
      months--;
      if (months < 0) {
        months = 11;
        years--;
      }
    }

    return `${years} years ${months} months`;
  };

  const columns = [
    {
      title: "Participant Name",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 3,
      },
    },
    {
      title: "Age",
      className: "ant-table-row-cell-break-word",
      dataIndex: "age",
      align: "center",
      sorter: {
        compare: (a, b) => a.age - b.age,
        multiple: 2,
      },
    },
    {
      title: "Gender",
      className: "ant-table-row-cell-break-word",
      dataIndex: "gender",
      align: "center",
    },
    {
      title: "Institute",
      className: "ant-table-row-cell-break-word",
      dataIndex: "institute",
      align: "center",
    },
    {
      title: "Belt Level",
      dataIndex: "belt",
      align: "center",
      className: "ant-table-row-cell-break-word",
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      className: "ant-table-row-cell-break-word",
    },
  ];

  let data = [];
  for (let item of eventData?.totalParticipant || []) {
    const beltColor = (() => {
      switch (item?.belt) {
        case "Black":
          return "black";
        case "Brown":
          return "brown";
        case "Blue":
          return "blue";
        case "Green":
          return "green";
        case "Yellow":
          return "yellow";
        case "Orange":
          return "orange";
        case "Red":
          return "red";
        default:
          return "gray";
      }
    })();

    data.push({
      key: item?.key,
      name: item?.firstName + " " + item?.lastName,
      age: calculateAge(item?.dateOfBirth),
      gender: item?.gender,
      coach:
        item?.institute?.coach?.firstName +
        " " +
        item?.institute?.coach?.lastName,
      institute: item?.instituteId?.name,
      belt: <GiBlackBelt color={beltColor} size={25} />,
      category: item?.category,
    });
  }

    const handleDownload = async (id, fileName) => {
      try {
        const res = await event.downloadBanner(id, { fileName: fileName });
        // window.open(res?.data, "_blank");
        setBannerImage(res?.data);
      } catch (err) {}
    };

    useEffect(() => {
      if (eventData) {
        handleDownload(eventData?._id, eventData?.bannerImage);
      }
    }, [eventData]);

  return (
    <Row>
    <Col span={24}>
      <Card
        title={[<h6 className="mb-0">Event Details</h6>]}
        bordered={false}
        className="header-solid card-product mb-24"
      >
        <Row gutter={[24, 0]}>
          <Col span={24} lg={12} xl={10} className="col-gallery mb-24">
            <img
              src={bannerImage}
              className="product-img shadow-3 img-contain"
            />
            {/* <div className="thumbnails mt-30">
              <Image.PreviewGroup>
                <Image src="https://images.unsplash.com/photo-1541836567455-2d41eb6dd9b4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8a2FyYXRlJTIwZXZlbnR8ZW58MHx8MHx8fDA%3D" />
                <Image src="https://plus.unsplash.com/premium_photo-1664304795352-638f3fc037d7?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGthcmF0ZSUyMGV2ZW50fGVufDB8fDB8fHww" />
                <Image src="https://images.unsplash.com/photo-1697086279220-bcaea17a8734?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8a2FyYXRlJTIwJTIwaW5kaWF8ZW58MHx8MHx8fDA%3D" />
                <Image src="https://images.unsplash.com/photo-1542937307-6eeb0267cbab?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8a2FyYXRlfGVufDB8fDB8fHww" />
              </Image.PreviewGroup>
            </div> */}
          </Col>
          <Col span={24} xl={12} lg={{ offset: 2 }}>
            <h3 className="h3">{eventData?.name}</h3>
            <div className="rating">
              <StarFilled />
              <StarFilled />
              <StarFilled />
              <StarFilled />
              <StarOutlined />
            </div>
            <h6 className="mb-0 mt-20 font-semibold">Event Date</h6>
            <h5 className="font-semibold">
              {moment(eventData?.startDate).format("MMM DD YYYY")}
            </h5>
            <Tag color="green" className="font-bold">
              Scheduled
            </Tag>
            <h6 className="mt-20 font-semibold">
              <small>Description:</small>
            </h6>
            <ul>
              <li>
                <p className="mb-0 text-lg text-muted">
                  The most prestigious karate championship of the year.
                </p>
              </li>
              <li>
                <p className="mb-0 text-lg text-muted">
                  Open to all age categories and belt levels.
                </p>
              </li>
              <li>
                <p className="mb-0 text-lg text-muted">
                  Witness and compete with the best karatekas.
                </p>
              </li>
              <li>
                <p className="mb-0 text-lg text-muted">
                  Winners receive certification and medals.
                </p>
              </li>
            </ul>
            <Form layout="vertical">
              <Row gutter={[24, 0]}>
                {/* <Col span={12} lg={8}>
                    <Form.Item
                      label="Age Category"
                      colon={false}
                      className="mb-10"
                    >
                      <Select
                        defaultValue="Senior"
                        onChange={handleChange}
                        size="large"
                      >
                        <Option value="Junior">Junior</Option>
                        <Option value="Senior">Senior</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} lg={8}>
                    <Form.Item
                      label="Belt Level"
                      colon={false}
                      className="mb-10"
                    >
                      <Select
                        defaultValue="Black"
                        onChange={handleChange}
                        size="large"
                      >
                        <Option value="White">White</Option>
                        <Option value="Yellow">Yellow</Option>
                        <Option value="Green">Green</Option>
                        <Option value="Blue">Blue</Option>
                        <Option value="Brown">Brown</Option>
                        <Option value="Black">Black</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={8}>
                    <Form.Item
                      label="Number of Participants"
                      colon={false}
                      size="large"
                      className="mb-10"
                    >
                      <InputNumber
                        min={1}
                        max={100}
                        defaultValue={1}
                        onChange={onChange}
                      />
                    </Form.Item>
                  </Col> */}
                <Col span={24} lg={8}>
                  <Button type="primary" block>
                    Participant Now
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {/* <Row gutter={[24, 0]} className="mt-24">
          <Col span={24}>
            <h6>Participants List</h6>
            <Table
              tableLayout="auto"
              scroll={{ x: 500 }}
              columns={columns}
              dataSource={data}
              bordered={false}
              pagination={false}
            />
          </Col>
        </Row> */}
      </Card>
    </Col>
  </Row>
  );
};

export default ActiveEventDetails;
