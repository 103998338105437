import { apiDelete, apiGet, apiPost, apiPut } from "../utils/axios";
import { domainUrl } from "../utils/domainUrl";

export default {
    getTeamList: ()=> apiGet(domainUrl(`/api/v1/team/list`)),
    getTeamById: (id)=> apiGet(domainUrl(`/api/v1/team/${id}`)),


    createTeam: (payload)=> apiPost(domainUrl(`/api/v1/team/create`), payload),


    updateTeam: (id, payload)=> apiPut(domainUrl(`/api/v1/team/update/${id}`), payload),

    
    deleteTeam: (payload)=> apiDelete(domainUrl(`/api/v1/team/delete`), payload),

}