export const path = () => ({
  USER_DETAILS_STATE: (id) => `/users/state/state-users/${id}/details`,
  CREATE_STATE_HEADS: () => `/users/state/create-state-head`,
  STUDENTS_INSTITUTE_DETAILS: (id) => `/students/institute/${id}/details`,
  JUDGE_DETAILS:(id)=>`/users/moderator/judge/${id}/details`,
  REFEREE_DETAILS:(id)=>`/users/moderator/referee/${id}/details`,

  KUMITI_SCORE_PANEL: (catId, id)=> `/pages/events/all-events/category/${catId}/kumiti-match/${id}`,
  KUMITI_SCORE_PANEL_DRAWER: (catId)=> `/pages/events/all-events/category/${catId}/kumiti-match-panel/score-panel`,
  KATA_SCORE_PANEL_DRAWER: (catId)=> `/pages/events/all-events/category/${catId}/matches/kata-score-panel`,

  JUDGE_DETAILS:(id)=>`/users/moderator/judge-overview/judge/${id}/details`,
  REFEREE_DETAILS:(id)=>`/users/moderator/referee-overview/referee/${id}/details`,
});
