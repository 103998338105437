import { SET_BREADCRUMB } from "../actionTypes/breadcrumb";

const initialState = {
  breadcrumbItems: {
    title: "",
    subtitle: "",
    url: "",
  },
};

const breadcrumbReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREADCRUMB:
      return {
        ...state,
        breadcrumbItems: action.payload,
      };
    default:
      return state;
  }
};

export default breadcrumbReducer;
