import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import BgProfile from "../../images/karate_picture_02.jpg";
import { Row, Col, Card, Avatar, Radio } from "antd";
import profilavatar from "../../images/face-1.jpg";
import convesionImg from "../../images/face-3.jpg";
import convesionImg2 from "../../images/face-4.jpg";
import convesionImg3 from "../../images/face-5.jpeg";

import logo1 from "../../images/logo-slack.svg";
import ava1 from "../../images/logo-spotify.svg";
import ava2 from "../../images/logo-xd.svg";
import ava3 from "../../images/logo-asana.svg";
import ava4 from "../../images/logo-invision.svg";
import team from "../../images/team-3.jpg";

import { MoreOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import event from "../../../services/event";

const UpcomingEvents = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  function onChange(checked) {
  }

  const getEventByStatus = async (status) => {
    try {
        const res = await event.getEventByStatus(status)
        console.log("res:", res?.data)
    } catch (error) {
      
    }
  }

  const card = [
    {
      headerImg: "eventImage1.jpg", // Replace with actual image paths
      title: "National Karate Championship",
      Descriptions:
        "Join the most prestigious karate event of the year and compete with the best!",
      footer: (
        <Row gutter={[24]}>
          <Col>
            <h6 className="font-semibold text-md mb-0">100</h6>
            <p className="font-semibold text-muted mb-0">Participants</p>
          </Col>
          <Col className="ml-auto">
            <h6 className="font-semibold text-md mb-0">05.02.24</h6>
            <p className="font-semibold text-muted mb-0">Event Date</p>
          </Col>
        </Row>
      ),
    },
    {
      headerImg: "eventImage2.jpg", // Replace with actual image paths
      title: "Interstate Karate Meet",
      Descriptions:
        "A fantastic opportunity for all karatekas to showcase their skills.",
      footer: (
        <Row gutter={[24]}>
          <Col>
            <h6 className="font-semibold text-md mb-0">75</h6>
            <p className="font-semibold text-muted mb-0">Participants</p>
          </Col>
          <Col className="ml-auto">
            <h6 className="font-semibold text-md mb-0">10.03.24</h6>
            <p className="font-semibold text-muted mb-0">Event Date</p>
          </Col>
        </Row>
      ),
    },
    {
      headerImg: "eventImage3.jpg", // Replace with actual image paths
      title: "Junior Karate Cup",
      Descriptions:
        "A special tournament for the young karate enthusiasts to shine.",
      footer: (
        <Row gutter={[24]}>
          <Col>
            <h6 className="font-semibold text-md mb-0">150</h6>
            <p className="font-semibold text-muted mb-0">Participants</p>
          </Col>
          <Col className="ml-auto">
            <h6 className="font-semibold text-md mb-0">20.03.24</h6>
            <p className="font-semibold text-muted mb-0">Event Date</p>
          </Col>
        </Row>
      ),
    },
    {
      headerImg: "eventImage4.jpg", // Replace with actual image paths
      title: "Karate Invitational",
      Descriptions:
        "An invite-only event featuring top karatekas from around the world.",
      footer: (
        <Row gutter={[24]}>
          <Col>
            <h6 className="font-semibold text-md mb-0">120</h6>
            <p className="font-semibold text-muted mb-0">Participants</p>
          </Col>
          <Col className="ml-auto">
            <h6 className="font-semibold text-md mb-0">15.04.24</h6>
            <p className="font-semibold text-muted mb-0">Event Date</p>
          </Col>
        </Row>
      ),
    },
    {
      headerImg: "eventImage5.jpg", // Replace with actual image paths
      title: "Open Karate Tournament",
      Descriptions:
        "The ultimate open tournament where all karate enthusiasts are welcome.",
      footer: (
        <Row gutter={[24]}>
          <Col>
            <h6 className="font-semibold text-md mb-0">140</h6>
            <p className="font-semibold text-muted mb-0">Participants</p>
          </Col>
          <Col className="ml-auto">
            <h6 className="font-semibold text-md mb-0">05.05.24</h6>
            <p className="font-semibold text-muted mb-0">Event Date</p>
          </Col>
        </Row>
      ),
    },
  ];

  useEffect(()=> {
    dispatch(
      setBreadcrumb({
        title: "Events",
        subtitle: "Upcoming Events",
        url: "",
      })
    );
    getEventByStatus("draft")
  }, [])


  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={team} />{" "}
                {/* Replace with actual image path */}
                <div className="avatar-info">
                  <h4 className="font-semibold m-0">Ravi Kumar</h4>
                  <p>Head Coach / Organizer</p>
                </div>
              </Avatar.Group>
            </Col>
            <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group onChange={onChange} defaultValue="a">
                <Radio.Button value="a">Events</Radio.Button>
                <Radio.Button value="b">Training</Radio.Button>
                <Radio.Button value="c">Achievements</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        }
      ></Card>
      <Row>
        <Col span={25} lg={16}>
          <h5 className="font-semibold">Upcoming Karate Events in India</h5>
          <p className="text-lg">
            Explore the plethora of karate events happening across India. Stay
            informed and participate in events to hone your skills and connect
            with the karate community.
          </p>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        {card.map((card, index) => (
          <Col span={24} lg={8} className="mb-24" key={index}>
            <Card
              bodyStyle={{ paddingTop: "14px" }}
              bordered={false}
              style={{cursor:"pointer"}}
              className="card-project-2 header-solid"
              onClick={() => history.push(`/pages/events/all-events/${index}`)}
              title={[
                <Row gutter={[24, 24]} key={0}>
                  <Col>
                    <Avatar
                      src={card.headerImg}
                      style={{
                        width: "54px",
                        height: "54px",
                        lineHeight: "54px",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundImage:
                          "linear-gradient(310deg, rgb(20, 23, 39), rgb(58, 65, 111))",
                      }}
                    />
                  </Col>
                  <Col>
                    <h6 className="font-semibold mb-10">{card.title}</h6>
                    <Avatar.Group className="avatar-chips">
                      <Avatar size="small" src={profilavatar} />
                      <Avatar size="small" src={convesionImg} />
                      <Avatar size="small" src={convesionImg2} />
                      <Avatar size="small" src={convesionImg3} />
                    </Avatar.Group>
                  </Col>
                  <Col className="ml-auto pt-10">
                    <a href="#">
                      <MoreOutlined className="text-muted" />
                    </a>
                  </Col>
                </Row>,
              ]}
            >
              <p>{card.Descriptions}</p>
              <hr />
              {card.footer}
            </Card>
          </Col>
        ))}

        {/* <Col span={24} lg={8} className="mb-24">
          <Card
            className="crm-bar-line header-solid h-full"
            bodyStyle={{
              padding: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <a href="#" className="text-center text-muted font-bold">
              <h3
                className="font-semibold text-muted mb-0"
                style={{ fontSize: "30px" }}
              >
                +
              </h3>
              <h5 className="font-semibold text-muted">New project</h5>
            </a>
          </Card>
        </Col> */}
      </Row>
    </>
  );
};

export default UpcomingEvents;
