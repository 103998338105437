import { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import useAuth from '../hooks/useAuth';
// import Login from '../pages/authentication/Login';
// import Role from '../contexts/Role';
import { extractToken, isLogin } from './localStorage';
import Login from '../component/pages/Login';

const AuthGuard = (props) => {
  const { children, roles } = props;


//   const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

let user = extractToken();



  if (!isLogin()) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Redirect to={requestedLocation} />;
  }

  if (roles && roles.indexOf(user?.role) === -1) {
    // role not authorised so redirect to home page
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }
  return <>{children}</>;
};



export default AuthGuard;
