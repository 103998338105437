import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Segmented, Table } from "antd";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import fixtures from "../../../../services/fixtures";
import { useEdges } from "reactflow";
import { CustomSegmented } from "../Styled";

const EventResult = ({ eventData }) => {
  const [apiData, setApiData] = useState([]);
  const [selectedSection, setSelectedSection] = useState("All");

  let componentRef = useRef(null);

  // console.log("apiData", apiData);

  const medalsOrder = ["Gold", "Silver", "Bronze", "Bronze"];
  let certificateCounter = 1;

  let data = [];
  for (let item of apiData||[]) {
    const { rank = {}, _id, category, institue } = item;

    const medals = Object.keys(rank)
      .slice(0, medalsOrder.length)
      .map((rankKey, index) => {
        const player = rank[rankKey];
      
        return {
          medal: medalsOrder[index],
          player,
          certificate: `CERT${certificateCounter++}`,
          club: institue[rankKey]?.name,
        };
      });
      if(institue?.first && institue?.second && institue?.third && institue?.fourth)
    data.push({
      key: _id,
      category: `${category?.type}-${category?.name} ${
        category?.gender === "both"
          ? "- ( Boys and Girls )"
          : "male"
          ? "- ( Boys )"
          : "- ( Girls )"
      }`,
      gender:category?.gender,
      medals,
    });
  }

  // console.log("data1: ", data);

  // const data = [
  //   {
  //     key: "1",
  //     category: "Kata - Boys Under 12",
  //     medals: [
  //       {
  //         medal: "Gold",
  //         player: "John Doe",
  //         certificate: "CERT123",
  //         club: "ABC Karate Club",
  //       },
  //       {
  //         medal: "Silver",
  //         player: "Mark Smith",
  //         certificate: "CERT124",
  //         club: "XYZ Martial Arts",
  //       },
  //       {
  //         medal: "Bronze",
  //         player: "Luke Johnson",
  //         certificate: "CERT125",
  //         club: "MNO Academy",
  //       },
  //     ],
  //   },
  //   {
  //     key: "2",
  //     category: "Kumite - Girls Under 14",
  //     medals: [
  //       {
  //         medal: "Gold",
  //         player: "Jane Roe",
  //         certificate: "CERT126",
  //         club: "PQR Karate Club",
  //       },
  //       {
  //         medal: "Silver",
  //         player: "Maria Gonzales",
  //         certificate: "CERT127",
  //         club: "STU Martial Arts",
  //       },
  //       {
  //         medal: "Bronze",
  //         player: "Emma Wilson",
  //         certificate: "CERT128",
  //         club: "DEF Academy",
  //       },
  //     ],
  //   },
  // ];

  const getEventResult = async (eventId) => {
    try {
      const response = await fixtures.getEventResult(eventId);
      setApiData(response?.data);
    } catch (err) {
      // console.error(err);
    }
  };

  useEffect(() => {
    if(eventData?._id)
    getEventResult(eventData?._id);
  }, [eventData?._id]);

  const generateResult = async (evenId) => {
    try {
      const res = await fixtures?.generateEventResult(evenId);
      getEventResult(evenId);
    } catch (err) {}
  };

  const expandedData = data
    .map((entry) =>
      entry.medals.map((medalEntry, index) => ({
        key: `${entry.key}-${index}`,
        category: entry.category,
        ...medalEntry,
        categoryRowSpan: index === 0 ? entry.medals.length : 0,
        gender: entry.gender
      }))
    )
    .flat();

  

    const filteredData = expandedData.filter((entry) => {
      if (selectedSection === "All" ) {
        return true; 
      }
      if (selectedSection === "Boys") {
        return entry.gender==="male";
      }
      if (selectedSection === "Girls") {
        return entry.gender==="female"; 
      }
      return false; 
    });

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (value, row) => ({
        children: value,
        props: {
          rowSpan: row.categoryRowSpan,
        },
      }),
    },
    {
      title: "Medal",
      dataIndex: "medal",
      key: "medal",
    },
    {
      title: "Player Name",
      dataIndex: "player",
      key: "player",
    },
    {
      title: "Certificate No.",
      dataIndex: "certificate",
      key: "certificate",
    },
    {
      title: "Club/School",
      dataIndex: "club",
      key: "club",
    },
  ];

  const printTable = () => {
    window.print();
  };

  const handleAfterPrint = React.useCallback(() => {}, []);

  const handleBeforePrint = React.useCallback(() => {
    return Promise.resolve();
  }, []);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "AwesomeFileName",
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });

  const handleSegmentChange = (value) => {
    setSelectedSection(value);
  };

  return (
    <>
      <Row>
        <Col span={24} className="d-flex justify-content-between">
          <CustomSegmented>
            <Segmented
              options={["All", "Boys", "Girls"]}
              value={selectedSection}
              onChange={handleSegmentChange}
            />
          </CustomSegmented>
          <div>
            <button
              className="button-29"
              style={{ marginRight: "5px" }}
              onClick={() => generateResult(eventData?._id)}
            >
              Generate Result
            </button>
            <button className="button-29" onClick={printFn}>
              Print Result
            </button>
          </div>
        </Col>
        <Col span={24} style={{ padding: "20px",height:'70vh'}}>
        <div style={{ height: '100%', overflowY: 'auto' }}>
          <Table
            ref={componentRef}
            columns={columns}
            dataSource={filteredData}
            bordered
            pagination={false}
            title={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src="/mpka.jpg"
                  alt="Logo 1"
                  style={{ width: "60px", height: "60px" }}
                />
                <div style={{ textAlign: "center" }}>
                  <h2
                    style={{
                      fontWeight: "bold",
                      fontSize: "24px",
                      margin: 0,
                    }}
                  >
                    {eventData?.name}
                  </h2>

                  <p style={{ fontSize: "16px", margin: "5px 0" }}>
                    {moment(eventData?.startDate).format("Do MMMM, YYYY")}{" "}
                    {" - "}
                    {moment(eventData?.endData).format("Do MMMM, YYYY")}
                  </p>

                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      margin: "5px 0",
                    }}
                  >
                    Result Sheet
                  </p>
                  <p style={{ fontSize: "16px", margin: 0 }}>
                    {/* Date: {moment().format("Do MMMM, YYYY")} */}
                    Date : {moment(eventData?.endData).format("Do MMMM, YYYY")}
                  </p>
                </div>

                <img
                  src="/bdka.jpg"
                  alt="Logo 2"
                  style={{ width: "60px", height: "60px" }}
                />
              </div>
            )}
          />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EventResult;
