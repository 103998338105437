import React, { Component } from "react";
import { withRouter } from "react-router";

import NavLogo from "./navLogo";
import NavContent from "./navContent";

import menuItems from "../../../utils/menu-items";
import { extractToken } from "../../../utils/localStorage";
import { capitalizeFirstLetter } from "../../../utils/helper";
import users from "../../../services/users";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: []
    };
  }
  componentDidMount() {

    let user = extractToken();
    this.getStateHeadById(user?.id)
  }

  getStateHeadById = async (userId) => {
    try {
      const data = await users.getStateHeadById(userId);
      this.setState({ apiData: data });
    } catch (error) {
      // this.setState({ error: error.message, loading: false });
    }
  };
  // filterMenuItems = () => {
  //   return menuItems.items.filter((item) => item?.visible);
  // };
  filterMenuItems = (items) => {

    return items
      .map((route) => {
      
        if (route.children && route.children.length > 0) {
          const filteredChildren = this.filterMenuItems(route.children);

          if (filteredChildren.length > 0 || route.visible) {
            return { ...route, children: filteredChildren };
          }
          return null;
        }
        if (route.visible) {
          // console.log(route?.title,route?.visible);
          return route;
        }

        return null;
      })
      .filter(Boolean);
  };
  render() {
    const filteredMenuItems = this.filterMenuItems(menuItems.items);
    let navContent = (
      <React.Fragment>
        <NavLogo
          collapseMenu={this.props.collapseMenu}
          windowWidth={this.props.windowWidth}
          onToggleNavigation={this.props.onToggleNavigation}
          role={capitalizeFirstLetter(this.state?.apiData?.data?.user?.role)}
        />
        <hr />
        <NavContent
          // navigation={menuItems.items}
          navigation={filteredMenuItems}
          handleSidebarColor={this.props.handleSidebarColor}
          currentSelected={this.props.currentSelected}
        />
      </React.Fragment>
    );
    return <>{navContent}</>;
  }
}

export default withRouter(Navigation);
