import {
  ArrowRightOutlined,
  CalendarOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EllipsisOutlined,
  SearchOutlined,
  SettingOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Collapse,
  Input,
  Row,
  DatePicker,
  Space,
  Progress,
  Carousel,
  Button,
  Table,
} from "antd";
import Meta from "antd/lib/card/Meta";
import { useState } from "react";
import { useParams } from "react-router-dom";
import LineChart from "../../chart/LineChart";
// import Highcharts from "highcharts";
// import { HighchartsReact } from "highcharts-react-official";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";

import {
  BlackHeadCardUser,
  CustomerCard,
  SuperWrapper,
  ViewProfileWrapper,
} from "./Styled";
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const UserDetails = () => {
  const [userSearch, setUserSearch] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [userDetails, setUserDetails] = useState({
    firstname: "John",
    lastname: "Doe",
    Address: "123 Main St",
    City: "Springfield",
    State: "IL",
    pincode: "62701",
    Country: {
      label: "United States",
    },
    UserType: {
      role: "Organization",
    },
    url: "https://www.example.com",
    is2FaActive: true,
  });
  const [open, setOpen] = useState(false);
  const query = useParams();
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
    
    },
    onSelect: (record, selected, selectedRows) => {
     
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      
    },
  };
  const userByOrg = [
    {
      id: 1,
      firstname: "John",
      lastname: "Doe",
      email: "john.doe@example.com",
      role: "Administrator",
      organization: "Sports District 1",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Gold",
    },
    {
      id: 2,
      firstname: "Jane",
      lastname: "Smith",
      email: "jane.smith@example.com",
      role: "Coach",
      organization: "Sports District 2",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Silver",
    },
    {
      id: 3,
      firstname: "Jim",
      lastname: "Brown",
      email: "jim.brown@example.com",
      role: "Athlete",
      organization: "Sports District 3",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Bronze",
    },
    {
      id: 4,
      firstname: "Jessica",
      lastname: "Johnson",
      email: "jessica.johnson@example.com",
      role: "Manager",
      organization: "Sports District 1",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Platinum",
    },
    {
      id: 5,
      firstname: "Jack",
      lastname: "Davis",
      email: "jack.davis@example.com",
      role: "Referee",
      organization: "Sports District 2",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Gold",
    },
    {
      id: 6,
      firstname: "Jack",
      lastname: "Deo",
      email: "jack.davis@example.com",
      role: "Referee",
      organization: "Sports District 2",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Gold",
    },
    {
      id: 7,
      firstname: "Marks",
      lastname: "Davis",
      email: "jack.davis@example.com",
      role: "Referee",
      organization: "Sports District 2",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Gold",
    },
    {
      id: 8,
      firstname: "John",
      lastname: "Deo",
      email: "jack.davis@example.com",
      role: "Referee",
      organization: "Sports District 2",
      image: "https://via.placeholder.com/150", // Placeholder image URL
      rank: "Gold",
    },
  ];

  const AllUserCard = () => {
    let filteredUsers = userByOrg;

    if (userSearch.trim() !== "") {
      filteredUsers = userByOrg.filter((item) =>
        `${item?.firstname} ${item?.lastname}`
          .toLowerCase()
          .includes(userSearch.toLowerCase())
      );
    }

    const renderCustomerCards = filteredUsers.map((item) => (
      <CustomerCard key={item._id}>
        <div
          className="d-flex align-items-center"
          style={{ justifyContent: "start" }}
        >
          <div>
            <Checkbox
              className="custom-checkbox"
              // checked={customerId === item._id}
              onChange={() => {
                setCustomerId(item._id);
              }}
            />
          </div>
          <div
            className="  px-2 "
            style={{
              textAlign: "left",
              color:
                customerId === item._id
                  ? "var(--dashboard-secondary)"
                  : "var(--text-primary)",
            }}
          >
            <div className=" ">
              {" "}
              {item?.firstname} {item?.lastname}
            </div>
            <div className="" style={{ fontSize: "11px" }}>
              role : {item?.UserType?.role}
            </div>
          </div>
        </div>
      </CustomerCard>
    ));

    return <>{renderCustomerCards}</>;
  };

  const events = [
    {
      date: "22 OCT",
      type: "Practise Bullpen",
      avatars: [
        /* array of avatar image URLs */
      ],
    },
    {
      date: "19 OCT",
      type: "On Field Batting Practice",
      avatars: [
        /* array of avatar image URLs */
      ],
    },
    {
      date: "16 SEP",
      type: "Sandlot's Day",
      avatars: [
        /* array of avatar image URLs */
      ],
    },
    // Add more events as needed
  ];

  const EventCarousel = () => (
    <Carousel>
      {events.map((event, index) => (
        <div key={index}>
          <Card style={{ width: 300 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <CalendarOutlined
                style={{ fontSize: "24px", marginRight: "8px" }}
              />
              <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                {event.date}
              </span>
            </div>
            <div style={{ fontSize: "16px", marginBottom: "16px" }}>
              Event Type: {event.type}
            </div>
            <div>
              {event.avatars.map((avatar, i) => (
                <Avatar key={i} src={avatar} style={{ marginRight: "4px" }} />
              ))}
            </div>
          </Card>
        </div>
      ))}
    </Carousel>
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "DATE",
      dataIndex: "date",
      sorter: (a, b) => a.date - b.date,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      sorter: (a, b) => a.status - b.status,
    },
    {
      title: "STUDENTS",
      dataIndex: "customer",
      sorter: (a, b) => a.customer - b.customer,
    },

    // {
    //   title: "PRODUCT",
    //   dataIndex: "product",
    //   sorter: (a, b) => a.product - b.product,
    // },
    // {
    //   title: "REVENUE",
    //   dataIndex: "revenue",
    //   sorter: (a, b) => a.revenue - b.revenue,
    // },
  ];

  const data = [
    {
      key: "1",
      id: "#10421",
      date: "1 Nov, 10:20 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar alt="" size="small" className="mr-10" />
          <span style={{ verticalAlign: "bottom" }}>Orlando Imieto</span>
        </div>
      ),
      product: "Nike Sport V2",
      revenue: "$140.20",
    },

    {
      key: "2",
      id: "#10422",
      date: "1 Nov, 10:53 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar alt="" size="small" className="mr-10" />
          <span style={{ verticalAlign: "bottom" }}>Alice Murinho</span>
        </div>
      ),
      product: "Valvet T-shirt",
      revenue: "$42.00",
    },

    {
      key: "3",
      id: "#10423",
      date: "1 Nov, 11:13 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-muted mr-5"
          >
            <UndoOutlined className="text-muted" style={{ fontSize: "10px" }} />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Refunded</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar alt="" size="small" className="mr-10 font-12">
            M
          </Avatar>
          <span style={{ verticalAlign: "bottom" }}>Leather Wallet</span>
        </div>
      ),
      product: "Valvet T-shirt",
      revenue: "$25.50",
    },

    {
      key: "4",
      id: "#10424",
      date: "1 Nov, 12:20 PM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar alt="" size="small" className="mr-10" />
          <span style={{ verticalAlign: "bottom" }}>Andrew Nichel</span>
        </div>
      ),
      product: "Bracelet Onu-Lino",
      revenue: "$19.40",
    },

    {
      key: "5",
      id: "#10425",
      date: "1 Nov, 1:40 PM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-danger mr-5"
          >
            <CloseOutlined
              className="text-danger"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Canceled</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar alt="" size="small" className="mr-10" />
          <span style={{ verticalAlign: "bottom" }}>Sebastian Koga</span>
        </div>
      ),
      product: "Phone Case Pink",
      revenue: "$44.90",
    },
    {
      key: "6",
      id: "#10426",
      date: "1 Nov, 2:19 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10">
            L
          </Avatar>
          <span style={{ verticalAlign: "bottom" }}>Laur Gilbert</span>
        </div>
      ),
      product: "Backpack Niver",
      revenue: "$112.50",
    },

    {
      key: "7",
      id: "#10427",
      date: "1 Nov, 3:42 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10">
            I
          </Avatar>
          <span style={{ verticalAlign: "bottom" }}>Iryna Innda</span>
        </div>
      ),
      product: "Adidas Vio",
      revenue: "$200.00",
    },

    {
      key: "8",
      id: "#10428",
      date: "2 Nov, 9:32 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10">
            A
          </Avatar>
          <span style={{ verticalAlign: "bottom" }}>Arrias Liunda</span>
        </div>
      ),
      product: "Airpods 2 Gen",
      revenue: "$350.00",
    },

    {
      key: "9",
      id: "#10429",
      date: "2 Nov, 10:14 AM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10"></Avatar>
          <span style={{ verticalAlign: "bottom" }}>Rugna Ilpio</span>
        </div>
      ),
      product: "Bracelet Warret",
      revenue: "$15.00",
    },

    {
      key: "10",
      id: "#10430",
      date: "2 Nov, 12:56 PM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-muted mr-5"
          >
            <UndoOutlined className="text-muted" style={{ fontSize: "10px" }} />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Refunded</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10"></Avatar>
          <span style={{ verticalAlign: "bottom" }}>Anna Landa</span>
        </div>
      ),
      product: "Watter Bottle India",
      revenue: "$25.00",
    },
    {
      key: "11",
      id: "#10431",
      date: "2 Nov, 3:12 PM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10">
            K
          </Avatar>
          <span style={{ verticalAlign: "bottom" }}>Karl Innas</span>
        </div>
      ),
      product: "Kitchen Gadgets",
      revenue: "$164.90",
    },
    {
      key: "12",
      id: "#10432",
      date: "2 Nov, 5:12 PM",
      status: (
        <>
          <Button
            shape="circle"
            size="small"
            className="btn-status border-success mr-5"
          >
            <CheckOutlined
              className="text-success"
              style={{ fontSize: "10px" }}
            />
          </Button>
          <span style={{ verticalAlign: "bottom" }}>Paid</span>
        </>
      ),
      customer: (
        <div className="table-avatar-info">
          <Avatar size="small" className="mr-10">
            O
          </Avatar>
          <span style={{ verticalAlign: "bottom" }}>Oana Kilas</span>
        </div>
      ),
      product: "Office Papers",
      revenue: "$23.90",
    },
  ];
  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 530px)", x: true } : null;

  return (
    <SuperWrapper>
      <ViewProfileWrapper gutter={[8, 12]} style={{ height: "100vh" }}>
        <Col span={5}>
          <Row
            className="all-users-card"
            style={{
              backgroundColor: "#ffffff",
              height: "85vh",
              //   border: "1px solid red",
            }}
          >
            <Col span={24} className="all-users-card-header ">
              All Users
            </Col>
            <Col className="all-users-card-searchbox mb-3">
              <div className="input-box">
                <Input
                  style={{
                    height: "30px",
                    fontSize: "14px",
                    background: "var(--dashboard-primay)",
                  }}
                  placeholder="Search Users "
                  onChange={(e) => setUserSearch(e.target.value)}
                />
                <div className="icon">
                  <SearchOutlined style={{ margin: "5px", fontSize: "14px" }} />
                </div>
              </div>
            </Col>

            <Scrollbars style={{ height: "83%" }} autoHide>
              <AllUserCard />
            </Scrollbars>
          </Row>
        </Col>
        <Col span={19}>
          <Row gutter={[8, 12]}>
            <Col
              span={6}
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "6px",
                height: "85vh",
              }}
            >
              <div className="user-card" style={{ height: "100%" }}>
                <div
                  className="user-info-card"
                  style={{ height: "20%", color: "#fff", fontSize: 500 }}
                >
                  <div
                    span={24}
                    className="user-info-details-container"
                    style={{ height: "100%" }}
                  >
                    <div
                      style={{
                        // backgroundImage: `url(${ProjectGenral})`,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        objectFit: "cover",
                        alignItems: "center",
                        // borderRadius: "6px",
                      }}
                    >
                      <div
                        className="p-1 hex-card m-2"
                        style={{
                          // maxWidth: "83px",
                          // maxHeight: "83px",
                          // minWidth: "83px",
                          // minHeight: "83px",
                          maxWidth: "24%",
                          maxHeight: "60%",
                          minWidth: "24%",
                          minHeight: "60%",
                        }}
                      >
                        {userDetails?.profilePicture ? (
                          <img
                            className="hex-img"
                            style={{
                              // maxWidth: "75px",
                              // maxHeight: "75px",
                              // minWidth: "75px",
                              // minHeight: "75px",
                              maxWidth: "100%",
                              maxHeight: "100%",
                              minWidth: "100%",
                              minHeight: "100%",
                            }}
                            src={userDetails?.profilePicture}
                            alt="img"
                          />
                        ) : (
                          <div
                            className="text-white hex-card p-1 mid"
                            style={{
                              maxWidth: "75px",
                              maxHeight: "75px",
                              minWidth: "75px",
                              minHeight: "75px",
                            }}
                          >
                            {userDetails?.firstname?.[0]}{" "}
                            {userDetails?.lastname?.[0]}
                          </div>
                        )}
                      </div>

                      <div
                        className="px-2 poppins"
                        style={{
                          width: "60%",
                          color: "var(--text-primary)",
                        }}
                      >
                        <div className="ellipsis" style={{ fontSize: "17px" }}>
                          {userDetails?.firstname} {userDetails?.lastname}
                          <span className="px-2"></span>
                        </div>
                        <div className="ellipsis" style={{ fontSize: "11px" }}>
                          {userDetails?.email}
                        </div>
                        <div className="ellipsis" style={{ fontSize: "11px" }}>
                          {" "}
                          role : {userDetails?.UserType?.role}
                        </div>
                        <div className="ellipsis" style={{ fontSize: "11px" }}>
                          <span>
                            Joined on :{" "}
                            {/* {currency?.timezone && (
                            <span>
                              {moment(userDetails?.createdAt)
                                .tz(currency?.timezone)
                                .format("DD-MM-YYYY")}
                            </span>
                          )} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "20%",
                    //   border: "1px solid red",
                    marginTop: "30px",
                  }}
                >
                  <Scrollbars autoHide>
                    <div
                      className="mt-3 px-2 font-bold"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>Personal Info </span>{" "}
                      <span style={{ flexGrow: 1, marginLeft: "10px" }}>
                        <hr
                          style={{
                            border: "none",
                            borderBottom: "1px solid gray",
                            margin: 0,
                          }}
                        />
                      </span>
                    </div>
                    <div
                      className="  d-flex mt-2  px-2 "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ flex: "2" }}>Age</div>
                      <div style={{ flex: "1" }}> 25</div>
                    </div>
                    <div
                      className="  d-flex mt-2  px-2 "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ flex: "2" }}>Height</div>
                      <div style={{ flex: "1" }}> 6 feet</div>
                    </div>
                    <div
                      className="  d-flex mt-2  px-2 "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ flex: "2" }}>Weight</div>
                      <div style={{ flex: "1" }}> 66</div>
                    </div>
                    <div
                      className="  d-flex mt-2  px-2 "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ flex: "2" }}>Belt</div>
                      <div style={{ flex: "1" }}>Black</div>
                    </div>
                  </Scrollbars>
                </div>

                <div
                  style={{
                    height: "42%",
                    //   border: "1px solid red",
                    marginTop: "30px",
                  }}
                >
                  <div
                    className="mt-3 px-2 font-bold"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span>Bio </span>{" "}
                    <span style={{ flexGrow: 1, marginLeft: "10px" }}>
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "1px solid gray",
                          margin: 0,
                        }}
                      />
                    </span>
                  </div>
                  <Scrollbars autoHide>
                    <div
                      className="  d-flex mt-2  px-2 "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div style={{ flex: "1" }}>
                        Sensei Trenton Johanis is a Third Degree (Sandan) Black
                        Belt in Karate and a Blue Belt in Brazilian Jiu-Jitsu.
                        He has been involved in martial arts for over 15 years
                        and was raised in the family business! Trenton is a
                        two-time CKKA Black Belt Kumite Champion, a Black Belt
                        Forms Champion, and a Team Form Champion. After teaching
                        full-time at JKS through high school, Trenton pursued a
                        Bachelor of Science in Psychology, Neuroscience &
                        Behaviour (Music Cognition Specialization) at McMaster
                        University. While at Mac, Trenton was the President and
                        Head Instructor of McMaster Brazilian Jiu-Jitsu. He also
                        trained with the McMaster Shaolin Kung-Fu Association.
                        Trenton is now pursuing his MA/PhD in Psychology at the
                        University of Toronto with the intention of becoming a
                        university professor. When he is not on the mats, you
                        can find Trenton listening to music, playing his guitar,
                        or at the gym!
                      </div>
                    </div>
                  </Scrollbars>
                </div>
              </div>
            </Col>
            <Col span={18} style={{ height: "85vh" }}>
              <Row gutter={[12, 8]}>
                <Col style={{ height: "25vh" }} span={8}>
                  <BlackHeadCardUser
                    className="user-card"
                    bordered={false}
                    title={
                      <div
                        style={{ width: "100%", textAlign: "start" }}
                        className=""
                      >
                        <h4>Skill Set</h4>
                      </div>
                    }
                    style={{ margin: "0px", height: "100%" }}
                  >
                    <Row gutter={[6, 6]}>
                      <Col span={24}>
                        <Progress percent={30} />
                        <p>Technical Precision in Kata</p>

                        <Progress percent={30} />
                        <p>Technical Precision in Kumite</p>
                      </Col>
                    </Row>
                  </BlackHeadCardUser>
                </Col>
                <Col style={{ height: "25vh" }} span={16}>
                  <BlackHeadCardUser
                    className="user-card"
                    bordered={false}
                    title={
                      <div
                        style={{ width: "100%", textAlign: "start" }}
                        className=""
                      >
                        <h4>Recent Events</h4>
                      </div>
                    }
                    style={{ margin: "0px" }}
                  >
                    <Row gutter={[6, 6]}>
                      <Col span={24}>
                        <EventCarousel />
                      </Col>
                    </Row>
                  </BlackHeadCardUser>
                </Col>

                <Col
                  span={24}
                  style={{
                    height: "59vh",
                    borderRadius: "6px",
                  }}
                >
                  <Row
                    className="transactions-card"
                    style={{
                      height: "100%",
                      maxHeight: "100%",
                      background: "#fff",
                      borderRadius: "6px",
                      //   border: "1px solid",
                    }}
                  >
                    {/* <Col span={24} className="transactions-card-header">
                      Transactions And Activities
                    </Col> */}
                    <Row gutter={[24, 0]}>
                      <Col span={24}>
                        <Card
                          className="header-solid mb-24"
                          bordered={false}
                          //   extra={[
                          //     <Input
                          //       placeholder="input text search"
                          //       size="small"
                          //       suffix={<SearchOutlined />}
                          //     />,
                          //   ]}
                        >
                          <Table
                            tableLayout="auto"
                            columns={columns}
                            dataSource={data}
                            // onChange={onChange}
                            rowSelection={{ ...rowSelection }}
                            scroll={scroll}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </ViewProfileWrapper>
    </SuperWrapper>
  );
};

export default UserDetails;
