import {
  EditOutlined,
  MoreOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Segmented,
  Select,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import institute from "../../../services/institute";
import { notify } from "../../../utils/helper";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";
import Breadcrumb from "../../layout/breadcrumb";

const InstituteDetails = () => {
  const [isEditUser, setIsEditUser] = useState(false);
  const { id } = useParams();
  const [instituteData, setInstituteData] = useState({});
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const types = [
    { label: "Club", value: "CLUB" },
    { label: "School", value: "SCHOOL" },
    { label: "College", value: "COLLEGE" },
  ];

  const getInstituteById = async (id) => {
    try {
      let res = await institute.getInstituteById(id);
      setInstituteData(res?.data?.institute);
    } catch (err) {}
  };

  useEffect(() => {
    getInstituteById(id);
  }, [id]);

  const updateInstitute = async (payload) => {
    try {
      let res = await institute.updateInstitute(id, payload);
      notify("success", "Institute Updated Successfully.");
      getInstituteById(id);
      setIsEditUser(false);
    } catch (err) {}
  };

  const populateFormFields = () => {
    form.setFieldsValue({
      name: instituteData?.name,
      type: instituteData?.type,
    });
  };

  useEffect(() => {
    if (isEditUser) {
      populateFormFields();
    }
  }, [isEditUser]);

  useEffect(() => {
    if (instituteData?.name)
      dispatch(
        setBreadcrumb({
          title: "Institute",
          subtitle: instituteData?.name,
          url: "",
        })
      );
  }, [instituteData]);

  return (
    <Card
      title={
        <Row justify="space-between" align="middle" gutter={[24, 0]}>
          <Col span={24} md={12} className="col-info">
            <Avatar.Group>
              <Avatar
                size={74}
                shape="square"
                src={
                  instituteData?.profilePicture ? (
                    instituteData?.profilePicture
                  ) : (
                    <div style={{ background: "gray" }}>
                      {instituteData?.name?.trim()?.[0]?.toUpperCase() || "-"}
                    </div>
                  )
                }
              />

              <div className="avatar-info" style={{ marginLeft: "10px" }}>
                <h4 className="font-semibold m-0">{instituteData?.name}</h4>
              </div>
            </Avatar.Group>
          </Col>
        </Row>
      }
    >
      <>
        <Form onFinish={updateInstitute}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div
                className="w-100"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h6>Summary</h6>
                <div className="px-3">
                  {!isEditUser ? (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsEditUser(!isEditUser)}
                    >
                      <EditOutlined color="var(--text-primary)" size={20} />
                    </div>
                  ) : (
                    <div>
                      <Button
                        style={{
                          marginRight: "10px",
                        }}
                        type="primary"
                        onClick={() => setIsEditUser(!isEditUser)}
                        className="font-bold cursor-pointer mx-4"
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="font-bold cursor-pointer mx-4"
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col span={24} className="m-2"></Col>
            <Col span={6}>
              {/* <div className="subHeading">First Name</div> */}
              <h6 className="list-header text-m text-muted m-0">Name</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ marginBottom: 10 }}
                  className="username"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required!",
                    },
                  ]}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              ) : (
                <div className="font-semibold">{instituteData?.name}</div>
              )}
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Last Name</div> */}
              <h6 className="list-header text-m text-muted m-0">Type</h6>
              {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Type is required!",
                    },
                  ]}
                >
                  <Select placeholder="Select type">
                    {types.map((org) => (
                      <Select.Option key={org.value} value={org.value}>
                        {org.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <div className="font-semibold">{instituteData?.type}</div>
              )}
            </Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">State</h6>
              {/* {isEditUser ? (
                <Form.Item
                  style={{ margin: 0 }}
                  className="username"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "state is required!",
                    },
                  ]}
                >
                  <Select placeholder="Select type">
                    {types.map((org) => (
                      <Select.Option key={org.value} value={org.value}>
                        {org.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : ( */}
              <div className="font-semibold">{instituteData?.state}</div>
              {/* //   )} */}
            </Col>
            <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">District</h6>
              <div className="font-semibold">{instituteData?.district}</div>
            </Col>
            {/* <Col span={6}>
              <h6 className="list-header text-m text-muted m-0">Coach</h6>
              <div className="font-semibold">{`${instituteData?.coach?.firstName} ${instituteData?.coach?.lastName}`}</div>
            </Col> */}
            <Col span={6}>
              {/* <div className="subHeading">Member since</div> */}
              <h6 className="list-header text-m text-muted m-0">Created on</h6>
              <div className="font-semibold">
                {moment(instituteData?.createdAt).format("DD MMM YYYY")}
              </div>
            </Col>
            <Col span={6}>
              {/* <div className="subHeading">Member since</div> */}
              <h6 className="list-header text-m text-muted m-0">Updated on</h6>
              <div className="font-semibold">
                {moment(instituteData?.updatedAt).format("DD MMM YYYY")}
              </div>
            </Col>

            <Col span={24} className="m-3 text-center">
              {!isEditUser && (
                <>
                  <Divider />

                  <Button
                    type="primary"
                    className="font-bold cursor-pointer mx-4 p-0"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </>
    </Card>
  );
};

export default InstituteDetails;
