import React, { useEffect, useState } from "react";
import {
  Modal,
  Select,
  Checkbox,
  Button,
  Table,
  Input,
  Tabs,
  Divider,
} from "antd";
import ModalWrapper from "../../modal/ModalWrapper";
import users from "../../../services/users";
import moment from "moment";
import event from "../../../services/event";
import teamService from "../../../services/teams";
import { notify } from "../../../utils/helper";

const { Option } = Select;
const { TabPane } = Tabs;

const AddEntriesModal = ({ open, setOpen, getActiveEvent, selectedEvent }) => {

  const [activeTab, setActiveTab] = useState("individual");
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [categories, setCategories] = useState({
    kata: false,
    kumite: false,
  });
  const [entries, setEntries] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [selectedTeamCategory, setSelectedTeamCategory] = useState(null);
  const [teams, setTeams] = useState([]);
  const [allStudents, setAllStudents] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [teamSize, setTeamSize] = useState(0); 

  const players = [{ id: 1, name: "Player 1" }];

  const teamCategories = ["Category"];

  const StudentsData = [];

  const getMyStudents = async () => {
    try {
      const res = await users.getMyStudents(selectedEvent[0]);
      setAllStudents(res?.data?.students);
    } catch (error) {}
  };

  const getSubCategory = async () => {
    try {
      const res = await event.getSubCategory(selectedEvent[0]);
      setCategoryData(res?.data?.response?.records);
    } catch (error) {}
  };

  const geStudentCategory = async () => {
    try {
      const res = await event.geStudentCategory({
        categoryId: selectedTeamCategory,
      });
      setStudentsData(res?.data?.students);
    } catch (error) {}
  };

  const handlePlayerSelect = (value) => {
    setSelectedPlayer(value);
  };

  const handleCategoryChange = (category, checked) => {
    setCategories((prev) => ({
      ...prev,
      [category]: checked,
    }));
  };

  const handleAddTeam = async () => {
    if (teamName && selectedTeamCategory) {
      const newTeam = {
        name: teamName,
        students: selectedPlayer,
        eventId: selectedEvent[0],
        categoryId: selectedTeamCategory,
      };
      const res = await teamService.createTeam(newTeam);
      notify("success", "Team Assigned Successfully.")
      setTeams([...teams, newTeam]);
      setTeamName("");
      setStudentsData([]);
      setSelectedTeamCategory(null);
      setOpen(false)
    }
  };

  const handleAddEntry = async () => {
    try {
      const studentsPayload = selectedRowKeys.map((id) => ({
        id: id,
        kata: categories.kata,
        kumite: categories.kumite,
      }));

      const res = await event.participateEvent({
        eventId: selectedEvent[0],
        students: studentsPayload,
      });

      notify("success", "Players assigned successfuylly.");
      setOpen(false);
      getActiveEvent();
    } catch (error) {}
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = StudentsData.filter((student) =>
      student.name.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  const individualColumns = [
    {
      title: "Player Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
  ];

  const teamColumns = [
    {
      title: "Team Name",
      dataIndex: "teamName",
      key: "teamName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Kata",
      dataIndex: "kata",
      key: "kata",
    },
    {
      title: "Kumite",
      dataIndex: "kumite",
      key: "kumite",
    },
  ];

  for (let item of allStudents || []) {
    StudentsData.push({
      key: item?._id,
      name: item?.firstName + " " + item?.lastName,
      gender: item?.gender,
      age: moment(item?.dateOfBirth).format("DD MMM YYYY"),
      weight: item?.weight,
    });
  }

  useEffect(() => {
    getMyStudents();
    getSubCategory();
  }, []);

  useEffect(() => {
    if (selectedTeamCategory?.length) geStudentCategory();
  }, [selectedTeamCategory]);



  return (
    <ModalWrapper
      title="Add Entries"
      open={open}
      setOpen={setOpen}
      footer={null}
      width={600}
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Individual" key="individual">
          <div style={{ marginBottom: 16 }}>
            <Checkbox
              checked={categories.kata}
              onChange={(e) => handleCategoryChange("kata", e.target.checked)}
            >
              Kata
            </Checkbox>
            <Checkbox
              checked={categories.kumite}
              onChange={(e) => handleCategoryChange("kumite", e.target.checked)}
              style={{ marginLeft: 16 }}
            >
              Kumite
            </Checkbox>
          </div>

          {/* <Input
            placeholder="Search students"
            value={searchText}
            onChange={handleSearch}
            style={{ margin: 16, 0 }}
          /> */}

          <Divider />

          <Table
            dataSource={StudentsData}
            columns={individualColumns}
            rowSelection={rowSelection}
            // style={{ marginTop: 24 }}
          />
          <Divider />
          <div>
            <Button
              type="primary"
              onClick={handleAddEntry}
              // disabled={
              //   selectedPlayer?.length === 0 || !categories.kumite
              // }
            >
              Add Player
            </Button>
          </div>
        </TabPane>

        <TabPane tab="Team" key="team">
          <Input
            placeholder="Enter team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            style={{ marginBottom: 16 }}
          />

          <Select
            placeholder="Select Category"
            value={selectedTeamCategory}
            onChange={(value) => {
              setSelectedTeamCategory(value);
              setSelectedPlayer([]);
              const selectedCategory = categoryData.find(
                (cat) => cat._id === value
              );
              if (selectedCategory) {
                setTeamSize(selectedCategory.teamSize); 
              }
            }}
            style={{ width: "100%", marginBottom: 16 }}
          >
            {categoryData?.map((category) => (
              <Option key={category?._id} value={category?._id}>
                {category?.name}
              </Option>
            ))}
          </Select>

          <Select
            placeholder="Select Players"
            value={selectedPlayer}
            mode="multiple"
            onChange={handlePlayerSelect}
            style={{ width: "100%", marginBottom: 16 }}
            maxTagCount="responsive" // Adjusts the display of selected tags
          >
            {studentsData?.map((player) => (
              <Option key={player?._id} value={player?._id}>
                {player?.firstName + " " + player?.lastName}
              </Option>
            ))}
          </Select>

          {/* Displaying selected count */}
          <div style={{ marginBottom: 16 }}>
            {selectedPlayer.length} out of {teamSize} players selected
          </div>

          <Divider />
          <div>
            <Button
              type="primary"
              onClick={handleAddTeam}
              disabled={selectedPlayer.length !== teamSize} // Enable only when count matches
            >
              Add Team
            </Button>
          </div>
        </TabPane>
      </Tabs>
    </ModalWrapper>
  );
};

export default AddEntriesModal;
