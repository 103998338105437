import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import users from "../../../../services/users";
import { notify } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
const CreateStateUsers = () => {
  const [role, setRole] = useState("");
  const [stateData, setStateData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const userRole = queryParams.get("role");
  const roles = [
    // { label: "Super Admin", value: "superAdmin" },
    { label: "State Head", value: "stateHead" },
    { label: "State Admin", value: "stateAdmin" },
    { label: "District Head", value: "districtHead" },
    { label: "District Admin", value: "districtAdmin" },
    { label: "Coach", value: "coach" },
    { label: "Student", value: "student" },
  ];

  // const fetchStates = async () => {
  //   const response = await fetch(
  //     "https://cdn-api.co-vin.in/api/v2/admin/location/states"
  //   );
  //   const data = await response.json();
  //   setStateData(data.states);
  // };

  // const fetchDistricts = async (stateName) => {
  //   const stateId = stateData.filter(
  //     (state) => state.state_name === stateName
  //   )[0]?.state_id;
  //   const response = await fetch(
  //     `https://cdn-api.co-vin.in/api/v2/admin/location/districts/${stateId}`
  //   );
  //   const data = await response.json();
  //   setDistrictData(data.districts);
  // };

  const getAllStates = async () => {
    try {
      const response = await users.getAllStates();
      setStateData(response?.data);
    } catch (err) {
      // console.log(err);
    }
  };


  const getAllDistrict = async (stateName) => {
    const stateId = stateData.filter(
      (state) => state.name === stateName
    )[0]?.id;

    try {
      const response = await users.getAllDistrict(stateId);
      setDistrictData(response?.data?.names);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    // fetchStates();
    getAllStates();
  }, []);

  const createUser = async (values) => {
    const payload = {
      ...values,
      role: userRole,
    };
    try {
      const res = await users.createUsers(payload);

      notify("success", "User Created Successfully.");
      history.goBack();
    } catch (err) {}
  };

  const convertToTitleCase = (str) => {
    return (
      str
        // Insert space before each uppercase letter
        .replace(/([A-Z])/g, " $1")
        // Capitalize the first letter and lowercase the rest
        .replace(/^./, (firstChar) => firstChar.toUpperCase())
    );
  };

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: convertToTitleCase(userRole),
        subtitle: `Create New ${convertToTitleCase(userRole)}`,
        url: "",
      })
    );
  }, []);




  return (
    <Card title={<h4>Create {convertToTitleCase(userRole)}</h4>}>
      <Form onFinish={createUser} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24} className="m-2"></Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">First Name</h4>

            <Form.Item
              style={{ marginBottom: 10 }}
              className="username"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "firstname is required!",
                },
              ]}
            >
              <Input placeholder="Firstname" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Last Name</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="username"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: "lastname is required!",
                },
              ]}
            >
              <Input placeholder="Lastname" />
            </Form.Item>
          </Col>

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Email</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "email is required!",
                },
              ]}
            >
              <Input placeholder="email" type="email" />
            </Form.Item>
          </Col>
          {/* <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Password</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "password is required!",
                },
              ]}
            >
              <Input placeholder="password" type="password"/>
            </Form.Item>
          </Col> */}
          {/* <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Role</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="role"
              name="role"
              rules={[
                {
                  required: true,
                  message: "role is required!",
                },
              ]}
            >
              <Select
                placeholder="Select a role"
                value={role}
                onChange={(e) => setRole(e)}
              >
                {roles.map((role) => (
                  <Select.Option key={role.value} value={role.value}>
                    {role.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          {role === "student" && (
            <>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Class</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="class"
                  name="class"
                  rules={[
                    {
                      required: true,
                      message: "class is required!",
                    },
                  ]}
                >
                  <Input placeholder="class" />
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Age</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="age"
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: "age is required!",
                    },
                  ]}
                >
                  <Input placeholder="age" />
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Height</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="height"
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: "height is required!",
                    },
                  ]}
                >
                  <Input placeholder="height" />
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} span={12}>
                <h4 className="list-header text-m text-muted m-0">Weight</h4>

                <Form.Item
                  style={{ margin: 0 }}
                  className="weight"
                  name="weight"
                  rules={[
                    {
                      required: true,
                      message: "weight is required!",
                    },
                  ]}
                >
                  <Input placeholder="weight" />
                </Form.Item>
              </Col>
            </>
          )}

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">State</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="state"
              name="state"
              rules={[
                {
                  required: true,
                  message: "state is required!",
                },
              ]}
            >
              {/* <Input placeholder="state" /> */}
              <Select
                placeholder="Select state"
                //   value={role}
                showSearch
                onChange={(e) => {
                  // fetchDistricts(e);
                  getAllDistrict(e);
                }}
              >
                {stateData.map((org) => (
                  <Select.Option key={org.id} value={org.name}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">District</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="district"
              name="district"
              rules={[
                {
                  required: true,
                  message: "district is required!",
                },
              ]}
            >
              {/* <Input placeholder="district" /> */}
              <Select showSearch placeholder="Select district">
                {districtData.map((org) => (
                  <Select.Option
                    key={org?.id}
                    value={org?.name}
                  >
                    {org?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">City</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="city"
              name="city"
              rules={[
                {
                  required: true,
                  message: "city is required!",
                },
              ]}
            >
              <Input placeholder="city" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Pincode</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="pincode"
              name="pincode"
              rules={[
                {
                  required: true,
                  message: "pincode is required!",
                },
              ]}
            >
              <Input placeholder="pincode" />
            </Form.Item>
          </Col>
          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Mobile</h4>

            <Form.Item
              style={{ margin: 0 }}
              className="mobile"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: "mobile is required!",
                },
              ]}
            >
              <Input placeholder="mobile" />
            </Form.Item>
          </Col>
          <Col span={24} className="m-3"></Col>
          <Col
            span={24}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              className="px-25"
              onClick={() => history.goBack()}
            >
              CANCEL
            </Button>
            <Button type="primary" className="px-25" htmlType="submit">
              CREATE {convertToTitleCase(userRole).toUpperCase()}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateStateUsers;
