import { Avatar, Button, Col, Empty, Input, Row, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import institute from "../../../services/institute";
import { path } from "../../../utils/path";
import moment from "moment";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { notify, ShowTotal } from "../../../utils/helper";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";
const Institute = () => {
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const dispatch = useDispatch()

  const getInstitutes = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await institute.getInstitutes(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = (value) => {
    getInstitutes(value);
  };

  useEffect(() => {
    getInstitutes();
  }, [pagination?.size,pagination?.currentPage,sortingColumn,sortingOrder,searchText]);

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>{item?.name?.[0]?.toUpperCase() || "-"}</div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ width:"150px", display: "flex", alignItems: "center" }}
          >
            <Link
              to={path().STUDENTS_INSTITUTE_DETAILS(item?._id)}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.name || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort:item?.name || "-",
      type: item?.type ? item?.type :"-",
      state: item?.state ? item?.state :"-",
      district:item?.district ? item?.district : "-",
      coach: item?.coach?.firstName ? `${item?.coach?.firstName} ${item?.coach?.lastName}`: "-",
      createdAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.createdAt).format("DD MMM YYYY") || "-"}
        </div>
      ),

      updatedAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.updatedAt).format("DD MMM YYYY") || "-"}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
      width: 200,
    },
    {
      title: "TYPE",
      className: "ant-table-row-cell-break-word",
      dataIndex: "type",
      align: "center",
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 2,
      },
    },
    {
      title: "STATE",
      className: "ant-table-row-cell-break-word",
      dataIndex: "state",
      align: "center",
      // sorter: {
      //   compare: (a, b) => a.type - b.type,
      //   multiple: 2,
      // },
    },
    {
      title: "DISTRICT",
      className: "ant-table-row-cell-break-word",
      dataIndex: "district",
      align: "center",
      sorter: {
        compare: (a, b) => a.district.localeCompare(b.district),
        multiple: 2,
      },
    },
    {
        title: "COACH",
        className: "ant-table-row-cell-break-word",
        dataIndex: "coach",
        align: "center",
        sorter: {
          compare: (a, b) => a.coach.localeCompare(b.coach),
          multiple: 2,
        },
      },
    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      align: "center",
      className: "ant-table-row-cell-break-word",
      width: 150,
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      align: "center",
      className: "ant-table-row-cell-break-word",
      width: 150,
    },
  ];



  const handleClick = () => {
    history.push(`/students/institute/create-institute`);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const handleDelete = async () => {
    try {
      setLoading(true);
      let res = await institute.deleteInstitute({"institute": selectedRowKeys});
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "Institute Deleted Successfully.");
      getInstitutes();
      setLoading(false);
    } catch (err) {
    }
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(()=> {
    dispatch(
      setBreadcrumb({
        title: "All Institutes",
        subtitle: "",
        url: "",
      })
    );
  }, [])
  return (
    <Row>
      <Col
        span={24}
        className="header-control"
        style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}
      >
        <Button type="primary" className="px-25" onClick={handleClick}>
          CREATE NEW INSTITUTE
        </Button>
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          scroll={scroll}
          rowSelection={rowSelection}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {selectedRowKeys.length > 0 ? (
                <DeleteOutlined
                  onClick={handleDelete}
                  style={{
                    fontSize: "18px",
                    color: selectedRowKeys.length > 0 ? "red" : "gray",
                    cursor:
                      selectedRowKeys.length > 0 ? "pointer" : "not-allowed",
                  }}
                  disabled={selectedRowKeys.length === 0}
                />
              ) : null}
            </div>
          )}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            total: count,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
      </Col>
    </Row>
  );
};

export default Institute;
