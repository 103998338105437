import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Upload,
} from "antd";
import photo from "../../images/karate_picture_01.jpg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";
import event from "../../../services/event";
import { notify } from "../../../utils/helper";
import { MdCloudUpload } from "react-icons/md";
import { setBreadcrumb } from "../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";

const { Option } = Select;
const { RangePicker } = DatePicker;

const CreateEvent = () => {
  const [file, setFile] = useState(null);
  const [bannerImage, setBannerImage] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const CreateEvent = async (values) => {
    try {
      const [startDate, endDate] = values.eventDate;
      const res = await event.createEvent({
        name: values?.eventName,
        organizerName: values?.eventOrganizer,
        description: values?.eventDescription,
        eventType: values?.eventType,
        district: values?.district,
        state: values?.state,
        city: values?.city,
        pinCode: values?.pinCode,
        address: values?.address,
        startDate: startDate,
        endDate: endDate,
        registrationStartDate: values?.registrationStartdate,
        registrationLastDate: values?.registrationDeadline,
        cutOffDate: values?.cutOffDate,
        social: {
          website: values?.eventWebsite,
          facebook: values?.facebookPage,
          twitter: values?.twitterHandle,
        },
        contact: {
          name: values?.contactPersonName,
          phone: values?.contactPersonPhone,
        },
      });
      if (file) {
        await handleUpload(res?.data?._id, file?.file);
      }

      notify("success", "Event has been created successfully.");
      history.push(`/pages/events/all-events`);
    } catch (error) {}
  };

  const handleUpload = async (id, file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await event.uploadBanner(id, formData);
    } catch (err) {
      console.error("File upload error:", err);
    }
  };

  const handleFileChange = (file) => {
    setFile(file);
    setBannerImage(file);
  };

  const beforeUpload = (file) => {
    setFile(file);
    setBannerImage(file);
    return false;
  };

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Events",
        subtitle: "Create New Event",
        url: "",
      })
    );
  }, []);

  return (
    <>
      <Form layout="vertical" form={form} onFinish={CreateEvent}>
        <Row gutter={[24, 0]}>
          <Col span={24} lg={12} className="mt-24 mb-24">
            <h4 className="h4">Create Karate Event</h4>
            <p>
              Please fill in the details below to create a new event. Make sure
              to provide all the necessary information.
            </p>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col span={24} lg={8} className="mb-24">
            <Card
              bodyStyle={{ paddingTop: "0px" }}
              bordered={false}
              className="header-solid h-full"
              title={<h5 className="h5 mb-0">Event Image</h5>}
            >
              {bannerImage && (
                <img
                  src={bannerImage}
                  alt="card"
                  className="product-img shadow-3 img-contain"
                />
              )}
              <div className="mt-20">
                <Upload
                  name="attachment"
                  listType="text"
                  multiple={false}
                  accept="image/*"
                  type="file"
                  beforeUpload={beforeUpload}
                  onChange={handleFileChange}
                >
                  <Button icon={<MdCloudUpload />}>Add Banner</Button>
                </Upload>
                {/* <Button className="ml-10 px-25">REMOVE</Button> */}
              </div>
            </Card>
          </Col>
          <Col span={24} lg={16} className="mb-24">
            <Card
              title={<h5 className="h5">Event Information</h5>}
              className="header-solid h-full"
              bordered={false}
              bodyStyle={{ paddingTop: "10px", paddingBottom: "40px" }}
            >
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="eventName"
                    label="Event Name"
                    colon={false}
                    rules={[
                      { required: true, message: "Please enter event name" },
                    ]}
                  >
                    <Input placeholder="Enter event name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="eventOrganizer"
                    label="Event Organizer"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter organizer name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter organizer name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="eventType"
                    label="Event Type"
                    colon={false}
                    rules={[
                      { required: true, message: "Please select event type" },
                    ]}
                  >
                    <Select defaultValue="Tournament">
                      <Option value="Tournament">Tournament</Option>
                      <Option value="Workshop">Workshop</Option>
                      <Option value="Seminar">Seminar</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="eventDate"
                    label="Event Date"
                    colon={false}
                    rules={[
                      { required: true, message: "Please select event date" },
                    ]}
                  >
                    <RangePicker
                      showTime
                      format="DD-MMM-YYYY HH:mm"
                      defaultValue={[
                        moment(), 
                        moment().add(6, "hours"), 
                      ]}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="registrationStartdate"
                    label="Registration Start Date"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please select registration startdate",
                      },
                    ]}
                  >
                    <DatePicker
                      showTime
                      format="DD-MMM-YYYY HH:mm"
                      placeholder="Select startdate"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="registrationDeadline"
                    label="Registration Deadline"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please select registration deadline",
                      },
                    ]}
                  >
                    <DatePicker
                      showTime
                      format="DD-MMM-YYYY HH:mm"
                      placeholder="Select deadline"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="cutOffDate"
                    label="Cut-off Date"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please select cut-off date",
                      },
                    ]}
                  >
                    <DatePicker
                      showTime
                      format="DD-MMM-YYYY HH:mm"
                      placeholder="Select cut-off date"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="eventDescription"
                    label="Event Description"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter event description",
                      },
                    ]}
                  >
                    <ReactQuill style={{ height: "100px" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col span={24} lg={8} className="mb-24">
            <Card
              bodyStyle={{ paddingTop: "0px" }}
              className="header-solid"
              bordered={false}
              title={<h5 className="mb-0">Social Links</h5>}
            >
              <Form.Item
                name="eventWebsite"
                label="Event Website"
                colon={false}
                rules={[
                  { required: true, message: "Please enter website URL" },
                ]}
              >
                <Input placeholder="Enter website URL" />
              </Form.Item>
              <Form.Item
                name="twitterHandle"
                label="Twitter Handle"
                colon={false}
                rules={[
                  { required: true, message: "Please enter Twitter handle" },
                ]}
              >
                <Input placeholder="@karate_event" type="text" />
              </Form.Item>
              <Form.Item
                name="facebookPage"
                label="Facebook Page"
                colon={false}
                rules={[
                  { required: true, message: "Please enter Facebook page URL" },
                ]}
              >
                <Input placeholder="http://facebook.com/karate_event" />
              </Form.Item>
            </Card>
          </Col>
          <Col span={24} lg={16}>
            <Card
              className="header-solid"
              bordered={false}
              title={<h5 className="mb-0">Additional Information</h5>}
            >
              <Row gutter={[24, 0]}>
                <Col span={12}>
                  <Form.Item
                    name="state"
                    label="State"
                    colon={false}
                    rules={[
                      { required: true, message: "Please enter the state" },
                    ]}
                  >
                    <Input placeholder="Enter state" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="district"
                    label="District"
                    colon={false}
                    rules={[
                      { required: true, message: "Please enter the district" },
                    ]}
                  >
                    <Input placeholder="Enter district" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="city"
                    label="City"
                    colon={false}
                    rules={[
                      { required: true, message: "Please enter the city" },
                    ]}
                  >
                    <Input placeholder="Enter city" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="pinCode"
                    label="Pin Code"
                    colon={false}
                    rules={[
                      { required: true, message: "Please enter the pin code" },
                      // { type: "number", message: "Pin code must be a number" },
                    ]}
                  >
                    <Input placeholder="Enter pin code" type="number" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="Address"
                    colon={false}
                    rules={[
                      { required: true, message: "Please enter the address" },
                    ]}
                  >
                    <Input placeholder="Enter address" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="contactPersonName"
                    label="Contact Person Name"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact person name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter contact person name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contactPersonPhone"
                    label="Contact Person Phone"
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: "Please enter contact person phone",
                      },
                    ]}
                  >
                    <Input placeholder="Enter contact person phone" />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col
            span={24}
            lg={12}
            className="d-flex justify-content-end mt-24 mb-24"
          >
            <Form.Item>
              <button
                className="button-29"
                style={{ marginRight: "15px" }}
                onClick={() => history.push(`/pages/events/all-events`)}
              >
                Back
              </button>
            </Form.Item>
            <Form.Item>
              <Button className="button-29" type="primary" htmlType="submit">
                Create
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateEvent;
