import React, { useState, useEffect } from "react";

const StartTimeDrawer = () => {
  const [totalTime, setTotalTime] = useState(120000);
  
  const [remainingTime, setRemainingTime] = useState(Number(localStorage.getItem('remainingTime'))|| totalTime);
  const [isLoading,setIsLoading] =useState(localStorage.getItem('isRunning') === 'active'  ? true:false)
  const [startTime, setStartTime] = useState(null);
  const [isActive, setIsActive] = useState(localStorage.getItem('isRunning') === 'active' ?true:false);
  

  useEffect(() => {
    let timer;

    if (isActive) {
      // Start the timer if it is active
      let time = Number(localStorage.getItem("lastStopTime")) || Date.now();
      setStartTime(time);

      timer = setInterval(() => {

        const elapsedTime = Date.now() - startTime;
        console.log(elapsedTime);
        const newRemainingTime =
          totalTime -
          elapsedTime -
          Number(localStorage.getItem("spendTime") || 0);

        // Stop the timer if the remaining time reaches 0
        if (newRemainingTime <= 0) {
          clearInterval(timer);
          setRemainingTime(0);
          setIsActive(false);
        } else {
          setRemainingTime(newRemainingTime);
        }
        setIsLoading(false)

      }, 1000);
    }
    // Cleanup on unmount or when the timer is stopped
    return () => clearInterval(timer);
  }, [isActive, startTime, totalTime]);

  const startTimer = () => {
    localStorage.setItem("lastStopTime", Date.now());
    setIsActive(true);
    localStorage.setItem('isRunning','active') 
  };

  const resetTimer = () => {
    setIsActive(false);
    setRemainingTime(totalTime);
    localStorage.removeItem('spendTime')
    localStorage.removeItem('isRunning')
    localStorage.removeItem('remainingTime')
    localStorage.removeItem('lastStopTime')
  };

  const stopTimer = () => {
    setIsActive(false);
    localStorage.removeItem('isRunning') 
    localStorage.setItem('remainingTime', remainingTime)

    const currentTime = Date.now();
    localStorage.setItem("spendTime", currentTime - startTime);
    localStorage.setItem("lastStopTime", currentTime);
  };

  return (
    <div>
      <h1>Reverse Timer</h1>
   {!isLoading ?    <p>
        Remaining Time: {Math.max(0, Math.floor(remainingTime / 1000))} seconds
      </p> : 'Loading...'}
      <button onClick={startTimer} disabled={isActive}>
        Start Timer
      </button>
      <button onClick={stopTimer} disabled={!isActive}>
        Stop Timer
      </button>
      <button onClick={resetTimer}>Reset Timer</button>
    </div>
  );
};

export default StartTimeDrawer;
