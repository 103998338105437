import React from "react";
import ModalWrapper from "../../../../modal/ModalWrapper";
import { Button, Typography } from "antd";

const WinnerConfirmationModal = ({
  open,
  setOpen,
  decidedWinner,
  updateWinner,
}) => {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    updateWinner(decidedWinner?._id);
    setOpen(false);
  };

  return (
    <ModalWrapper
      title="Winner Confirmation"
      open={open}
      setOpen={setOpen}
      footer={null}
      width={600}
    >
      <Typography.Paragraph>
        Are you sure you want to confirm{" "}
        <strong>
          {decidedWinner?.firstName + " " + decidedWinner?.lastName}
        </strong>{" "}
        as the winner?
      </Typography.Paragraph>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default WinnerConfirmationModal;
