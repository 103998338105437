import { call, put, takeLatest } from 'redux-saga/effects';
import { SET_BREADCRUMB } from '../actionTypes/breadcrumb';
import { setBreadcrumb } from '../actions/bredcrumb';

const fetchBreadcrumbItems = (location) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const breadcrumbItems = ['Home', 'Category', 'Product']; 
      resolve({
        title: location === '/product' ? 'Product' : 'Category',
        subtitle: 'Details',
        url: location,
      });
    }, 1000); 
  });
};

function* handleSetBreadcrumb(action) {
  try {
    const breadcrumbItems = yield call(fetchBreadcrumbItems, action.payload.location);
    yield put(setBreadcrumb(breadcrumbItems));
  } catch (error) {
    console.error('Error fetching breadcrumb items', error);
  }
}

function* breadcrumbSaga() {
  yield takeLatest(SET_BREADCRUMB, handleSetBreadcrumb);
}

export default breadcrumbSaga;
