/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../../images/logo-ct-black.png";

const navLogo = (props) => {
  // console.log(props.role)
  return (
    <>
      <div className=" brand" style={{display:'flex',gap:'10px'}}>
        <div style={{height:'43px',width:'34px',lineHeight:'38px'}}>
        <img src={logo} alt="" />
        </div>
       
        <div>
        <div className="b-title" style={{textAlign:'center'}}>Karate Club</div>
        <div style={{textAlign:'center',fontSize:'13px'}}>{props?.role?.replace(/([a-z])([A-Z])/g, '$1 $2')}</div>
        </div>
      </div>
    </>
  );
};

export default navLogo;
