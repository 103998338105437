import { Table, Tag, Space, Avatar, Col, Row, Button, Skeleton, Empty, Input } from "antd";
import { Link, useHistory } from "react-router-dom";
import { path } from "../../../../utils/path";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import users from "../../../../services/users";
import { useEffect, useState } from "react";
import { notify, ShowTotal } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import { CustomUsersTable } from "../Styled";
const CoachOverview = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const getCoachs = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await users.getCoachs(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = (value) => {
    getCoachs(value);
  };


  useEffect(() => {
    getCoachs();
  }, [pagination?.size,pagination?.currentPage,sortingColumn,sortingOrder,searchText]);




  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>
                {item?.firstName?.[0]?.toUpperCase() +
                  item?.lastName?.[0]?.toUpperCase() || "-"}
              </div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            <Link
              // to={path().USER_DETAILS_STATE(item?._id)}
              to={`/users/coach/coach-overview/coach-details/${item?._id}`}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.firstName + " " + item?.lastName || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort:item?.firstName ? item?.firstName + " " + item?.lastName :"-",
      verification: (
        <Tag
          style={{
            background: `${
              item?.isVerified
                ? "#006A4E"
                : "rgba(255, 191, 0, 0.1)"
            } `,
            color: `${
              item?.isVerified ? "#ffffff" : "#FFBF00"
            } `,
            border: "none",
            borderRadius: "6px",
            padding: "0 10px",
            fontWeight: `${item?.isVerified ? 600 : 300}`,
          }}
        >
          {item?.isVerified ? "Verified" : "Pending"}
        </Tag>
      ),
      email: item?.email ? item?.email:"-",

      role: item?.role,

      createdAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.createdAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      updatedAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.updatedAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      action: (
        <div className="d-flex justify-content-center">
          <DeleteOutlined
            onClick={() => {
              //   setDeleteShow(true);
              //   setSelectedUserId(item?._id);
            }}
            type="danger"
          />
          {/* <EditOutlined
                className=""
                // onClick={() => history.push(path().UPDATE_USER(item?._id))}
                type="danger"
                style={{ cursor: "pointer" }}
              /> */}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
    },
    {
      title: "EMAIL",
      className: "ant-table-row-cell-break-word",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
      width: 200,
      align:'center',
    },
    {
      title: "VERIFIED",
      dataIndex: "verification",
      className: "ant-table-row-cell-break-word",
      align:'center',
    },
    // {
    //   title: "ROLE",
    //   dataIndex: "role",
    //   className: "ant-table-row-cell-break-word",
    // },

    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      //   align: "center",
      className: "ant-table-row-cell-break-word",
      align:'center',
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      className: "ant-table-row-cell-break-word",
      align:'center',
    },
    // {
    //   title: "ACTION",
    //   dataIndex: "action",
    //   className: "ant-table-row-cell-break-word",
    //   width: 100,
    //   //   align: "center",
    // },
  ];

  const handleClick = () => {
    history.push("/users/coach/coach-overview/create-coach?role=coach");
  };



  const deleteUsers = async () => {
    try {
      setLoading(true);
      const res = await users.deleteUsers({"users": selectedRowKeys});
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "User Deleted Successfully.");
      getCoachs();
      setLoading(false);
    } catch (err) {}
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };



  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(()=> {
    dispatch(
      setBreadcrumb({
        title: "All Coaches",
        subtitle: "",
        url: "",
      })
    );
  }, [])


  return (
    <Row>
      <Col
        span={24}
          className="header-control"
        style={{ padding: "10px", display: "flex", justifyContent: "space-between" }}
      >
        <Button type="primary" className="px-25" onClick={handleClick}>
          CREATE NEW COACH
        </Button>
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
      <CustomUsersTable>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          scroll={scroll}
          rowSelection={rowSelection}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {selectedRowKeys.length > 0 ? (
                <DeleteOutlined
                  onClick={deleteUsers}
                  style={{
                    fontSize: "18px",
                    color: selectedRowKeys.length > 0 ? "red" : "gray",
                    cursor:
                      selectedRowKeys.length > 0 ? "pointer" : "not-allowed",
                  }}
                  disabled={selectedRowKeys.length === 0}
                />
              ) : null}
            </div>
          )}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            total: count,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
      </CustomUsersTable>
      </Col>
    </Row>
  );
};

export default CoachOverview;
