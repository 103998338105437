import { apiDelete, apiGet, apiPatch, apiPost, apiPostWithMultiPart, apiPut } from "../utils/axios";
import { domainUrl } from "../utils/domainUrl";

export default {
     // for all expense 
    getExpenseList: ()=> apiGet(domainUrl(`/api/v1/expense/list`)),
    // for single expense details by expense id
    getExpenseById: (id)=> apiGet(domainUrl(`/api/v1/expense/${id}`)),
     // for expense details of an event by event id
    getEventExpenseById: (eventId)=> apiGet(domainUrl(`/api/v1/expense/event-expense/${eventId}`)),

    getTotalEventExpenses: (eventId)=> apiGet(domainUrl(`/api/v1/expense/total/event-expense/${eventId}`)),


    createExpense: (payload)=> apiPost(domainUrl(`/api/v1/expense/create`), payload),
    uploadAttachmentToS3: (id, payload)=> apiPostWithMultiPart(domainUrl(`/api/v1/expense/upload-attachment/${id}`), payload),
    downloadAttachment: (id, payload)=> apiPost(domainUrl(`/api/v1/expense/download-attachment/${id}`), payload),


    deleteExpenses: (payload)=> apiDelete(domainUrl(`/api/v1/expense/delete`), payload),


    updateExpense: (id, payload)=> apiPut(domainUrl(`/api/v1/expense/update/${id}`), payload),


}