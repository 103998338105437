import axios from 'axios';
import { notify } from '../utils/helper';
 
axios.interceptors.response.use(null, (error) => {
  if (error && error.response) {
    if (error.response.status === 403) {
      if (error.response.config.method === 'get') {
        return (window.location.href = '/not-found');
      }
      if (
        error.response?.data?.message ===
        'Your account has been suspended. please contact support team.'
      ) {
        notify('Access Denied', 'Your account has been suspended, please contact support team.');
 
      } else {
        notify('error', 'Access Denied');
      }
    }
 
    if (
      error.response.status === 401 &&
      error.response?.data?.message === 'Unauthorized session'
    ) {
      localStorage.removeItem('accessToken');
      window.location.href = '/';
      // initial call to whoami will get intercepted here and redirect, need to throw log event
      // TODO: Do something
    }
 
    if (
      error.response.status === 401 &&
      error.response?.data?.error?.name === 'TokenExpiredError'
    ) {
      localStorage.removeItem('accessToken');
      window.location.href = '/';
      // initial call to whoami will get intercepted here and redirect, need to throw log event
      // TODO: Do something
    }
    // const { data } = error.response;
    // const errorPayload =
    //   data.error ||
    //   data.errors ||
    //   data.error_message ||
    //   data.error_type ||
    //   data.message;
 
    return Promise.reject(error.response);
  }
 
  return Promise.reject(error);
});
/**
*
* @param {*} path api endpoint
* @returns data
*/
export function apiGet(path) {
  const accessToken = localStorage.getItem('accessToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
 
  return axios.get(path, config);
}
/**
*
* @param {*} path api endpoint
* @param {*} data body object for post request
* @returns
*/
export function apiPost(path, data, responseType) {
  const accessToken = localStorage.getItem('accessToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
 
  if (responseType) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        type: "buffer",

      },
      responseType: 'arraybuffer',
    };
  }

 
  return axios.post(path, data, config);
}
 
export function apiPostWithMultiPart(path, data, responseType) {
  const accessToken = localStorage.getItem('accessToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  }
 
  if (responseType) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
    };
  }
 
  return axios.post(path, data, config);
}
/**
*
* @param {*} path api endpoint
* @param {*} data  body object for path request
* @param {*} headers  header
* @returns
*/
export function apiPatch(path, data, headers = {}) {
  const accessToken = localStorage.getItem('accessToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        ...headers,
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    };
  }
 
  return axios.patch(path, data, config);
}
/**
*
* @param {*} path api endpoint
* @param {*} data body object for put request
* @returns
*/
 
export function apiPut(path, data) {
  const accessToken = localStorage.getItem('accessToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
 
  return axios.put(path, data, config);
}
/**
*
* @param {*} path api endpoint
* @param {*} data data for params
* @returns
*/
export function apiDelete(path, data) {
  const accessToken = localStorage.getItem('accessToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      data,
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    };
  }
 
  return axios.delete(path, { ...config });
}
 
export function apiGetWithCustomToken(path) {
  const accessToken = localStorage.getItem('ipToken');
  let config;
  if (accessToken) {
    config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    };
  } else {
    config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }
 
  return axios.get(path, config);
}
 
const instance = axios.create();
// axios.defaults.headers.common['CLIENT_IP'] = localStorage.getItem('myIp');
// axios.defaults.headers.common['CLIENT_LOCATION'] =
//   localStorage.getItem('location');
 
export default instance;