import {
  Table,
  Tag,
  Space,
  Avatar,
  Col,
  Skeleton,
  Empty,
  Button,
  Row,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { path } from "../../../../utils/path";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";
import { CustomColUser, CustomUsersTable } from "../Styled";
import users from "../../../../services/users";
import { useEffect, useState } from "react";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import { useDispatch } from "react-redux";

const StateOverview = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const getStateHeads = async () => {
    try {
      setLoading(true);
      let res = await users.getStateHeads();
      setApiData(res?.data?.response?.records);
      setLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    getStateHeads();
  }, []);

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>
                {item?.firstName?.[0]?.toUpperCase() +
                  item?.lastName?.[0]?.toUpperCase() || "-"}
              </div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            <Link
              to={path().USER_DETAILS_STATE(item?._id)}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.firstName + " " + item?.lastName || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort:item?.firstName ? item?.firstName + " " + item?.lastName : "-",
      verification: (
        <Tag
          style={{
            background: `${
              item?.isVerified
                ? "#006A4E"
                : "rgba(255, 191, 0, 0.1)"
            } `,
            color: `${
              item?.isVerified ? "#ffffff" : "#FFBF00"
            } `,
            border: "none",
            borderRadius: "6px",
            padding: "0 10px",
            fontWeight: `${item?.isVerified ? 600 : 300}`,
          }}
        >
          {item?.isVerified ? "Verified" : "Pending"}
        </Tag>
      ),
     
      email: item?.email ?item?.email:"-",

      role: item?.role,
      createdAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.createdAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      updatedAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.updatedAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      action: (
        <div className="d-flex justify-content-center">
          <DeleteOutlined
            onClick={() => {
              //   setDeleteShow(true);
              //   setSelectedUserId(item?._id);
            }}
            type="danger"
          />
          {/* <EditOutlined
            className=""
            // onClick={() => history.push(path().UPDATE_USER(item?._id))}
            type="danger"
            style={{ cursor: "pointer" }}
          /> */}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
    },
    {
      title: "EMAIL",
      className: "ant-table-row-cell-break-word",
      dataIndex: "email",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
    },
    {
      title: "VERIFIED",
      dataIndex: "verification",
      className: "ant-table-row-cell-break-word",
    },
    // {
    //   title: "ROLE",
    //   dataIndex: "role",
    //   className: "ant-table-row-cell-break-word",
    // },

    {
      title: "CREATED AT",
      dataIndex: "createdAt",
        align: "center",
      className: "ant-table-row-cell-break-word",
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      align: "center",
      className: "ant-table-row-cell-break-word",
    },
    // {
    //   title: "ACTION",
    //   dataIndex: "action",
    //   className: "ant-table-row-cell-break-word",
    //   width: 100,
    //   //   align: "center",
    // },
  ];
  const handleClick = () => {
    history.push("/users/state/create-state-head?role=stateHead");
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(()=> {
    dispatch(
      setBreadcrumb({
        title: "State Heads",
        subtitle: "",
        url: "",
      })
    );
  }, [])


  return (
    <Row>
      <Col
        span={24}
        style={{ padding: "10px", display: "flex", justifyContent: "end" }}
      >
        <Button type="primary" className="px-25" onClick={handleClick}>
          CREATE NEW STATE HEAD
        </Button>
      </Col>

      <Col span={24}>
     < CustomUsersTable>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          scroll={scroll}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
        />
        </CustomUsersTable>
      </Col>
    </Row>
  );
};

export default StateOverview;
