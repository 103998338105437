import {
  CodeFilled,
  AppstoreFilled,
  DollarCircleFilled,
  LockFilled,
  ReadFilled,
  RocketFilled,
  SwitcherFilled,
  DashboardFilled,
} from "@ant-design/icons";
import { extractToken } from "./localStorage";

const user = extractToken();

export const roleAccess = (role, accessRole) => {
  if (accessRole?.includes(role)) return true;
  else return false;
};
//  "stateAdmin", "districtHead", "districtAdmin", "coach","student"
const menuItems = {
  items: [
    {
      id: "null0",
      title: "",
      className: "d-none",
      type: "group",
      visible: false,
      children: [
        {
          id: "das",
          title: <span className="label">Dashboard</span>,
          type: "collapse",
          visible: roleAccess(user?.role, []),
          icon: (isSelected, color) => (
            <DashboardFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "default",
              title: "Default",
              type: "item",
              url: "/dashboard",
              visible: false,
            },
            {
              id: "crm",
              title: "CRM",
              type: "item",
              url: "/dashboard/crm",
              visible: false,
            },
          ],
        },
      ],
    },
    {
      id: "institutes-0",
      title: "INSTITUTES",
      type: "group",
      visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin"]),
      children: [
        {
          id: "students",
          title: <span className="label">Institute</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <CodeFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "institute",
              title: "Institute",
              type: "item",
              url: "/students/institute",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin"]),
            },
          ],
        },
      ],
    },
    {
      id: "user",
      title: "USERS",
      type: "group",
      visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "coach"]),
      children: [
        {
          id: "users",
          title: <span className="label">Users</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <CodeFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "state",
              title: "State",
              type: "collapse",
              children: [
                {
                  id: "state-head",
                  title: "State Head",
                  type: "item",
                  url: "/users/state/state-head",
                  visible: roleAccess(user?.role, ["stateHead"]),
                },
                {
                  id: "state-admin",
                  title: "State Admin",
                  type: "item",
                  url: "/users/state/state-admin",
                  visible: roleAccess(user?.role, ["stateHead"]),
                },
              ],
            },
            {
              id: "district",
              title: "District",
              type: "collapse",
              children: [
                {
                  id: "district-head",
                  title: "District Head",
                  type: "item",
                  url: "/users/district/district-head",
                  visible: roleAccess(user?.role, ["stateHead", "stateAdmin"]),
                },
                {
                  id: "district-admin",
                  title: "District Admin",
                  type: "item",
                  url: "/users/district/district-admin",
                  visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead"]),
                },
              ],
            },
            {
              id: "moderator",
              title: "Moderator",
              type: "item",
              url: "/users/moderator/moderator-overview",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin"]),
            },
            {
              id: "judge",
              title: "Judge",
              type: "item",
              url: "/users/moderator/judge-overview",
              visible: roleAccess(user?.role, ["moderator","stateHead","districtHead"]),
            },
            {
              id: "referee",
              title: "Referee",
              type: "item",
              url: "/users/moderator/referee-overview",
              visible: roleAccess(user?.role, ["moderator","stateHead","districtHead"]),
            },
            {
              id: "coach",
              title: "Coach",
              type: "item",
              url: "/users/coach/coach-overview",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin"]),
            },
            {
              id: "student",
              title: "Players",
              type: "item",
              url: "/users/students/students-overview",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "coach"]),
            },
          ],
        },
      ],
    },
    {
      id: "event",
      title: "EVENTS",
      type: "group",
      visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "coach", "student", "moderator"]),
      children: [
        {
          id: "events",
          title: <span className="label">Events</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <CodeFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "all-events",
              title: "All Events",
              type: "item",
              url: "/pages/events/all-events",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "coach"]),
            },
            {
              id: "new-event",
              title: "Upcoming Event",
              type: "item",
              url: "/pages/events/new-event",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "coach", "student"]),
            },
            {
              id: "active-events",
              title: "Active Events",
              type: "item",
              url: "/pages/events/active-events",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "coach", "student"]),
            },
            {
              id: "categories",
              title: "Categories",
              type: "item",
              url: "/pages/events/categories",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin"]),
            },

            {
              id: "tatami",
              title: "Tatami",
              type: "item",
              url: "/pages/events/tatami",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin", "moderator"]),
            },
            {

              id: "expense",
              title: "Expenses",
              type: "item",
              url: "/pages/events/expense",
              visible: roleAccess(user?.role, ["stateHead", "stateAdmin", "districtHead", "districtAdmin"]),
            },

          ],
        },
      ],
    },
    {
      id: "profile-overview",
      title: "Profile Overview",
      type: "item",
      url: "/pages/profile/profile-overview",
      visible: false,
    },
    {
      id: "page",
      title: "PAGES",
      type: "group",
      // visible: true,
      children: [
        {
          id: "pages",
          title: <span className="label">Pages</span>,
          type: "collapse",
          icon: (isSelected, color) => (
            <CodeFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "profile",
              title: "Profile",
              type: "collapse",
              children: [
                {
                  id: "profile-overview",
                  title: "Profile Overview",
                  type: "item",
                  url: "/pages/profile/profile-overview",
                  // visible: true,
                },
                {
                  id: "all-projects",
                  title: "All Projects",
                  type: "item",
                  url: "/pages/profile/all-projects",
                  // visible: true,
                },
              ],
            },
            {
              id: "users1",
              title: "Users",
              type: "collapse",
              // visible: true,
              children: [
                {
                  id: "new-user",
                  title: "New User",
                  type: "item",
                  url: "/pages/users/new-user",
                  // visible: true,
                },
              ],
            },
            {
              id: "account",
              title: "Account",
              type: "collapse",
              children: [
                {
                  id: "settings",
                  title: "Settings",
                  type: "item",
                  url: "/pages/account/settings",
                  // visible: true,
                },
                {
                  id: "billing",
                  title: "Billing",
                  type: "item",
                  url: "/pages/account/billing",
                  // visible: true,
                },
                {
                  id: "invoice",
                  title: "Invoice",
                  type: "item",
                  url: "/pages/account/invoice",
                  // visible: true,
                },
              ],
            },
            {
              id: "projects",
              title: "Projects",
              type: "collapse",
              children: [
                {
                  id: "timeline",
                  title: "Timeline",
                  type: "item",
                  url: "/pages/projects/timeline",
                  // visible: true,
                },
              ],
            },
            {
              id: "pricing",
              title: "Pricing",
              type: "item",
              url: "/pages/pricing",
              // visible: true,
            },
            {
              id: "rtl",
              title: "RTL",
              type: "item",
              url: "/pages/rtl",
              // visible: true,
            },
            {
              id: "charts",
              title: "Charts",
              type: "item",
              url: "/pages/charts",
              // visible: true,
            },
            {
              id: "alerts",
              title: "Alerts",
              type: "item",
              url: "/pages/alerts",
              // visible: true,
            },
            {
              id: "notifications",
              title: "Notifications",
              type: "item",
              url: "/pages/notifications",
              // visible: true,
            },
          ],
        },
        {
          id: "applications",
          title: <span className="label">Applications</span>,
          type: "collapse",
          // visible: true,
          icon: (isSelected, color) => (
            <AppstoreFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "kanban",
              title: "Kanban",
              type: "item",
              url: "/applications/kanban",
              // visible: true,
            },
            {
              id: "wizard",
              title: "Wizard",
              type: "item",
              url: "/applications/wizard",
              // visible: true,
            },
            {
              id: "data-table",
              title: "DataTable",
              type: "item",
              url: "/applications/data-table",
              // visible: true,
            },
            {
              id: "calendar",
              title: "Calendar",
              type: "item",
              url: "/applications/calendar",
              // visible: true,
            },
          ],
        },
        {
          id: "ecommerce",
          title: <span className="label">Ecommerce</span>,
          type: "collapse",
          // visible: true,
          icon: (isSelected, color) => (
            <DollarCircleFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "products",
              title: "Products",
              type: "collapse",
              // visible: true,
              children: [
                {
                  id: "new-product",
                  title: "New Product",

                  type: "item",
                  url: "/ecommerce/products/new-product",
                  // visible: true,
                },
                {
                  id: "edit-product",
                  title: "Edit Product",

                  type: "item",
                  url: "/ecommerce/products/edit-product",
                  // visible: true,
                },
                {
                  id: "product-page",
                  title: "Product Page",

                  type: "item",
                  url: "/ecommerce/products/product-page",
                  // visible: true,
                },
              ],
            },
            {
              id: "orders",
              title: "Orders",
              type: "collapse",
              // visible: true,
              children: [
                {
                  id: "order-list",
                  title: "Order List",

                  type: "item",
                  url: "/ecommerce/orders/order-list",
                  // visible: true,
                },
                {
                  id: "order-details",
                  title: "Order Details",
                  type: "item",
                  url: "/ecommerce/orders/order-details",
                  // visible: true,
                },
              ],
            },
          ],
        },
        {
          id: "authentication",
          title: <span className="label">Authentication</span>,
          type: "collapse",
          // visible: true,
          icon: (isSelected, color) => (
            <LockFilled
              className="icon"
              style={{
                background: isSelected ? color : "",
              }}
            />
          ),
          children: [
            {
              id: "sign-up",
              title: "Sign Up",
              type: "collapse",
              children: [
                {
                  id: "basic",
                  title: "Basic",
                  type: "item",
                  url: "/authentication/sign-up/basic",
                  // visible: true,
                },
                {
                  id: "cover",
                  title: "Cover",
                  type: "item",
                  url: "/authentication/sign-up/cover",
                  // visible: true,
                },
                {
                  id: "illustration",
                  title: "Illustration",
                  type: "item",
                  url: "/authentication/sign-up/illustration",
                  // visible: true,
                },
              ],
            },
          ],
        },
      ],
    },
    // {
    //   id: "docs",
    //   title: "DOCS",
    //   type: "group",
    //   children: [
    //     {
    //       id: "getting-started",
    //       title: "Getting Started",
    //       type: "item",
    //       icon: (isSelected, color) => (
    //         <ReadFilled
    //           className="icon"
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       ),
    //       url: "http://digimonk.net:1618/overview",
    //       target: "_blank",
    //     },
    //     {
    //       id: "components",
    //       title: "Components",
    //       type: "item",
    //       icon: (isSelected, color) => (
    //         <RocketFilled
    //           className="icon"
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       ),
    //       url: "http://digimonk.net:1618/alert",
    //       target: "_blank",
    //     },
    //     {
    //       id: "changelog",
    //       title: "Changelog",
    //       type: "item",
    //       icon: (isSelected, color) => (
    //         <SwitcherFilled
    //           className="icon "
    //           style={{
    //             background: isSelected ? color : "",
    //           }}
    //         />
    //       ),
    //       url: "https://github.com/digimonktech/musedocs",
    //       target: "_blank",
    //     },
    //   ],
    // },
  ],
};

export default menuItems;
