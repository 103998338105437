import { Table, Tag, Space } from 'antd';
import { Link } from "react-router-dom";
import { path } from '../../../utils/path';
const UsersManagement = () => {
  const apiData = [
    {
      id: "1",
      key: "1",
      name: "John Deo",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: [" tag",],
    },
    {
      id: "2",
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: [" tag",],
    },
    {
      id: "3",
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
      tags: [" tag",],
    },
  ];
  let data = [];
  for (let item of apiData) {
    data.push({
      id: item?.id,
      key: item?.id,
      name: (
        <div className="" style={{ minWidth: "200px", maxWidth: "200px" }}>
          <Link
            // to={path().USER_DETAILS_SUPERADMIN(item?.id)}
            style={{ cursor: "pointer" }}
            className="ellipsis"
          >
            <div
              className="cus-name ellipsis table-data-color"
              style={{
                lineHeight: "14px",
              }}
            >
              {item?.name}
            </div>
       
          </Link>
        </div>
      ),
      age: item?.age,
      address: item?.address,
      tags: item?.tags,
    });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <a>Invite {record.name}</a>
          <a>Delete</a>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={data} />
    </div>
  );
};

export default UsersManagement;
