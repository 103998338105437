import { Row,Col,Card } from "antd";
import styled from "styled-components";

export const SuperWrapper = styled.div`
  /* font-family: poppins; */

  .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 0px 11px;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 1.5715;
    background-color: rgb(26, 26, 26);
    background-image: none;
    border: none;
    border-radius: 6px;
    transition: all 0.3s;
    display: inline-flex;
  }

  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 1.5715;
    background-color: rgb(26, 26, 26);
    background-image: none;
    border: none;
    transition: all 0.3s;
    height: 45px;
  }

  .ant-input-group-addon {
    position: relative;
    padding: 0 0 0 11px;
    color: var(--text-primary);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: rgb(26, 26, 26);
    border: none;
    border-radius: 0px;
    /* border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; */
    transition: all 0.3s;
  }

  .ant-segmented-item,
  .ant-segmented-item-label {
    padding: 0 13px !important;
  }

  .ant-segmented-item:hover,
  .ant-segmented-item:focus {
    color: var(--individual-button);
  }

  .ant-segmented-item-selected {
    background-color: var(--individual-button);
    border-radius: 4px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05),
      0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    color: white;
  }
  .ant-segmented-item-selected:hover {
    background-color: var(--individual-button);
    border-radius: 4px;
    box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05),
      0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
    color: white;
  }
  .ant-segmented {
    box-sizing: border-box;
    margin: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    display: inline-block;
    padding: 4px;
    color: var(--text-primary);
    background-color: var(--dashboard-primay);
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-segmented:hover {
    padding: 4px;
    color: var(--text-primary);
    background-color: var(--dashboard-primay);
    border-radius: 8px;
  }

  .main-heading {
    font-weight: 600;
    font-size: 30px;
    color: #000000;
    line-height: 40px;
  }

  .sub-heading {
    color: #aaaaaa;
    font-size: 16px;
    font-weight: 400;
  }

  .main-2-heading {
    font-size: 22px;
    font-weight: 500;
    color: var(--text-primary);
    line-height: 30px;
  }

  .mini-2-heading {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-form-label);
    line-height: 24px;
  }

  .counterBox {
    .ant-input {
      border-radius: 7px;
      padding: 0px !important;
      height: 32px;
      width: 45px;
      background-color: rgb(26, 26, 26);
      color: white !important;
      font-weight: 500 !important;
      font-size: 18px !important;
      box-shadow: none;
    }
    .centered-input {
      text-align: center;
    }
  }

  .ant-input-number-affix-wrapper:not(
      .ant-input-number-affix-wrapper-disabled
    ):hover {
    border-color: green;
  }

  .ant-input-show-count-suffix {
    color: #cccccc;
  }

  .ant-input-number-affix-wrapper:hover {
    /* box-shadow: 0 0 0 1px green; */
  }

  .ant-input-number-affix-wrapper-focused {
    box-shadow: 0 0 0 1px green;
  }

  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    padding: 0;
    background-color: rgb(26, 26, 26);
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    height: 45px;
    align-items: center;
    background-color: var(--dashboard-secondary);
    border: none;
  }
  .TransAndAct {
    margin: 0px;
  }

  .ant-input-textarea-show-count::after {
    color: #ccc;
  }
  .ant-picker-status-error.ant-picker,
  .ant-picker-status-error.ant-picker:not([disabled]):hover {
    background-color: #1a1a1a;
  }

  .superadmin-btn-primary {
    background-color: #6c72ff;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    line-height: 1.167em;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    border-style: none;
  }
  .superadmin-btn-primary:hover {
    background-color: #212c4d;
    color: #ffffff;
  }

  .superadmin-btn-secondary {
    background-color: #212c4d;
    color: #ffffff;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    line-height: 1.167em;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    border-style: none;
  }
  .superadmin-btn-secondary:hover {
    background-color: #6c72ff;
    color: #ffffff;
  }

  .status-badge {
    grid-column-gap: 2px;
    border: 0.6px solid rgba(0, 0, 0, 0);
    border-radius: 2px;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    display: flex;
  }
  .status-badge.green {
    font-size: 10px;
    color: #14ca74;
    background-color: rgba(5, 193, 104, 0.2);
    border-color: rgba(5, 193, 104, 0.2);
  }
  .status-badge.red {
    font-size: 10px;
    color: #ff5a65;
    background-color: rgba(255, 90, 101, 0.2);
    border-color: rgba(255, 90, 101, 0.2);
  }
  .small-dot {
    max-height: 8px;
    max-width: 8px;
    min-height: 8px;
    min-width: 8px;
    background-color: #6c72ff;
    border-radius: 100%;
  }
  .select-open .ant-select-arrow {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }

  .ant-select-arrow {
    transition: transform 0.3s ease;
  }
  .ant-picker {
    border: 1px solid var(--individual-button);
    border-radius: 6px;
    /* width: 200px; */
    height: 30px;
  }
  .highcharts-legend-item {
    /* border:1px solid var(--individual-button); */
  }
`;

export const ViewProfileWrapper = styled(Row)`
  .all-users-card {
    background: #ffffff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
  }
  .all-users-card-header {
    color: #000000;
    font-size: 16px;
    font-weight: 700;
    height: 28px;
    text-align: center;
    margin-top: 15px;
    width: 100%;
  }
  .all-users-card-searchbox {
    border-bottom: 1px solid #d9d9d9;
    .input-box {
      border: 1px solid #d9d9d9;
      width: 100%;
      border-radius: 8px;
      display: flex;
      margin-bottom: 12px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
    }
  }
  .user-card{
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
      border-radius: 6px;
  }
  .user-info-card {
 
    .user-info-details-container {
      padding-left: 0px;
      padding-right: 0px;
      height: 42%;
      background-image: linear-gradient(
          red 0%,
          blue 100%
        ),
        url(/unsplash_5VJLeQ-TrKs.png);
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
  .transactions-card {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
    .transactions-card-header {
      color: var(--text-primary);
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      line-height: 44px;
      height: 44px;
      background: var(--dashboard-primay);
      margin-bottom: 10px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
`;

export const BlackHeadCardUser = styled(Card)`
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;

  .ant-card-bordered {
    border: none !important;
  }
  .ant-card-body {
    color: var(--text-primary);
    background: var(--dashboard-secondary);
  }
  .ant-card-head {
    color: var(--text-primary);
    background-color: var(--dashboard-primay);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-card-head-title {
    padding: 0px;
    text-align: center;
    color: var(--text-primary);
  }
  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0px;
    padding-bottom: 5px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }

  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 1.5715;
    background-color: var(--dashboard-primay);
    background-image: none;
    border: none;
    border-radius: 6px;
    transition: all 0.3s;
    height: 45px;
  }
  .ant-card-body {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .ant-card-head-wrapper {
    width: 100%;
  }
`;

export const CustomerCard = styled(Card)`
  background: #ffffff;
  border-radius: 10px;

  .text {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: #ffffff;
  }
  &:hover {
    background-color: #eeeeee;
    /* color: #fff; */
    cursor: pointer;
  }

`;

export const CustomUsersTable = styled.div`
  .ant-table {
    /* border: 1px solid red; */
    .ant-table-body {
      min-height: calc(
        -325px + 100vh
      ); // gives the minimum height to the table body
    }
  }
  // table header -titles
  .ant-table-thead > tr > th {
    color: #8c8c8c;
    font-weight: 700;
    background-color: transparent;
    font-size: 13px;
    padding: 16px 6px;
  }
  // table body
  .ant-table-tbody > tr > td {
    padding: 16px 6px;
    /* color: #000000; */
    font-size: 14px;
    /* font-weight: 700; */
  }
  // table page-size dropdown
  .ant-pagination-options {
    .ant-select {
      font-weight: 600;
      .ant-select-selector {
        .ant-select-single:not(.ant-select-customize-input)
          .ant-select-selector {
          height: 30px !important;
        }
        .ant-select-selection-item {
        }
      }
    }
  }

`;
