import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import breadcrumbReducer from './reducers/bredcrumb';
import breadcrumbSaga from './sagas/breadcrumb';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['breadcrumb'], 

}

// const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  breadcrumb: breadcrumbReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  // applyMiddleware(sagaMiddleware)
);

// sagaMiddleware.run(breadcrumbSaga);

export const persistor = persistStore(store);

export default store;
