import {
  Table,
  Col,
  Tag,
  Avatar,
  Row,
  Button,
  Skeleton,
  Empty,
  Input,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { path } from "../../../../utils/path";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import users from "../../../../services/users";
import institute from "../../../../services/institute";
import { notify, ShowTotal } from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import { CustomUsersTable } from "../Styled";

const JudgeOverview = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("moderator");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();


  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const getJudges = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await users.getJudges(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = (value) => {
    getJudges(value);
  };

  useEffect(() => {
    // getModerators();
    getJudges();
  }, [pagination?.size,pagination?.currentPage,sortingColumn,sortingOrder,searchText]);



  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            minWidth: "180px",
            maxWidth: "180",
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>
                {item?.firstName?.[0]?.toUpperCase() +
                  item?.lastName?.[0]?.toUpperCase() || "-"}
              </div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ minWidth: "160px", display: "flex", alignItems: "center" }}
          >
            <Link
              to={path().JUDGE_DETAILS(item?._id)}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.firstName + " " + item?.lastName || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      verification: (
        <Tag
          style={{
            background: `${
              item?.isVerified
                ? "var(--dashboard-primay)"
                : "rgba(255, 191, 0, 0.1)"
            } `,
            color: `${
              item?.isVerified ? "var(--individual-button)" : "#FFBF00"
            } `,
            border: "none",
            borderRadius: "6px",
            padding: "0 10px",
            fontWeight: `${item?.isVerified ? 600 : 300}`,
          }}
        >
          {item?.isVerified ? "Verified" : "Pending"}
        </Tag>
      ),
      email: item?.email,

      role: item?.role,
      class: item?.class ? item?.class : "-",
      age: item?.age ? item?.age + " Years" : "-",
      weight: item?.weight ? item?.weight + " kg" : "-",
      coach: item?.instituteId?.coach?.firstName
        ? `${item?.instituteId?.coach?.firstName} ${item?.instituteId?.coach?.lastName}`
        : "-",
      institute: item?.instituteId?.name ? item?.instituteId?.name : "-",
      dateOfBirth: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.dateOfBirth).format("DD MMM YYYY") || "-"}
        </div>
      ),
      createdAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.createdAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      updatedAt: (
        <div style={{ color: "var(--text-form-label)" }}>
          {moment(item?.updatedAt).format("DD MMM YYYY hh:mm:ss A") || "-"}
        </div>
      ),

      action: (
        <div className="d-flex justify-content-center">
          <DeleteOutlined
            onClick={() => {
              //   setDeleteShow(true);
              //   setSelectedUserId(item?._id);
            
              // deleteUsers(item?._id);
            }}
            type="danger"
          />
          {/* <EditOutlined
                      className=""
                      // onClick={() => history.push(path().UPDATE_USER(item?._id))}
                      type="danger"
                      style={{ cursor: "pointer" }}
                    /> */}
        </div>
      ),
    });
  }
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      width: 120,
      sorter: {
        compare: (a, b) => a.name - b.name,
        multiple: 3,
      },
    },
    {
      title: "EMAIL",
      className: "ant-table-row-cell-break-word",
      dataIndex: "email",
      width: 120,
      sorter: {
        compare: (a, b) => a.email - b.email,
        multiple: 2,
      },
      align: "center",
    },

    //   {
    //     title: "VERIFIED",
    //     dataIndex: "verification",
    //     className: "ant-table-row-cell-break-word",
    //   },
    //   {
    //     title: "ROLE",
    //     dataIndex: "role",
    //     className: "ant-table-row-cell-break-word",
    //   },

    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      align: "center",
      width: 150,
      // className: "ant-table-row-cell-break-word",
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      width: 150,
      // className: "ant-table-row-cell-break-word",
    },
  ];

  const handleClick = () => {
    history.push("/users/moderator/judge-overview/create-judge");
  };

  const deleteUsers = async () => {
    try {
      setLoading(true);
      //   const res = await users.deleteUsers({"users": selectedRowKeys});
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "User Deleted Successfully.");
      //   getStudents();
      setLoading(false);
    } catch (err) {}
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;

  useEffect(() => {
      dispatch(
        setBreadcrumb({
          title: "All Judges",
          subtitle: "",
          url: "",
        })
      );
  }, []);

  return (
    <Row>
      <Col
        span={24}
        className="header-control"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button type="primary" className="px-25" onClick={handleClick}>
          CREATE NEW JUDGE
        </Button>
        <Input
          className="header-search"
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          style={{ width: "300px" }}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Col>
      <Col span={24}>
      <CustomUsersTable>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          scroll={scroll}
          rowSelection={rowSelection}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {selectedRowKeys.length > 0 ? (
                <DeleteOutlined
                  onClick={deleteUsers}
                  style={{
                    fontSize: "18px",
                    color: selectedRowKeys.length > 0 ? "red" : "gray",
                    cursor:
                      selectedRowKeys.length > 0 ? "pointer" : "not-allowed",
                  }}
                  disabled={selectedRowKeys.length === 0}
                />
              ) : null}
            </div>
          )}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            total: count,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
        </CustomUsersTable>
      </Col>
    </Row>
  );
};

export default JudgeOverview;
