import React from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Slider,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import moment from "moment";
import { notify } from "../../../../utils/helper";
import event from "../../../../services/event";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
const CreateTatami = () => {
  const [allEvents, setAllEvents] = useState([]);
  const [id, setId] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const types = [
    { label: "Kata", value: "kata" },
    { label: "Kumite", value: "kumite" },
  ];

  const getAllEvents = async () => {
    try {
      const res = await event.getAllEvent();
      setAllEvents(res?.data?.response?.records);
    } catch (err) {}
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const createTatami = async (values) => {
    try {
      const res = await event.createTatami(values);
      notify("success", "Tatami Created Successfully.");
      history.goBack();
    } catch (err) {}
  };

  useEffect(() => {
    dispatch(
      setBreadcrumb({
        title: "Tatami",
        subtitle: "Create New Tatami",
        url: "",
      })
    );
  }, []);

  return (
    <Card title={<h4>Create Tatami</h4>}>
      <Form onFinish={createTatami} layout="vertical">
        <Row gutter={[24, 24]}>
          <Col span={24} className="m-2"></Col>

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Name</h4>

            <Form.Item
              style={{ marginBottom: 10 }}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Name is required!",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>

          <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Event</h4>

            <Form.Item
              style={{ margin: 0 }}
              name="event"
              rules={[
                {
                  required: true,
                  message: "Event is required!",
                },
              ]}
            >
              <Select
                placeholder="Select an event"
                value={id}
                onChange={(e) => setId(e)}
              >
                {allEvents.map((event) => (
                  <Select.Option key={event._id} value={event._id}>
                    {event?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col sm={24} md={24} lg={12} span={12}>
            <h4 className="list-header text-m text-muted m-0">Address</h4>

            <Form.Item
              style={{ margin: 0 }}
              name="address"
              rules={[
                {
                  required: true,
                  message: "Address is required!",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter address" rows={4} />
            </Form.Item>
          </Col> */}

          <Col span={24} className="m-3"></Col>
          <Col
            span={24}
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              className="px-25"
              onClick={() => history.goBack()}
            >
              CANCEL
            </Button>
            <Button type="primary" className="px-25" htmlType="submit">
              CREATE TATAMI
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CreateTatami;
