import { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Space,
  Avatar,
  Col,
  Row,
  Button,
  Skeleton,
  Empty,
  Input,
} from "antd";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { path } from "../../../../utils/path";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import users from "../../../../services/users";
import institute from "../../../../services/institute";
import { notify, ShowTotal } from "../../../../utils/helper";
import UploadStudentsModal from "./UploadStudentsModal";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../../../store/actions/bredcrumb";
import { CustomUsersTable } from "../Styled";

const StudentOverview = () => {
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("student");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ size: 10, currentPage: 1 });
  const [count, setCount] = useState("");
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingOrder, setSortingOrder] = useState("");
  const [searchText, setSearchText] = useState("");
  const [openUploadStudentsModal, setOpenUploadStudentsModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch()

  const getStudents = async (searchText = "") => {
    try {
      setLoading(true);
      let res = await users.getStudents(
        pagination?.size,
        pagination?.currentPage,
        sortingColumn,
        sortingOrder,
        searchText
      );
      setApiData(res?.data?.response?.records);
      setCount(res?.data?.response?.totalItems);
      setLoading(false);
    } catch (err) {}
  };

  const handleSearch = (value) => {
    getStudents(value);
  };

  useEffect(() => {
    getStudents();
  }, [
    pagination?.size,
    pagination?.currentPage,
    sortingColumn,
    sortingOrder,
    searchText,
  ]);



  const deleteUsers = async () => {
    try {
      setLoading(true);
      const res = await users.deleteUsers({ users: selectedRowKeys });
      setLoading(true);
      setSelectedRowKeys([]);
      notify("success", "User Deleted Successfully.");
      getStudents();
      setLoading(false);
    } catch (err) {}
  };

  let data = [];
  for (let item of apiData) {
    data.push({
      key: item?._id,
      name: (
        <div
          style={{
            display: "flex",
            gap: "5px",
          }}
        >
          <div>
            <Avatar
              shape="square"
              size={40}
              style={{
                // backgroundColor: getRandomColor(),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
              }}
              src={item?.profilePicture}
            >
              <div>
                {item?.firstName?.[0]?.toUpperCase() +
                  item?.lastName?.[0]?.toUpperCase() || "-"}
              </div>
            </Avatar>
          </div>

          <div
            className="mx-2"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Link
              // to={path().USER_DETAILS_STATE(item?._id)}
              to={`/users/students/students-overview/student-details/${item?._id}`}
              style={{ cursor: "pointer" }}
              className="ellipsis"
            >
              <div
                className="ellipsis"
                style={{
                  fontWeight: "600",
                  fontSize: "13px",
                  color: "var(--text-primary)",
                  lineHeight: "15px",
                  letterSpacing: "0.9px",
                }}
              >
                {item?.firstName + " " + item?.lastName || "-"}
              </div>
            </Link>
          </div>
        </div>
      ),
      nameToSort:item?.firstName + " " + item?.lastName || "-",
      verification: (
        <Tag
          style={{
            background: `${
              item?.isVerified
                ? "var(--dashboard-primay)"
                : "rgba(255, 191, 0, 0.1)"
            } `,
            color: `${
              item?.isVerified ? "var(--individual-button)" : "#FFBF00"
            } `,
            border: "none",
            borderRadius: "6px",
            padding: "0 10px",
            fontWeight: `${item?.isVerified ? 600 : 300}`,
          }}
        >
          {item?.isVerified ? "Verified" : "Pending"}
        </Tag>
      ),
      email: item?.email ? item.email : "-",

      role: item?.role ? item?.role:"-",
      // class: item?.class ? item?.class : "-",
      class: (
        <div style={{ width: "70px" }}>{item?.class ? item?.class : "-"}</div>
      ),
      gender: (
        <div style={{ width: "70px" }}>{item?.gender ? item?.gender : "-"}</div>
      ),
      // age: item?.age ? item?.age + " Years" : "-",
      age: <div>{item?.age ? item?.age + " Years" : "-"}</div>,
      // weight: item?.weight ? item?.weight + " kg" : "-",
      weight: (
        <div style={{ width: "50px" }}>
          {item?.weight ? item?.weight + " kg" : "-"}
        </div>
      ),
      // coach: item?.instituteId?.coach?.firstName
      //   ? `${item?.instituteId?.coach?.firstName} ${item?.instituteId?.coach?.lastName}`
      //   : "-",
      coach: (
        <div style={{width:'120px'}}>
          {item?.instituteId?.coach?.firstName
            ? `${item?.instituteId?.coach?.firstName} ${item?.instituteId?.coach?.lastName}`
            : "-"}
        </div>
      ),
      institute: (
        <div style={{width:'120px'}} >{item?.instituteId?.name ? item?.instituteId?.name : "-"}</div>
      ),
      dateOfBirth: (
        <div style={{width:'100px'}}>{moment(item?.dateOfBirth).format("DD MMM YYYY") || "-"}</div>
      ),
      createdAt: (
        <div style={{width:'100px'}}>{moment(item?.createdAt).format("DD MMM YYYY") || "-"}</div>
      ),

      updatedAt: (
        <div style={{width:'100px'}}>{moment(item?.updatedAt).format("DD MMM YYYY") || "-"}</div>
      ),

      action: (
        <div className="d-flex justify-content-center">
          <DeleteOutlined
            onClick={() => {
              //   setDeleteShow(true);
              //   setSelectedUserId(item?._id);
              // deleteUsers(item?._id);
            }}
            type="danger"
          />
          {/* <EditOutlined
                className=""
                // onClick={() => history.push(path().UPDATE_USER(item?._id))}
                type="danger"
                style={{ cursor: "pointer" }}
              /> */}
        </div>
      ),
    });
  }

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      width: 150,
      sorter: {
        compare: (a, b) => a.nameToSort.localeCompare(b.nameToSort),
        multiple: 3,
      },
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      width: 150,
      align: "center",
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 2,
      },
    },

    {
      title: "D.O.B",
      dataIndex: "dateOfBirth",
      align: "center",
      width: 100,
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      align: "center",
      width: 110,
      // className: "ant-table-row-cell-break-word",
    },
    {
      title: "CLASS",
      dataIndex: "class",
      width: 150,
      align: "center",
    },

    {
      title: "WEIGHT",
      dataIndex: "weight",
      width: 150,
      align: "center",
    },

    {
      title: "COACH",
      dataIndex: "coach",
      width: 150,
      align: "center",
    },
    {
      title: "INSTITUTE",
      dataIndex: "institute",
      width: 200,
      align: "center",
    },

    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      align: "center",
      width: 200,
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      width: 200,
      align: "center",
    },
  ];

  const handleClick = () => {
    history.push(`/users/students/students-overview/create-student?role=${role}`);
  };

  const getInstituteById = async (id) => {
    try {
      let res = await institute.getInstituteById(id);
    } catch (err) {}
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleChangeTable = (a, b, c) => {
    setSortingOrder(c?.order);
    setSortingColumn(c?.column?.dataIndex);
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 330px)", x: true } : null;


  useEffect(()=> {
    dispatch(
      setBreadcrumb({
        title: "All Players",
        subtitle: "",
        url: "",
      })
    );
  }, [])

  return (
    <Row>
      {openUploadStudentsModal && (
        <UploadStudentsModal
          open={openUploadStudentsModal}
          setOpen={setOpenUploadStudentsModal}
        />
      )}
      <Col
        span={24}
        className="header-control"
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
          <div className="d-flex justify-content-end">
            <div className="px-2">
              <button
                class="button-29"
                role="button"
                onClick={() => setOpenUploadStudentsModal(true)}
              >
                Import Players
              </button>
            </div>
          

          <div>
            <button class="button-29" onClick={handleClick}>
              Create New Player
            </button>
          </div>
        </div>
        <div>
          <Input
            className="header-search"
            placeholder="Search here..."
            prefix={<SearchOutlined />}
            style={{ width: "300px" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      
      </Col>
      <Col span={24}>
      <CustomUsersTable>
        <Table
          tableLayout="auto"
          columns={columns}
          dataSource={data}
          scroll={scroll}
          rowSelection={rowSelection}
          title={() => (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              {selectedRowKeys.length > 0 ? (
                <DeleteOutlined
                  onClick={deleteUsers}
                  style={{
                    fontSize: "18px",
                    color: selectedRowKeys.length > 0 ? "red" : "gray",
                    cursor:
                      selectedRowKeys.length > 0 ? "pointer" : "not-allowed",
                  }}
                  disabled={selectedRowKeys.length === 0}
                />
              ) : null}
            </div>
          )}
          locale={{
            emptyText: loading ? <Skeleton round active={true} /> : <Empty />,
          }}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["10","25", "50", "100"],
            total: count,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
        />
        </CustomUsersTable>
      </Col>
    </Row>
  );
};

export default StudentOverview;
