import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Input,
  Popover,
  Row,
  Segmented,
  Skeleton,
  Table,
  Tooltip,
} from "antd";
import { GiBlackBelt } from "react-icons/gi";
import { CustomTable, SearchInputContainer } from "../Styled";
import { MdSearch } from "react-icons/md";
import Filter from "../../../../utils/Filter";
import { extractToken } from "../../../../utils/localStorage";
import { capitalizeFirstLetter, notify, ShowTotal } from "../../../../utils/helper";
import { SearchOutlined } from "@ant-design/icons";
import AddEntriesModal from "../AddEntriesModal";
import UploadEntriesModal from "../UploadEntriesModal";
import event from "../../../../services/event";
import { useParams } from "react-router-dom";

const EventParticipantsList = ({
  eventData,
  eventTeamParticipants,
  count,
  setPagination,
  handleChangeTable,
  handleSearch,
  handleSearchTeamByName,
  setFilterStatus,
  setFilterInstituteId,
  setFilterCoachId,
  type,
  setType,
  getEventById,
  searchText,
  setSearchText,
}) => {
  const [show, setShow] = useState(false);
  const [filterCoach, setFilterCoach] = useState([]);
  const [filterInstitute, setFilterInstitute] = useState([]);
  const [selectedSection, setSelectedSection] = useState("Individuals");
  const [allEvents, setAllEvents] = useState([]);
  const [openEntriesModal, setOpenEntriesModal] = useState(false);
  const [openUploadEntriesModal, setOpenUploadEntriesModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = extractToken();
  const { EventId } = useParams();

  // console.log("user-token",user?.exp *1000  )

  let coachNames = [];
  let instituteNames = [];

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    if (isNaN(birthDate.getTime())) {
      return "Invalid Date";
    }

    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();

    if (months < 0 || (months === 0 && today.getDate() < birthDate.getDate())) {
      years--;
      months += 12;
    }

    if (today.getDate() < birthDate.getDate()) {
      months--;
      if (months < 0) {
        months = 11;
        years--;
      }
    }

    return `${years}.${months} Years`;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
    handleChange: (a, b, c) => {},
  };

  const columns = [
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Participants Name
          </div>

          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search participants..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px", width: "150px" }}
              name="participants"
              onChange={(e) => {
                setType("student");
                handleSearch(e);
                // setSearchText(e.target.value);
              }}
              value={searchText.participants}
            />
          </div>
        </div>
      ),
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      width: 150,
      align: "start",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 3,
      },
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Age
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 150,
      dataIndex: "age",
      align: "center",
      sorter: {
        compare: (a, b) => {
          const convertAgeToMonths = (ageStr) => {
            const ageMatch = ageStr.match(/(\d+)\s*years?\s*(\d*)\s*months?/i);
            const years = parseInt(ageMatch?.[1] || 0, 10);
            const months = ageMatch?.[2] ? parseInt(ageMatch[2], 10) : 0;
            return years * 12 + months;
          };

          const ageA = convertAgeToMonths(a.age);
          const ageB = convertAgeToMonths(b.age);

          return ageA - ageB;
        },
        multiple: 2,
      },
    },
    {
      title: <div>Weight</div>,
      dataIndex: "weight",
      align: "center",
      width: 120,
      sorter: (a, b) => {
        const weightA = parseFloat(a.weight);
        const weightB = parseFloat(b.weight);
        return weightA - weightB;
      },
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Gender
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 100,
      dataIndex: "gender",
      align: "center",
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Institute
          </div>

          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search institute..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px", width: "150px" }}
              name="institute"
              onChange={(e) => {
                setType("institute");
                handleSearch(e);
              }}
              value={searchText.institute}
            />
          </div>
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 150,
      dataIndex: "institute",
      align: "center",
    },
    {
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Coach
          </div>

          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search coach..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px", width: "150px" }}
              name="coach"
              onChange={(e) => {
                setType("coach");
                handleSearch(e);
              }}
              value={searchText.coach}
            />
          </div>
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 200,
      dataIndex: "coach",
      align: "center",
      hidden: ![
        "stateHead",
        "stateAdmin",
        "districtHead",
        "districtAdmin",
      ].includes(user?.role),
    },
    {
      title: (
        <div>
          <div className="heading mid">Category</div>
          {/* 
          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      dataIndex: "category",
      align: "center",
      width: 150,
      className: "ant-table-row-cell-break-word",
    },
  ].filter((item) => !item.hidden);

  let data = [];
  for (let item of eventData || []) {
    const coachName =
      item?.instituteId?.coach?.firstName +
      " " +
      item?.instituteId?.coach?.lastName;

    const instituteName = item?.instituteId?.name;

    if (coachName) {
      coachNames.push(coachName);
      instituteNames.push(instituteName);
    }

    data.push({
      key: item?._id,
      name: item?.firstName ? (
        <div
          style={{ width: "200px" }}
          className="ellipsis"
        >{`${item?.firstName} ${item?.lastName}`}</div>
      ) : (
        <div style={{ width: "200px" }}>-</div>
      ),
      age: calculateAge(item?.dateOfBirth),
      weight: item?.weight ? (
        <div style={{ width: "120px" }}>{`${item?.weight} kg`}</div>
      ) : (
        <div style={{ width: "120px" }}>-</div>
      ),
      gender: item?.gender ? capitalizeFirstLetter(item?.gender) : "-",
      coach:
        item?.instituteId?.coach?.firstName +
        " " +
        item?.instituteId?.coach?.lastName,
      institute: item?.instituteId?.name,
      category: <CategoriesDisplay categories={item?.category} />,
    });
  }

  function CategoriesDisplay({ categories }) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: categories?.map((cat) => cat.name).join(",<br />"),
        }}
      />
    );
  }

  useEffect(() => {
    setFilterInstituteId([...filterInstitute]);
    setFilterCoachId([...filterCoach]);
  }, [filterCoach, filterInstitute]);

  let uniqueCoachNames = [...new Set(coachNames)];
  let uniqueInstituteNames = [...new Set(instituteNames)];

  const getActiveEvent = async () => {
    try {
      const res = await event.getActiveEvent();
      setAllEvents(res?.data);
    } catch (error) {}
  };

  useEffect(() => {
    getActiveEvent();
  }, []);

  const handleDeleteSelectedParticipants = async (eventId,payload) => {
    try {
      const res = await event.deleteEventParticipants(eventId,payload);
   
      notify("success", res?.data?.message);
      getEventById()
    } catch (error) {
    }
  };


  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 325px)", x: true } : null;

  return (
    <>
      {openEntriesModal && (
        <AddEntriesModal
          open={openEntriesModal}
          setOpen={setOpenEntriesModal}
          getActiveEvent={getActiveEvent}
          selectedEvent={[EventId]}
        />
      )}
      {openUploadEntriesModal && (
        <UploadEntriesModal
          open={openUploadEntriesModal}
          setOpen={setOpenUploadEntriesModal}
          selectedEvent={[EventId]}
        />
      )}
      <Row>
        <Col span={24} className="d-flex justify-content-end mb-2">
          {[
            "stateHead",
            "stateAdmin",
            "districtHead",
            "districtAdmin",
            "coach",
          ].includes(user?.role) && (
            <>
              {selectedRowKeys?.length === 1 && (
                <div className="px-2">
                  <button
                    class="button-29"
                    role="button"
                    onClick={() =>
                      handleDeleteSelectedParticipants(EventId, {
                        "participants":selectedRowKeys,
                      })
                    }
                  >
                    Delete Selected Participant
                  </button>
                </div>
              )}
              {/* {selectedRowKeys?.length === 1 && ( */}
              <div style={{marginRight:'7px'}}>
                <button
                  class="button-29"
                  role="button"
                  onClick={() => console.log('map')}
                >
                  Map Categories
                </button>
              </div>
              <div >
                <button
                  class="button-29"
                  role="button"
                  onClick={() => setOpenEntriesModal(true)}
                >
                  Add Entries
                </button>
              </div>
              {/* )} */}
              {/* {selectedRowKeys?.length === 1 && ( */}
              <div className="px-2">
                <button
                  class="button-29"
                  role="button"
                  onClick={() => setOpenUploadEntriesModal(true)}
                >
                  Import Entries
                </button>
              </div>
              {/* )} */}
            </>
          )}
        </Col>
        {selectedSection === "Individuals" && (
          <Col span={24}>
            <CustomTable>
              <Table
                tableLayout="auto"
                scroll={scroll}
                columns={columns}
                dataSource={data}
                // bordered={false}
                // pagination={false}
                title={() => (
                  <Row gutter={[0, 0]} className="">
                    <Col
                      span={20}
                      style={{ justifyContent: "start" }}
                      className="d-flex align-items-center px-2 header-control"
                    >
                      {/* <div className="d-flex align-items-center px-2">
                  <Filter
                    show={show}
                    setShow={setShow}
                    data={[
                      {
                        title: "Coach Names",
                        list: uniqueCoachNames,
                        filterList: filterCoach,
                        setFilterList: setFilterCoach,
                      },
                      {
                        title: "Institutes",
                        list: uniqueInstituteNames,
                        filterList: filterInstitute,
                        setFilterList: setFilterInstitute,
                      },
                    ]}
                  />
                </div> */}
                      {/* <SearchInputContainer style={{ width: "250px" }}> */}

                      {/* <Input
                    placeholder="Search Participants..."
                    onChange={(e) => handleSearch(e.target.value)}
                    prefix={<MdSearch />}
                  /> */}

                      {/* </SearchInputContainer> */}
                      <Segmented
                        options={["Individuals", "Team"]}
                        defaultValue={selectedSection}
                        onChange={(value) => {
                          setSelectedSection(value);
                        }}
                      />
                    </Col>
                    <Col span={4} className="d-flex justify-content-end">
                      {/* <div>
                      <button className="button-29">Print All</button>
                    </div> */}
                    </Col>
                  </Row>
                )}
                rowSelection={rowSelection}
                pagination={{
                  defaultPageSize: 10,
                  defaultCurrent: 1,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "25", "50", "100"],
                  total: count,
                  onChange: (currentPage, size) => {
                    setPagination({ size, currentPage });
                  },
                  showTotal: (total, range) => (
                    <ShowTotal
                      selectedRowKeys={selectedRowKeys}
                      total={total}
                      currentPageItem={range[1] - range[0] + 1}
                      ranged={range}
                    />
                  ),
                }}
                onChange={handleChangeTable}
              />
            </CustomTable>
          </Col>
        )}
        {selectedSection === "Team" && (
          <TeamParticipanttable
            eventTeamParticipants={eventTeamParticipants}
            columns={columns}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            calculateAge={calculateAge}
            setPagination={setPagination}
            selectedRowKeys={selectedRowKeys}
            handleChangeTable={handleChangeTable}
            handleSearchTeamByName={handleSearchTeamByName}
          />
        )}
      </Row>
    </>
  );
};

export default EventParticipantsList;

const TeamParticipanttable = ({
  eventTeamParticipants,
  selectedSection,
  setSelectedSection,
  calculateAge,
  setPagination,
  selectedRowKeys,
  handleChangeTable,
  handleSearchTeamByName,
}) => {
  const user = extractToken();

  const columns = [
    {
      title: (
        <div>
          <div style={{}} className="heading mid">
            Teams Name
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      dataIndex: "name",
      className: "ant-table-row-cell-break-word",
      width: 200,
      align: "start",
      sorter: {
        compare: (a, b) => a.nameSort.localeCompare(b.nameSort),
        multiple: 3,
      },
    },

    // {
    //   title: (
    //     <div>
    //       <div style={{ textAlign: "center" }} className="heading mid">
    //         Participants
    //       </div>
    //     </div>
    //   ),
    //   dataIndex: "participants",
    //   className: "ant-table-row-cell-break-word",
    //   // width: 200,
    //   align: "center",

    // },

    {
      // title: "Institute",
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Institute
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      // width: 200,
      dataIndex: "institute",
      align: "center",
    },
    {
      // title: "Coach",
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Coach
          </div>

          {/* <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      className: "ant-table-row-cell-break-word",
      width: 200,
      dataIndex: "coach",
      align: "center",
      hidden: ![
        "stateHead",
        "stateAdmin",
        "districtHead",
        "districtAdmin",
      ].includes(user?.role),
    },
    // {
    //   title: "Belt Level",
    //   dataIndex: "belt",
    //   align: "center",
    //   className: "ant-table-row-cell-break-word",
    // },
    {
      // title: "Category",
      title: (
        <div>
          <div style={{ textAlign: "center" }} className="heading mid">
            Category
          </div>
          {/* 
          <div
            className="header-control"
            onClick={(e) => e.stopPropagation()}
            style={{ justifyContent: "center" }}
          >
            <Input
              className="header-search"
              placeholder="Search here..."
              prefix={<SearchOutlined />}
              style={{ height: "30px", fontSize: "12px" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
      ),
      dataIndex: "category",
      align: "center",
      className: "ant-table-row-cell-break-word",
    },
  ].filter((item) => !item.hidden);

  let coachNames = [];
  let instituteNames = [];
  let data = [];
  for (let item of eventTeamParticipants || []) {
    const coachName =
      item?.instituteId?.coach?.firstName +
      " " +
      item?.instituteId?.coach?.lastName;

    const instituteName = item?.instituteId?.name;

    if (coachName) {
      coachNames.push(coachName);
      instituteNames.push(instituteName);
    }
    data.push({
      key: item?._id,

      // name: (
      //   <Row style={{ width: "400px" }}>
      //     <Col span={24} style={{ border: "1px solid red" }}>
      //       {item?.name ? capitalizeFirstLetter(item?.name) : "-"}
      //     </Col>
      //     <Col span={24} style={{ border: "1px solid red" }}>
      //       <Row style={{ border: "1px solid pink" }}>
      //         {item?.students?.length > 0 &&
      //           item?.students?.map((student) => (
      //             <Col
      //               style={{ border: "1px solid pink", fontSize: "12px" }}
      //               span={8}
      //             >
      //               <div>
      //                 {capitalizeFirstLetter(student?.firstName) +
      //                   " " +
      //                   capitalizeFirstLetter(student?.lastName)}
      //               </div>
      //             </Col>
      //           ))}
      //       </Row>
      //     </Col>
      //   </Row>
      // ),
      name: (
        <Row
          style={{ alignItems: "center", gap: "10px", justifyContent: "start" }}
        >
          <div> {item?.name ? capitalizeFirstLetter(item?.name) : "-"}</div>
          {/*        
          <Avatar.Group
            maxCount={3}
            size={28}
            maxStyle={{
              color: "#fff",
              backgroundColor: "gray",
              border: "2px solid var(--dashboard-primay)",
              fontSize: "12px",
              lineHeight: "26px",
            }}
          >
            {item?.students?.length > 0 &&
              item?.students?.map((student, userIndex) => (
                <Tooltip
                  title={
                    capitalizeFirstLetter(student?.firstName) +
                    " " +
                    capitalizeFirstLetter(student?.lastName)
                  }
                  placement="top"
                >
                  <Avatar
                    style={{
                      backgroundColor: "#1890ff",
                      // border: "2px solid #1890ff",
                      fontSize: "13px",
                    }}
                    //   src={user?.profilePicture}
                    key={userIndex}
                    size={28}
                  >
                    {capitalizeFirstLetter(student?.firstName[0])}
                  </Avatar>
                </Tooltip>
              ))}
          </Avatar.Group> */}
        </Row>
      ),
      nameSort: item.name ? item.name : "-",
      age: (
        <Row style={{}}>
          <Avatar.Group
            maxCount={3}
            size={28}
            maxStyle={{
              color: "#fff",
              backgroundColor: "gray",
              border: "2px solid var(--dashboard-primay)",
              fontSize: "12px",
              lineHeight: "26px",
            }}
          >
            {item?.students?.length > 0 &&
              item?.students?.map((student, userIndex) => (
                // <Col
                //   style={{ border: "1px solid pink", fontSize: "12px" }}
                //   span={24}
                // >
                //   <div>
                //     {capitalizeFirstLetter(student?.firstName)}{" "}
                //     {capitalizeFirstLetter(student?.lastName)} ({" "}
                //     {calculateAge(student?.dateOfBirth)})
                //   </div>
                // </Col>
                <Tooltip
                  title={
                    <div>
                      {capitalizeFirstLetter(student?.firstName)} ({" "}
                      {calculateAge(student?.dateOfBirth)})
                    </div>
                  }
                  placement="top"
                >
                  <Avatar
                    style={{
                      backgroundColor: "#1890ff",
                      // border: "2px solid #1890ff",
                      fontSize: "13px",
                    }}
                    //   src={user?.profilePicture}
                    key={userIndex}
                    size={28}
                  >
                    {capitalizeFirstLetter(student?.firstName[0])}
                  </Avatar>
                </Tooltip>
              ))}
          </Avatar.Group>
        </Row>
      ),
      participants: (
        // <Row style={{ width: "300px" }}>
        //   {item?.students?.length > 0 &&
        //     item?.students?.map((student) => (
        //       <Col
        //         style={{ border: "1px solid pink", fontSize: "12px" }}
        //         span={24}
        //       >
        //         <div>
        //           {capitalizeFirstLetter(student?.firstName)}{" "}
        //           {capitalizeFirstLetter(student?.lastName)} ({student?.gender})
        //         </div>
        //       </Col>
        //     ))}
        // </Row>
        <Popover
          content={
            <>
              <div style={{}}>
                {item?.students?.length > 0
                  ? item?.students?.map((student, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "2px 6px",
                          marginBottom: "4px",
                          borderRadius: "4px",
                          color: "#fff",
                          backgroundColor: "#1890ff",
                        }}
                      >
                        <span>
                          {capitalizeFirstLetter(student?.firstName)}{" "}
                          {capitalizeFirstLetter(student?.lastName)} (
                          {calculateAge(student?.dateOfBirth)}) -{" "}
                          {student?.gender}
                        </span>
                      </div>
                    ))
                  : "-"}
              </div>
            </>
          }
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {item?.students?.length > 0 ? (
              <div style={{ display: "flex", gap: "10px" }}>
                <div
                  style={{
                    padding: "2px 6px",
                    borderRadius: "4px",
                    color: "#fff",
                    backgroundColor: "#1890ff",
                  }}
                >
                  {" "}
                  {item?.students[0]?.firstName}
                </div>
                {item?.students?.length - 1 ? (
                  <div
                    style={{
                      padding: "2px 6px",
                      borderRadius: "4px",
                      color: "#fff",
                      backgroundColor: "#1890ff",
                    }}
                  >
                    {" "}
                    + {item?.students?.length - 1} ..
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <span style={{ color: "var(--text-primary)" }}> - </span>
            )}
          </div>
        </Popover>
      ),
      coach: item?.coach
        ? `${
            capitalizeFirstLetter(item?.coach?.firstName) +
            " " +
            capitalizeFirstLetter(item?.coach?.lastName)
          }`
        : "-",

      institute: item?.institute?.name,
      category: item?.category?.name,
      students: item?.students,
    });
  }

  const expandColumns = [
    {
      title: "Team members",
      dataIndex: "teamMembers",
      key: "members",
      align: "start",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      align: "center",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      align: "center",
    },
  ];

  const expandedRowRender = (record) => {
    const expandDataSource = record?.students?.map((student) => ({
      key: student?._id,
      teamMembers: student?.firstName
        ? student?.firstName + " " + student?.lastName
        : "-",
      gender: student?.gender,
      age: calculateAge(student.dateOfBirth),
    }));

    return (
      <Table
        columns={expandColumns}
        dataSource={expandDataSource}
        pagination={false}
        size="small"
      />
    );
  };

  const hasData = data.length > 0;
  const scroll = hasData ? { y: "calc(100vh - 325px)", x: true } : null;

  return (
    <Col span={24}>
      <CustomTable>
        <Table
          tableLayout="auto"
          scroll={scroll}
          columns={columns}
          dataSource={data}
          bordered={false}
          title={() => (
            <Row gutter={[0, 0]} className="">
              <Col
                span={16}
                style={{ justifyContent: "start" }}
                className="d-flex align-items-center px-2 header-control"
              >
                <Segmented
                  options={["Individuals", "Team"]}
                  defaultValue={selectedSection}
                  onChange={(value) => {
                    setSelectedSection(value);
                  }}
                />
              </Col>

              <Col
                span={8}
                className="d-flex justify-content-end header-control"
              >
                <div>
                  <button className="button-29">Print All</button>
                </div>
                <Input
                  className="header-search"
                  placeholder="Search here..."
                  prefix={<SearchOutlined />}
                  style={{ width: "300px" }}
                  onChange={(e) => handleSearchTeamByName(e.target.value)}
                />
              </Col>
            </Row>
          )}
          pagination={{
            defaultPageSize: 10,
            defaultCurrent: 1,
            showSizeChanger: true,
            pageSizeOptions: ["25", "50", "100"],
            total: eventTeamParticipants?.length,
            onChange: (currentPage, size) => {
              setPagination({ size, currentPage });
            },
            showTotal: (total, range) => (
              <ShowTotal
                selectedRowKeys={selectedRowKeys}
                total={total}
                currentPageItem={range[1] - range[0] + 1}
                ranged={range}
              />
            ),
          }}
          onChange={handleChangeTable}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ["0"],
          }}
        />
      </CustomTable>
    </Col>
  );
};
